<template>
  <v-col cols="12" md="4" offset-md="4" align="center" class="mt-12">
    <h1>{{ $vuetify.lang.t("$vuetify.view.verify.title") }}</h1>

    <!--    <p class="font-weight-light">Тут будет номер телефона юзера</p>-->

    <v-row justify="center">
      <v-col cols="9">
        <div class="ma-auto" style="max-width: 300px">
          <v-otp-input
            :autofocus="true"
            v-model="otpInput"
            :length="length"
          ></v-otp-input>
        </div>
      </v-col>
    </v-row>

    <p class="mt-4 text-sm-body-2 font-weight-regular" v-if="email">
      {{ $vuetify.lang.t("$vuetify.main.psychologistReg.emailCode") }}
    </p>
    <p class="mt-4 text-sm-body-2 font-weight-regular" v-if="email">
      {{ $vuetify.lang.t("$vuetify.main.psychologistReg.emailCodeSpam") }}
    </p>

    <p class="mt-4 text-sm-body-2 font-weight-regular" v-if="timer">
      {{ $vuetify.lang.t("$vuetify.view.verify.desk") }} ({{ this.counter }})
    </p>
    <p class="mt-4 text-sm-body-2" v-if="!timer">
      {{ $vuetify.lang.t("$vuetify.view.verify.resendCodeDesk") }}
    </p>

    <!--    <p v-if="!this.$store.getters['user/getAdminTrigger'] && !email">-->
    <!--      {{ $vuetify.lang.t("$vuetify.view.verify.viberCode1") }}-->
    <!--      <span-->
    <!--        @click="sendCodeByViber"-->
    <!--        :class="{ 'text&#45;&#45;viber': !viberTimer, active: viberTimer }"-->
    <!--        >{{ $vuetify.lang.t("$vuetify.view.verify.viberCode2") }}</span-->
    <!--      >-->
    <!--      <v-btn v-else class="ma-2" outlined @click="resendCode">-->
    <!--        {{ $vuetify.lang.t("$vuetify.view.verify.resendCode") }}-->
    <!--      </v-btn>-->
    <!--    </p>-->
    <v-btn
      v-if="timer"
      class="ma-2"
      :loading="loading"
      :disabled="loading"
      color="primary"
      outlined
      @click="verifyCode"
    >
      {{ $vuetify.lang.t("$vuetify.view.verify.accept") }}
      <template v-slot:loader>
        <span>{{ $vuetify.lang.t("$vuetify.view.verify.loading") }}</span>
      </template>
    </v-btn>
    <v-btn v-if="!timer" class="ma-2" outlined @click="resendCode">
      {{ $vuetify.lang.t("$vuetify.view.verify.resendCode") }}
    </v-btn>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
export default {
  name: "verifyComponent",
  data() {
    return {
      otpInput: null,
      loader: null,
      loading: false,
      length: 6,
      url: "",
      counter: "10:00",
      timer: true,
      email: false,
      viberTimer: true,
      option: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },

  created() {
    this.url = this.$route.meta.url;
    this.option = this.$route.meta.option;
    this.payloadOption = this.$route.meta.payloadOption;
    this.startTimer(600);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    this.email = this.$route.fullPath === "/psychologistRegForm/email/verify";
  },
  methods: {
    setDispatchType(option) {
      switch (option) {
        case "auth":
          return { verify: "user/verifyCode", send: "user/sendCode" };
        case "psychReg":
          return {
            verify: "user/verifyUserRegCode",
            send: "user/sendUserRegCode",
          };
        case "verifyEmailCode":
          return {
            verify: "user/verifyEmailCode",
            send: "user/sendPsychEmailCode",
          };
        case "patientReg":
          return {
            verify: "user/verifyUserRegCode",
            send: "user/sendUserRegCode",
          };
      }
    },
    setPayloadType(payloadOption) {
      switch (payloadOption) {
        case "phone":
          return JSON.stringify({
            phone: this.user.phone,
            code: this.otpInput,
          });
        case "email":
          return JSON.stringify({
            email: this.user.email,
            code: this.otpInput,
          });
      }
    },
    resendCode() {
      clearInterval(this.interval);
      this.timer = false;
      this.$store.dispatch(
        this.setDispatchType(this.option).send,
        this.setPayloadType(this.payloadOption)
      );
      this.timer = true;
      this.startTimer(600);
    },
    startTimer(expiration) {
      let _this = this;
      _this.interval = setInterval(tikTok, 1000);
      function tikTok() {
        let minutes = Math.floor(expiration / 60);
        let seconds = expiration % 60;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        _this.counter = `${minutes}:${seconds}`;
        --expiration;
        if (expiration === 0) {
          _this.viberTimer = true;
          _this.timer = false;
          clearInterval(_this.interval);
        }
      }
    },
    sendCodeByViber() {
      if (!this.viberTimer) {
        return null;
      }

      let dispatchType = this.option === "auth" ? "viberSignIn" : "viberSignUp";

      clearInterval(this.interval);
      this.$store
        .dispatch("user/viberSendCode", {
          type: dispatchType,
          phone: this.$store.getters["user/getUserPhone"],
        })
        .then((response) => {
          Vue.swal({
            target: document.getElementById("main"),
            text: response.data.message,
            icon: "success",
            timer: 4000,
            toast: true,
            position: "top-right",
            showConfirmButton: false,
          });
          this.viberTimer = false;
          this.timer = true;
          this.startTimer(600);
        });
    },
    verifyCode() {
      this.$store
        .dispatch(
          this.setDispatchType(this.option).verify,
          this.setPayloadType(this.payloadOption)
        )
        .then((response) => {
          if (response.status === 200) {
            this.$router.push(this.url);
            Vue.swal({
              target: document.getElementById("main"),
              text: response.data.message,
              icon: "success",
              timer: 4000,
              toast: true,
              position: "top-right",
              showConfirmButton: false,
            });
            this.$store.commit("user/SET_ADMIN_TRIGGER", false);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.text--viber {
  color: $textGray;
}

.active {
  color: $primary;
  cursor: pointer;
}
</style>
