<template>
  <v-container fluid>
    <p class="text-center title mt-6">
      {{
        this.$vuetify.lang.t("$vuetify.main.redactorDashboard.categories.title")
      }}
    </p>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="subtitle-2">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.redactorDashboard.categories.createdCategories"
              )
            }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <p class="text-center mb-1 pb-0">Український</p>
              </v-col>
              <v-col cols="12" md="4">
                <p class="text-center mb-1 pb-0">Русский</p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row
              v-for="(category, i) in categoriesList"
              :key="i"
              class="mt-1"
            >
              <v-col cols="12" md="4">
                <p class="text-center">{{ category.name.uk }}</p>
              </v-col>
              <v-col cols="12" md="4">
                <p class="text-center">{{ category.name.ru }}</p>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn
                  small
                  @click="deleteCategory(category.id)"
                  class="text-center"
                >
                  {{
                    $vuetify.lang.t(
                      "$vuetify.main.redactorDashboard.categories.delete"
                    )
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>
            <p class="text-center subtitle-2">
              {{
                $vuetify.lang.t(
                  "$vuetify.main.redactorDashboard.categories.createCategory"
                )
              }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Український"
              v-model="category.uk"
            ></v-text-field>
            <v-text-field label="Русский" v-model="category.ru"></v-text-field>
            <v-btn small class="mt-3" @click="createCategory">{{
              $vuetify.lang.t(
                "$vuetify.main.redactorDashboard.categories.create"
              )
            }}</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "categoriesComponent",
  metaInfo() {
    return {
      title:
        "Платформа / " +
        this.$vuetify.lang.t("$vuetify.main.header.menu.categories"),
    };
  },
  data() {
    return {
      category: { uk: "", ru: "" },
    };
  },
  computed: {
    categoriesList() {
      return this.$store.getters["blog/getCategoriesList"].data;
    },
  },
  beforeMount() {
    this.$store.dispatch("blog/getCategoriesList");
  },
  methods: {
    deleteCategory(id) {
      this.$store.dispatch("blog/deleteCategory", id);
    },
    createCategory() {
      let payload = {
        name: { ru: this.category.ru, uk: this.category.uk },
      };
      this.$store.dispatch("blog/createCategory", payload);
      this.category = { ru: "", uk: "" };
    },
  },
};
</script>

<style scoped></style>
