<template>
  <div class="text-center">
    <v-dialog persistent v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-center text-h5 grey lighten-2">{{
          $vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.buttons.confirmUpdates"
          )
        }}</v-card-title>
        <v-card-text class="d-flex">
          <p class="mt-5 card-text">
            <slot></slot>
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="decline">{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.buttons.confirmN"
            )
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="accept">{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.buttons.confirmY"
            )
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    action: {
      type: Function,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
  },
  methods: {
    decline() {
      this.$emit("closeDialog");
    },
    accept() {
      if (this.id) this.action(this.id);
      else this.action();
      this.$emit("closeDialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-text {
  margin: 0 auto;
  color: #263238 !important;
}
</style>
