import Vue from "vue";
import VueRouter from "vue-router";

import psychologRegForm from "@/components/registration/registrationSteps/psychologRegForm";
import userRegForm from "@/components/registration/registrationSteps/patientRegForm";
import registration from "../views/registration";
import signIn from "@/views/auth/signIn";
import verify from "@/views/auth/verify";
import emailRegistration from "../views/auth/emailRegistration";
import authLayout from "@/components/registration/layouts/authLayout";
import pageNotFound from "@/views/auth/pageNotFound";
import registrationLayout from "../components/registration/layouts/registrationLayout";

import blog from "@/views/dashboard/blog";
import psychologists from "../views/dashboard/admin/psychologists";
const Specialization = () =>
  import("../views/dashboard/psychologist/Specialization");
const dashboardLayout = () => import("../components/dashboard/dashboardLayout");
const dashboard = () => import("../views/dashboard/dashboard");
const settings = () => import("../views/dashboard/settings");
const psychologist = () => import("../views/dashboard/patient/psychologist");
const payment = () => import("../views/dashboard/patient/payment");
const Schedule = () => import("../views/dashboard/psychologist/Schedule");

import store from "@/store";
import redactors from "../views/dashboard/admin/redactors";
import categories from "../views/dashboard/admin/categories";
import postComponent from "../components/dashboard/postComponent";

Vue.use(VueRouter);

const routes = [
  {
    path: "/registration",
    component: registration,
  },
  {
    path: "/auth",
    component: authLayout,
    children: [
      {
        path: "",
        name: "auth",
        component: signIn,
        meta: {
          option: "auth",
          authAndRegGroup: true,
          isPrivate: false,
          title: "$vuetify.main.route.enter",
          url: "/auth/verify",
        },
      },
      {
        path: "verify",
        component: verify,
        meta: {
          option: "auth",
          authAndRegGroup: true,
          payloadOption: "phone",
          url: "/dashboard/blog",
          isPrivate: false,
        },
      },
    ],
  },
  {
    path: "/patientRegForm",
    component: registrationLayout,
    meta: {
      isPrivate: false,
    },
    children: [
      {
        path: "",
        component: signIn,
        meta: {
          authAndRegGroup: true,
          url: "/patientRegForm/verify",
          title: "$vuetify.main.route.registration",
          isPrivate: false,
          option: "patientReg",
        },
      },
      {
        path: "verify",
        component: verify,
        meta: {
          authAndRegGroup: true,
          url: "/signUp",
          isPrivate: false,
          option: "patientReg",
          preRole: "patient",
          payloadOption: "phone",
        },
      },
      {
        path: "/signUp",
        component: userRegForm,
        meta: {
          authAndRegGroup: true,
          title: "$vuetify.main.route.registration",
          isPrivate: false,
        },
      },
    ],
  },
  {
    path: "/psychologistRegForm",
    component: registrationLayout,
    meta: {
      isPrivate: false,
    },
    children: [
      {
        path: "",
        component: signIn,
        meta: {
          authAndRegGroup: true,
          title: "$vuetify.main.route.registration",
          url: "/psychologistRegForm/verify",
          isPrivate: false,
          option: "psychReg",
        },
      },
      {
        path: "verify",
        component: verify,
        meta: {
          authAndRegGroup: true,
          url: "/psychologistRegForm/email",
          isPrivate: false,
          option: "psychReg",
          payloadOption: "phone",
        },
      },
      {
        path: "email",
        component: emailRegistration,
        meta: {
          authAndRegGroup: true,
          isPrivate: false,
          url: "/psychologistRegForm/email/verify",
          option: "sendPsychRegEmail",
        },
      },
      {
        path: "email/verify",
        component: verify,
        meta: {
          authAndRegGroup: true,
          url: "/form",
          isPrivate: false,
          option: "verifyEmailCode",
          payloadOption: "email",
        },
      },
      {
        path: "/form",
        name: "psychologRegForm",
        component: psychologRegForm,
        meta: {
          authAndRegGroup: true,
          isPrivate: false,
        },
      },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboardLayout,
    children: [
      {
        path: "main",
        name: "main",
        component: dashboard,
        meta: {
          isPrivate: true,
          roles: ["patient", "psychologist"],
        },
      },
      {
        path: "settings",
        component: settings,
        meta: {
          isPrivate: true,
          roles: ["patient", "psychologist"],
        },
      },
      {
        path: "psychologist",
        component: psychologist,
        meta: {
          isPrivate: true,
          roles: ["patient"],
        },
      },
      {
        path: "payment",
        component: payment,
        meta: {
          isPrivate: true,
          roles: ["patient"],
        },
      },
      {
        path: "Specialization",
        component: Specialization,
        meta: {
          isPrivate: true,
          roles: ["psychologist"],
        },
      },
      {
        path: "Schedule",
        component: Schedule,
        meta: {
          isPrivate: true,
          roles: ["psychologist"],
        },
      },
      {
        path: "blog",
        name: "blog",
        component: blog,
        meta: {
          roles: ["admin", "redactor", "psychologist", "patient"],
          isPrivate: true,
        },
      },
      {
        path: "/blog/posts/:slug",
        name: "blog-post",
        component: postComponent,
        meta: {
          roles: ["psychologist", "patient"],
          isPrivate: true,
        },
      },
      {
        path: "categories",
        component: categories,
        meta: {
          isPrivate: true,
          roles: ["admin", "redactor"],
        },
      },
      {
        path: "redactors",
        name: "redactors",
        component: redactors,
        meta: {
          isPrivate: true,
          roles: ["admin"],
        },
      },
      {
        path: "psychologists",
        component: psychologists,
        meta: {
          isPrivate: true,
          roles: ["admin"],
        },
      },
    ],
  },
  {
    path: "*",
    component: pageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  to.path === "/auth/verify/" && from.path !== "/auth/"
    ? next({ name: "auth" })
    : next();

  let authParam = store.getters["user/getAuthParam"];

  if (
    to.path === "/" &&
    authParam.isAuth &&
    (authParam.userRole === "patient" || authParam.userRole === "psychologist")
  ) {
    next({ name: "main" });
  } else if (
    to.path === "/" &&
    authParam.isAuth &&
    (authParam.userRole === "admin" || authParam.userRole === "redactor")
  ) {
    next({ name: "blog" });
  } else if (to.path === "/" && !authParam.isAuth) {
    next({ name: "auth" });
  }
  if (to.matched.some((record) => record.meta.isPrivate)) {
    if (authParam.isAuth && to.meta.roles.includes(authParam.userRole)) {
      next();
    } else {
      store.commit("user/SET_LOGOUT");
      next({ name: "auth" });
    }
  }
  if (to.matched.some((record) => record.meta.authAndRegGroup)) {
    if (
      (authParam.isAuth && authParam.userRole === "patient") ||
      authParam.userRole === "psychologist"
    ) {
      next({ name: "blog" });
    } else if (
      (authParam.isAuth && authParam.userRole === "admin") ||
      authParam.userRole === "redactor"
    ) {
      next({ name: "blog" });
    } else {
      next();
    }
  }
});

export default router;
