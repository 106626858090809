<template>
  <div>
    
    <!-- Pagination for active psychologists -->
    <v-row justify="center" class="mt-8 mb-8" v-if="tab === 1">
      <div class="text-center" style="margin-top: -30px;"> 
        <v-pagination
          @next="activePage + 1"
          @previous="activePage - 1"
          v-model="activePage"
          :length="activeLastPage"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-row>

    <!-- Pagination for inactive psychologists -->
    <v-row justify="center" class="mt-8 mb-8" v-else>
      <div class="text-center" style="margin-top: -30px;"> 
        <v-pagination
          @next="inactivePage + 1"
          @previous="inactivePage - 1"
          v-model="inactivePage"
          :length="inactiveLastPage"
          :total-visible="10"
        ></v-pagination>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    tab: {
      type: Number 
    },
    activeLastPage: {
      type: Number
    },
    inactiveLastPage: {
      type: Number
    }
  },
  data () {
    return {
      activePage: 1,
      inactivePage: 1
    }
  },
  watch: {
    activePage () {
      this.$emit('reload', 'active'); 
      this.$store.dispatch('user/getActivePsychologists', { page: this.activePage })
    },
    inactivePage () {
      this.$emit('reload', 'inactive'); 
      this.$store.dispatch('user/getInactivePsychologists', { page: this.inactivePage })
    },
  }
}
</script>

<style scoped>

</style>
