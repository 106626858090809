import ApiClient from "@/libs/http-client";

const AppServices = {
  endpoints(route, params) {
    const url = {
      getPsychologist: `api/psychologists/paginated?page=${params?.page}&filter=${params?.filter}`,
      getProfile: "api/profile",
      createCard: "api/payment-methods",
      deleteCard: `api/payment-methods/${params?.id}`,
      bookTimeSlotByPatient: `api/patients/calendars/${params?.id}/book`,
      cancelTimeSlotByPatient: `api/patients/calendars/${params?.id}/cancel`,
      switchLocale: `api/localization/switch`,
      sendRating: `api/patients/calls/${params?.id}/rating`,
      getPsychologistFilter: `api/general-questions`,
    };

    return url[route];
  },

  get(url, data) {
    return ApiClient.get(this.endpoints(url, data));
  },
  post(url, params = {}, data, config = {}) {
    return ApiClient.post(this.endpoints(url, params), data, config);
  },
  patch(url, params = {}, data, config = {}) {
    return ApiClient.patch(this.endpoints(url, params), data, config);
  },
  delete(url, params = {}, data, config = {}) {
    return ApiClient.delete(this.endpoints(url, params), data, config);
  },
};

export default AppServices;
