<template>
  <section class="footer">
    <div class="container d-flex justify-space-between">
      <div class="footer__social footer__item d-flex flex-column">
        <span class="footer__feedback align-se"
          >{{ this.$vuetify.lang.t("$vuetify.main.footer.questions") }} <br />
          {{ this.$vuetify.lang.t("$vuetify.main.footer.contacts") }}</span
        >
        <a class="footer__phone" href="tel:+380673077887">
          <img
            class="footer__phone-img"
            src="../../assets/images/6_icon_tel.png"
            alt=""
          />
          <span class="footer__phone-text">+38-067-307-78-87</span>
        </a>
        <div class="footer__links">
          <a
            href="https://www.facebook.com/%D0%9F%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D0%B0-110554091618488"
            target="_blank"
          >
            <img
              class="footer__social-item"
              src="../../assets/images/6_icon_fb.png"
              alt=""
            />
          </a>
          <a
            href="https://instagram.com/platforma.life?utm_medium=copy_link"
            target="_blank"
          >
            <img
              class="footer__social-item"
              src="../../assets/images/6_icon_insta.png"
              alt=""
            />
          </a>
          <a href="https://t.me/+Dqd5L573QkpjMWFi" target="_blank">
            <img
              class="footer__social-item"
              src="../../assets/images/6_icon_Telega.png"
              alt=""
            />
          </a>
          <a href="https://wa.me/380673077887" target="_blank">
            <img
              class="footer__social-item"
              src="../../assets/images/6_icon_VS.png"
              alt=""
            />
          </a>
        </div>
        <span class="footer__copyright"
          >&copy; {{ new Date().getFullYear() }} Платформа</span
        >
      </div>
      <div class="footer__description footer__item">
        <p>
          {{ $vuetify.lang.t("$vuetify.main.footer.legal.userAgreementPart1") }}
          <terms>{{
            $vuetify.lang.t("$vuetify.main.footer.legal.userAgreementLink")
          }}</terms>
          <br />
          <br />
          <span class="text--secondary">{{
            $vuetify.lang.t("$vuetify.main.footer.legal.userAgreementPart2")
          }}</span>
          <personal-data>{{
            $vuetify.lang.t("$vuetify.main.footer.legal.userAgreementLink2")
          }}</personal-data>
          <br />
          <br />
          <span>{{ $vuetify.lang.t("$vuetify.main.footer.legal.terms") }}</span>
          <br />
          <br />
          <span>{{
            $vuetify.lang.t("$vuetify.main.footer.legal.protected")
          }}</span>
        </p>
      </div>
      <v-select
        class="footer__language-btn"
        v-model="currentLanguage"
        @change="changeLocale"
        :items="languages"
        item-text="state"
        item-value="lang"
        background-color="#2fa3b5"
        solo
      ></v-select>
    </div>
  </section>
</template>

<script>
import PersonalData from "@/components/UI/personalTerms/personalData";
import Terms from "@/components/UI/personalTerms/terms";
export default {
  name: "footerComponent",
  components: { PersonalData, Terms },
  data() {
    return {
      currentLanguage: {},
      languages: [
        { state: "Українська", lang: "uk" },
        { state: "Русский", lang: "ru" },
      ],
    };
  },
  beforeMount() {
    this.languages.forEach((language) => {
      if (language.lang === this.$store.getters["app/getSelectedLocale"]) {
        this.currentLanguage = language;
      }
    });
  },
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = this.currentLanguage;
      this.$store.dispatch("app/changeLocale", this.currentLanguage);
    },
  },
};
</script>

<style lang="scss">
.footer__language-btn .v-select__selection.v-select__selection--comma {
  color: white;
}
</style>
<style lang="scss" scoped>
$darkTextColor: #878787;
$lightColor: #2fa3b5;
.footer {
  box-shadow: 0px 2px 4px 2px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  padding-top: 28px;
  &__devider {
    margin-bottom: 20px;
    background-color: #fff;
    border-top: 2px solid $lightColor;
    border-bottom: 2px solid $lightColor;
    padding: 15px 0;
  }
  &__picture {
    display: flex;
    justify-content: flex-start;
  }
  &__img {
    max-width: 240px;
    height: auto;
  }
  &__item {
    max-width: 340px;
  }
  &__faq {
    margin-left: auto;
  }
  &__social span {
    font-weight: 400;
  }
  &__feedback {
    font-size: 1rem;
    font-weight: 300;
    color: $darkTextColor;
  }
  &__phone {
    font-size: 1.1rem;
    text-decoration: none;
    margin-top: 25px;
  }
  &__phone-text {
    color: $darkTextColor;
    font-size: 1rem;
    font-weight: 300;
  }
  &__links {
    display: flex;
    gap: 5px;
    margin-top: 50px;
  }
  &__social-item {
    max-width: 35px;
  }
  &__phone-img {
    max-width: 15px;
    height: auto;
  }
  &__copyright {
    font-size: 1rem;
    display: block;
    margin-top: 50px;
  }

  &__description {
    color: $darkTextColor;
    & p {
      font-weight: normal;
      font-size: 0.8rem;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 300;
    letter-spacing: 1px;
    min-width: 180px;
  }
  &__info-item {
    text-decoration: none;
    font-weight: normal;
    font-size: 1rem;
  }
  &__language-btn {
    max-width: 180px;
    width: 100%;
    font-size: 1rem;
    text-align: left;
    outline: none;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }

  .justify-xs-center {
    justify-content: center !important;
  }
}
</style>
