<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" class="blue-font"><slot></slot></span>
    </template>
    <v-card v-if="locale">
      <v-card-title class="text-h5 grey lighten-2 justify-center">
        ДОГОВОР ОФЕРТЫ
      </v-card-title>
      <v-card-text>
        <div class="ql-editor container">
          <p><br /></p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >Настоящий Договор, в котором одна сторона ФЛП Левченко Людмила
              Ивановна, (далее – «Агент»), и любое лицо, принявшее
              (акцептовавшее) настоящий договор (далее – «Клиент») с другой
              стороны (далее вместе — «Стороны») , а каждый отдельно –
              «Сторона»), заключили настоящий Договор (далее — «Договор»),
              адресованный неограниченному кругу лиц, являющимся официальным
              публичным предложением Агента, заключить с любым Клиентом Договор
              о предоставлении услуг и консультаций информационного характера с
              помощью веб -сайта Агента. При заказе и оплате Услуг Стороны
              принимают условия настоящего Договора о следующем:</span
            >
          </p>
          <p class="ql-align-justify"><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">1.</strong>
            <strong style="background-color: transparent">ТЕРМИНОЛОГИЯ</strong>
          </p>
          <ul>
            <li class="ql-align-justify">
              <strong style="background-color: transparent"
                >«Веб-сайт Агента» - </strong
              ><span style="background-color: transparent"
                >веб-страница, доступная в сети интернет по ссылке:
                www.platforma.life</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent"
                >«Договор публичной оферты» </strong
              ><span style="background-color: transparent"
                >– публичный договор, образец которого размещен на Веб-сайте
                Агента.</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent">«Акцепт» – </strong
              ><span style="background-color: transparent"
                >предоставление Клиентом полного и безусловного согласия на
                заключение настоящего Договора в полном объеме, без подписи
                письменного экземпляра Договора Сторонами.</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent">«Услуги» – </strong
              ><span style="background-color: transparent"
                >набор информационно-консультационных услуг, предоставляемых
                Агентом и Принципалом онлайн в порядке и на условиях,
                определенных настоящим Договором и Правилами предоставления
                услуг, являющимися Приложением №1 к настоящему Договору, а также
                полный перечень услуг, которые могут предоставляться Агентом и
                /или Принципалом, определяется на веб-сайте Агента. Он-лайн
                услуги немедицинские услуги, носят информационный и
                консультационный характер.</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent">«Агент»</strong
              ><span style="background-color: transparent">
                - предприятие, ФЛП Левченко Людмила Ивановна, которое
                предоставляет и обеспечивает сторон техническими средствами в
                виде веб-сайта для предоставления услуг Принципала
                Клиенту.</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent"
                >«Консультация» </strong
              ><span style="background-color: transparent"
                >– одна из перечня услуг, предлагаемых на сайте Агента.
                Представляет собой получение клиентом одноразовой
                онлайн-консультации или многократного курса онлайн-консультаций,
                в области практической психологии, у одного из (или нескольких)
                специалистов, представленных на веб-сайте (Принципалов).</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent"
                >«Посетитель Веб-сайта (Посетитель)» – </strong
              ><span style="background-color: transparent"
                >физическое лицо, посещающее Веб-сайт, просмотрело хотя бы одну
                страницу Веб-сайта. Посетитель может просматривать информацию на
                веб-сайте, предназначенную для предварительного ознакомления с
                функциональными возможностями информационной системы.</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent"
                >«Клиент, Пользователь» – </strong
              ><span style="background-color: transparent"
                >лицо, которое зарегистрировалось в Информационной системе и/или
                пользуется Информационной системой и/или ее функциями и имеет
                необходимый объем правоспособности и дееспособности для
                заключения настоящего Договора.</span
              >
            </li>
          </ul>
          <p class="ql-align-center">
            <strong style="background-color: transparent">2.</strong>
            <strong style="background-color: transparent"
              >ПРЕДМЕТ ДОГОВОРА</strong
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">2.1 </span>
            <span style="background-color: transparent"
              >Принципал предоставляет Клиенту услуги путем проведения онлайн
              сеанса с Клиентом через личный кабинет, находящийся на веб-сайте
              Агента, а Клиент обязуется оплатить Психологу услуги на условиях и
              в сроки, определенные настоящим договором через веб-сайт
              Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">2.2 </span>
            <span style="background-color: transparent"
              >Агент предоставляет сторонам техническими средствами в виде
              веб-сайта для проведения сеанса и обеспечивает техническое
              сопровождение проведения сеанса между Принципалом и
              Клиентом.&nbsp;</span
            >
          </p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">3.</strong>
            <strong style="background-color: transparent"
              >ПОРЯДОК ПРЕДОСТАВЛЕНИЯ УСЛУГ</strong
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.1 </span>
            <span style="background-color: transparent"
              >На Веб-сайте Агент размещает информацию о специалистах
              партнеров-психологов (фамилия, имя, отчество, специальность, опыт,
              вопросы, в которых специализируется), виды услуг, стоимость таких
              услуг.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.2 </span>
            <span style="background-color: transparent"
              >Услуги предоставляются Клиенту специалистами, представленными на
              веб-сайте (Принципалов с которыми Агент заключил договор о
              предоставлении услуг, путем предоставления услуг исключительно
              через посредство Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.3 </span>
            <span style="background-color: transparent"
              >После акцептования настоящего Договора Клиент на сайте выбирает
              специалиста, вид и вопросы услуг, указывает удобное время и дату
              для проведения сеанса. По результатам обработки данных,
              выбранных/оказанных Клиентом формируется счет для уплаты.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.4 </span>
            <span style="background-color: transparent"
              >Акцептом настоящего Договора Клиент согласен с условиями
              предоставления услуг и условиями настоящего договора.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.5 </span>
            <span style="background-color: transparent"
              >Клиент производит оплату услуг на условиях, определенных в
              Договоре.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.6 </span>
            <span style="background-color: transparent"
              >После осуществления оплаты по электронному адресу Клиента
              поступает квитанция об оплате. Сеанс проходит в личном кабинете в
              дату и время проведения сеанса.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.7 </span>
            <span style="background-color: transparent"
              >Без оплаты услуги не предоставляются.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.8 </span>
            <span style="background-color: transparent"
              >Агент размещает на сайте информацию о психологе и времени и дате,
              в который можно получить услугу (провести/получить сеанс).</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">4.</strong>
            <strong style="background-color: transparent"
              >СТОИМОСТЬ УСЛУГ И ПОРЯДОК ПРОВЕДЕНИЯ РАСЧЕТОВ</strong
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.1 </span>
            <span style="background-color: transparent"
              >Стоимость услуг составляет стоимость всех предоставленных Клиенту
              услуг.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.2 </span>
            <span style="background-color: transparent"
              >Стоимость услуг формируется на основании выбранных Клиентом видов
              услуг.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.3 </span>
            <span style="background-color: transparent"
              >Стоимость одной услуги и/или одного сеанса указывается на сайте
              Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.4 </span>
            <span style="background-color: transparent"
              >Стоимость услуг формируется из стоимости услуг Принципала,
              комиссии Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.5 </span>
            <span style="background-color: transparent"
              >Клиент производит 100% оплату за заказы услуги он-лайн через
              платежную систему WayforPay или другую систему, предложенную на
              веб-сайте Агентом, в сумме, определенной по результатам обработки
              заказа Клиента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.6 </span>
            <span style="background-color: transparent"
              >Клиент имеет право произвести авансовый платеж за сеансы в
              количестве, выбранном Клиентом на веб-сайте Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.7 </span>
            <span style="background-color: transparent"
              >Авансовые платежи, совершенные Клиентом, до проведения сеанса
              хранятся на счете Агента. После проведения каждого сеанса Агент
              производит расчет с Принципалом за фактически проведенный сеанс на
              условиях, определенных Агентским договором.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.8 </span>
            <span style="background-color: transparent"
              >Оплата за сеанс списывается с карты клиента за 12 часов до начала
              консультации</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.9 </span>
            <span style="background-color: transparent"
              >После списания с карты, средства не подлежат возврату.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">5.</strong>
            <strong style="background-color: transparent"
              >ОБЯЗАННОСТИ И ПРАВА СТОРОН</strong
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent">5.1 </strong>
            <strong style="background-color: transparent">Агент обязан:</strong>
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1.1 </span>
            <span style="background-color: transparent"
              >Организовать проведение предоставления услуг в срок, определенный
              Клиентом и Принципалом;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1.2 </span>
            <span style="background-color: transparent"
              >Разместить информацию о Принципале, услугах, стоимости услуг на
              веб-сайте;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1.3 </span>
            <span style="background-color: transparent"
              >Предоставить и обеспечить техническую возможность для
              предоставления услуг он-лайн.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1.4 </span>
            <span style="background-color: transparent"
              >Информировать Клиента об изменениях в процессе предоставления
              услуг.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1.5 </span>
            <span style="background-color: transparent"
              >Осуществить перерасчет средств Принципала за предоставленные
              услуги Клиенту.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent">5.2 </strong>
            <strong style="background-color: transparent"
              >Агент имеет право:</strong
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.1 </span>
            <span style="background-color: transparent"
              >Согласовать с Клиентом другую дату и время предоставления услуг,
              если Клиент отказался от предоставления услуг более чем за 24 часа
              до даты начала оказания услуг;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.2 </span>
            <span style="background-color: transparent"
              >Без согласования устанавливать или отменять различные скидки,
              маркетинговые акции, льготы, учредить учетные программы, программы
              лояльности и т.д.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.3 </span>
            <span style="background-color: transparent"
              >Заменить специалиста (принципала) до начала проведения сеанса,
              сообщив заранее о такой замене Клиента;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.4 </span>
            <span style="background-color: transparent"
              >Отказать Клиенту в предоставлении Услуг по Договору в случае
              выявления нарушений Клиентом условий настоящего Договора.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.5 </span>
            <span style="background-color: transparent"
              >Внести изменения, дополнения в настоящий договор без
              дополнительного и/или отдельного согласования с Клиентом.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.6 </span>
            <span style="background-color: transparent"
              >В случае выявления акцептования данного договора лицом, не
              имеющим полной гражданской дееспособности, отказать Клиенту в
              проведении сеанса до получения соответствующего письменного
              согласования от законного представителя такого лица.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent">5.3 </strong>
            <strong style="background-color: transparent"
              >Принципал имеет право:</strong
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.3.1 </span>
            <span style="background-color: transparent"
              >По своему усмотрению выбирать программу (методологию)
              предоставления услуг;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.3.2 </span>
            <span style="background-color: transparent"
              >В случае выявления признаков психических расстройств у клиента
              отказать в предоставлении психологической помощи без возврата
              оплаты за сеанс.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.3.3 </span>
            <span style="background-color: transparent"
              >Использовать данные об объеме и видах предоставленных услуг
              Клиенту в целях статистических данных и исследований психологом
              проблемы возникновения вопросов, являющихся предметом сеансов с
              Клиентом.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent"><em>5.4 </em></strong>
            <strong style="background-color: transparent"
              ><em>Принципал обязан:</em></strong
            ><em style="background-color: transparent">&nbsp;</em>
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.1 </span>
            <span style="background-color: transparent"
              >Предоставлять услуги Клиенту, с которым Агент заключил договор, в
              том числе, но не исключительно оферты, качественно и в сроки,
              определенные настоящим Договором.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.2 </span>
            <span style="background-color: transparent"
              >Предоставлять всю возможную психологическую помощь Клиенту
              посредством психологического консультирования, психологической
              коррекции и психологического информирования.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.3 </span>
            <span style="background-color: transparent"
              >Предоставлять услуги, согласно графику на платформе Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.4 </span>
            <span style="background-color: transparent"
              >Во время сеанса соблюдать этические нормы, закрепленные Этическим
              Кодексом Украинского союза психотерапевтов, Этическим Кодексом
              Европейской Ассоциации Психотерапии.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.5 </span>
            <span style="background-color: transparent"
              >Не применять к Клиенту гипнотические и другие «агрессивные»
              методы воздействия на Клиента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.6 </span>
            <span style="background-color: transparent"
              >Не использовать в собственных и/или других целях информацию о
              Клиенте и других лиц, о которых Психологу стало известно во время
              проведения сеанса.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent">5.5 </strong>
            <strong style="background-color: transparent"
              >Клиент обязан:</strong
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.1 </span>
            <span style="background-color: transparent"
              >Своевременно и в полном объеме произвести оплату услуг
              Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.2 </span>
            <span style="background-color: transparent"
              >Появляться(подключиться к личному кабинету) для предоставления
              услуг в согласованное с Агентом и зарезервированное время путем
              перехода по ссылке, направленной Агентом Клиенту.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.3 </span>
            <span style="background-color: transparent"
              >Следовать рекомендациям Агента по технической настройке связи
              между системой Агента и Клиентом.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.4 </span>
            <span style="background-color: transparent"
              >Обеспечить стабильную Интернет-связь.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.5 </span>
            <span style="background-color: transparent"
              >Сообщить Агенту не позднее чем за 12 часов до начала сеанса об
              отмене и/или переносе даты и/или времени сеанса. В противном
              случае сессия считается проведенной&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.6 </span>
            <span style="background-color: transparent"
              >Демонстрировать взаимное уважение к Психологу, Агенту,
              сотрудникам и партнерам Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.7 </span>
            <span style="background-color: transparent"
              >Сообщить Агенту достоверную информацию о персональных данных и
              документы, необходимые для идентификации Клиента, в том числе для
              заполнения Анкеты.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.8 </span>
            <span style="background-color: transparent"
              >Не допускать пользования услугами посторонних лиц, если другое
              прямо не предусмотрено положениями настоящего Договора.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.9 </span>
            <span style="background-color: transparent"
              >Во время сеанса не находиться в состоянии алкогольного,
              наркотического и/или токсического опьянения под действием
              медикаментов за рулем транспортного средства.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent">5.6 </strong>
            <strong style="background-color: transparent"
              >Клиент имеет право:</strong
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.6.1 </span>
            <span style="background-color: transparent"
              >При потреблении услуг получать помощь (консультирование)
              Специалистов Агента, обладающих профессиональными знаниями и
              навыками по предоставлению услуг.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.6.2 </span>
            <span style="background-color: transparent"
              >Сообщить Агенту о своих пожеланиях, предложениях, замечаниях по
              его деятельности и/или специалисту (Принципале) и услугам,
              предоставляемым по Договору.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.6.3 </span>
            <span style="background-color: transparent"
              >Изменить специалиста (Принципал) и время предоставления услуг не
              позднее чем за 12 часов до начала предоставления услуг.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.6.4 </span>
            <span style="background-color: transparent"
              >В письменном виде обратиться к Агенту с предложениями по
              улучшению процесса предоставления Услуг и акционных
              предложений.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.7 </span>
            <span style="background-color: transparent"
              >Акцентируя этот договор Клиент подтверждает свою
              информированность что психолог и Агент не оказывают услуги
              психиатра, не назначают медикаментозное лечение. В случае если
              Клиент записывается на сеанс с целью получения услуг, определенных
              данным пунктом, такой сеанс считается проведенным, если Клиент
              отказывается пройти сеанс по услугам, предоставляемым психологом,
              средства за сеанс не возвращаются.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">6.</strong>
            <strong style="background-color: transparent"
              >ОТВЕТСТВЕННОСТЬ И РАЗРЕШЕНИЕ СПОРОВ СТОРОН</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">6.1 </span>
            <span style="background-color: transparent"
              >За неисполнение или ненадлежащее исполнение своих обязательств по
              настоящему Договору стороны несут ответственность в соответствии с
              действующим законодательством Украины.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">6.2 </span>
            <span style="background-color: transparent"
              >Все споры, возникающие из этого Договора или связанные с ним,
              разрешаются путем переговоров между Сторонами.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">6.3 </span>
            <span style="background-color: transparent"
              >Если соответствующий спор невозможно разрешить путем переговоров,
              он разрешается в судебном порядке по установленной
              подведомственности и подсудности такого спора в соответствии с
              действующим в Украине законодательством.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">6.4 </span>
            <span style="background-color: transparent"
              >Агент не несет никакой ответственности за непредставление или
              ненадлежащее предоставление Услуг Клиенту при условии наступления
              каких-либо обстоятельств, возникших не по его вине (а именно –
              наступления обстоятельств, возникших по вине или неосторожности
              Клиента и/или наступления обстоятельств, возникших по вине или
              неосторожности любого третьего лица (любых третьих лиц) и/или
              наступления форс-мажорных обстоятельств).</span
            >
          </p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">7.</strong>
            <strong style="background-color: transparent"
              >ФОРС-МАЖОР&nbsp;</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">7.1 </span>
            <span style="background-color: transparent"
              >Стороны освобождаются от ответственности за неисполнение или
              ненадлежащее исполнение обязательств, предусмотренных настоящим
              Договором, если оно возникло в результате форс-мажорных
              обстоятельств.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">7.2 </span>
            <span style="background-color: transparent"
              >Под форс-мажорными обстоятельствами в настоящем Договоре
              понимаются любые обстоятельства, возникшие вне воли или вопреки
              воле или желанию Сторон и которых нельзя предусмотреть или
              избежать, включая: военные действия, общественные беспорядки,
              эпидемии, блокаду, землетрясения, наводнения, пожары, а также
              решения или предписания органов государственной власти и
              управления государства, резидентом которого является Заказчик, или
              государства, резидентом которого является Агент, в результате
              которых на Стороны (или одну из Сторон) будут возлагаться
              дополнительные обязанности или устанавливаться дополнительные
              ограничения и делающие невозможным дальнейшее полное или частичное
              исполнение Договора, а также другие действия или события,
              существующие вне воли Сторон.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">7.3 </span>
            <span style="background-color: transparent"
              >Если форс-мажорные обстоятельства будут продолжаться более 3-х
              (трех) месяцев подряд, то каждая из Сторон будет иметь право
              отказаться от дальнейшего выполнения обязательств по настоящему
              Договору и, в таком случае, ни одна из Сторон не будет иметь права
              на возмещение другой Стороной возможных убытков.</span
            >
          </p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">8.</strong>
            <strong style="background-color: transparent"
              >ПРОЧИЕ УСЛОВИЯ</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.1 </span>
            <span style="background-color: transparent"
              >Каждая Сторона гарантирует другой Стороне, обладающей необходимой
              дееспособностью, а равно всеми правами и полномочиями,
              необходимыми и достаточными для заключения и исполнения настоящего
              Договора в соответствии с его условиями.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.2 </span>
            <span style="background-color: transparent"
              >Одностороннее изменение условий заключенного Договора Заказчиком
              или отказ выполнять условия заключенного Договора Заказчиком
              недопустимо, за исключением случаев, предусмотренных настоящим
              Договором. Ни одна из Сторон настоящего Договора не имеет права
              передавать свои права и обязанности третьим лицам без согласия
              другой Стороны.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.3 </span>
            <span style="background-color: transparent"
              >Агент подтверждает, что является плательщиком упрощенной системы
              налогообложения 2-я группа, согласно Налоговому кодексу
              Украины.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.4 </span>
            <span style="background-color: transparent"
              >Информация, предоставляемая Заказчиком является конфиденциальной.
              Информация о Заказчике используется исключительно в целях
              предоставления Услуг по настоящему договору.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.5 </span>
            <span style="background-color: transparent"
              >Собственным акцептом договора Заказчик добровольно дает согласие
              на сбор и обработку собственных персональных данных в следующих
              целях: становящиеся известные данные будут использоваться в
              коммерческих целях, в том числе получение информации о заказе и
              обработке информации о нем, отправка телекоммуникационными
              средствами связи (электронной почтой, мобильной связью) рекламных
              и специальных предложений, информации об акциях или любой другой
              информации о деятельности Веб-сайта Агента. В случае нежелания
              получать информацию о деятельности Веб-сайта Агента, Клиент имеет
              право обратиться к Агенту, написав заявление об отказе от
              получения рекламных материалов и отправив его на почтовый или
              электронный адрес Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.6 </span>
            <span style="background-color: transparent"
              >Стороны обязуются хранить конфиденциальную информацию, полученную
              в результате выполнения настоящего Договора, за исключением
              случаев, когда это письменно санкционировано другой Стороной или
              требуется государственными органами в соответствии с действующим
              законодательством. За разглашение конфиденциальной информации
              виновная Сторона несет ответственность согласно действующему
              законодательству.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.7 </span>
            <span style="background-color: transparent"
              >Договор публичный и бессрочный и действует до его прекращения
              любой из Сторон в порядке, установленном настоящим Договором или
              действующим законодательством, но в любом случае до момента
              окончательного его исполнения Сторонами. Стороны согласовали, что
              срок действия настоящего Договора не может составлять менее 3
              (трех) календарных месяцев. Настоящий Договор считается
              согласованным Клиентом и заключенным по месту нахождения Агента с
              даты акцептования.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.8 </span>
            <span style="background-color: transparent"
              >Агент самостоятельно в соответствии и во исполнение требований
              действующего законодательства Украины определяет условия
              настоящего Договора и приложений к нему, которые являются его
              неотъемлемыми частями. Агент имеет право самостоятельно изменить
              и/или дополнить условия настоящего Договора и приложений к нему,
              включая правила предоставления и получения Услуг по настоящему
              Договору. При этом Агент гарантирует и подтверждает, что
              размещенная на Веб-сайте текущая редакция текста настоящего
              Договора и приложений к нему, включая правила предоставления и
              получения Услуг по настоящему Договору, действительна.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.9 </span>
            <span style="background-color: transparent"
              >Недействительность отдельных положений Договора не влияет на
              действительность каких-либо других положений Договора.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.10 </span>
            <span style="background-color: transparent"
              >Заголовки разделов приводятся только для удобства и не влияют на
              их толкование.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">9.</strong>
            <strong style="background-color: transparent">РЕКВИЗИТЫ ФОП</strong>
          </p>
          <p><br /></p>
          <p>
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >ФЛП Левченко Людмила Ивановна</span
            >
          </p>
          <p>
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >г. Одесса, ул. Жемчужная 5А</span
            >
          </p>
          <p>
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >ЄГРПОУ/ИНН 2748210085</span
            >
          </p>
          <p>
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >IBAN UA443220010000026000320054098 в АТ «Универсал Банк»</span
            >
          </p>
          <p><br /></p>
          <p><br /></p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Принять </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="!locale">
      <v-card-title class="text-h5 grey lighten-2 justify-center">
        ДОГОВІР ОФЕРТИ
      </v-card-title>

      <v-card-text>
        <div class="ql-editor container">
          <p><br /></p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >Цей Договір, в якому одна сторона ФОП Левченко Людмила Іванівна,
              (надалі – «Агент»), і будь-яка особа, яка прийняла (акцептувала)
              даний договір&nbsp; (надалі – «Клієнт») з іншого боку (далі разом
              — «Сторони», а кожний окремо – «Сторона»), уклали даний Договір
              (далі — «Договір»), адресований необмеженому колу осіб, який є
              офіційною публічною пропозицією Агента, укласти з будь-яким
              Клієнтом Договір про надання послуг та консультацій інформаційного
              характеру за допомогою веб-сайту Агента. При замовленні та оплаті
              Послуг Сторони приймають умови цього Договору про
              нижченаведене:</span
            >
          </p>
          <p class="ql-align-justify"><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">1. </strong>
            <strong style="background-color: transparent"
              >ТЕРМІНИ І ВИЗНАЧЕННЯ&nbsp;</strong
            >
          </p>
          <p><br /></p>
          <ul>
            <li class="ql-align-justify">
              <strong style="background-color: transparent"
                >«Веб-сайт Агента»</strong
              ><span style="background-color: transparent">
                - веб сторінка, доступна в мережі інтернет за посиланням:
                www.platforma.life&nbsp;</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent"
                >«Договір публічної оферти»</strong
              ><span style="background-color: transparent">
                - публічний договір, зразок якого розміщений на Веб-сайті
                Агента.&nbsp;</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent">«Акцепт»</strong
              ><span style="background-color: transparent">
                - надання Клієнтом повної й безумовної згоди на укладення даного
                Договору в повному обсязі, без підпису письмового примірника
                Договору Сторонами.&nbsp;</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent">«Послуги»</strong
              ><span style="background-color: transparent">
                – набір інформаційно-консультаційних послуг, що надаються
                Агентом та Принципалом онлайн в порядку та на умовах, визначених
                цим Договором та Правилами надання послуг, що є Додатком №1 до
                даного Договору, а також повний перелік послуг, що можуть
                надаватись Агентом та/або Принципалом, визначається на веб -
                сайті Агента. Он-лайн послуги не є медичними послугами, носять
                інформаційний та консультаційний характер.&nbsp;</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent">«Агент»</strong
              ><span style="background-color: transparent">
                - суб’єкт господарювання, ФОП Левченко Людмила Іванівна, який
                надає та забезпечує сторони технічними засобами у вигляді
                веб-сайту для надання послуг Принципала Клієнту.&nbsp;</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent"
                >«Консультація» </strong
              ><span style="background-color: transparent"
                >– одна з переліку послуг, що пропонуються на Веб-сайті Агента.
                Являє собою отримання клієнтом одноразової онлайн-консультації
                або багаторазового курсу онлайн-консультацій, в галузі
                практичної психології, в одного з (або декількох) фахівців
                представлених на веб-сайті (Принципалів).&nbsp;</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent"
                >«Відвідувач Веб-сайту (Відвідувач)»</strong
              ><span style="background-color: transparent">
                – фізична особа, яка відвідувала Веб-сайт, переглянула хоча б
                одну сторінку Веб-сайту. Відвідувач може переглядати інформацію
                на Веб-сайті, що призначена для попереднього ознайомлення з
                функціональними можливостями Інформаційної системи.</span
              >
            </li>
            <li class="ql-align-justify">
              <strong style="background-color: transparent"
                >«Клієнт, Користувач»</strong
              ><span style="background-color: transparent">
                – особа, яка зареєструвалась в Інформаційній системі та/або
                користується Інформаційною системою та/або її функціями та має
                необхідний обсяг правоздатності та дієздатності для укладення
                цього Договору.</span
              >
            </li>
          </ul>
          <p class="ql-align-justify"><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent"> 2. </strong>
            <strong style="background-color: transparent"
              >ПРЕДМЕТ ДОГОВОРУ</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-indent-1">
            2.1 Принципал надає Клієнту послуги шляхом проведення он-лайн сеансу
            з Клієнтом через особистий кабінет, що знаходиться на веб-сайті
            Агента, а Клієнт зобов'язується оплатити Психологу послуги на умовах
            та в терміни, визначені даним договором через веб-сайт Агента.
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">2.2 </span>
            <span style="background-color: transparent"
              >Агент надає, забезпечує сторін технічними засобами у вигляді
              веб-сайту для проведення сеансу та забезпечує технічний супровід
              проведення сеансу між Принципалом та Клієнтом.&nbsp;</span
            >
          </p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">3. </strong>
            <strong style="background-color: transparent"
              >ПОРЯДОК НАДАННЯ ПОСЛУГ</strong
            >
          </p>
          <p class="ql-align-center"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.1 </span>
            <span style="background-color: transparent"
              >На Веб-сайті Агент розміщує інформацію про фахівців
              партнерів-психологів (прізвище, ім'я, ім'я по батькові, фах,
              досвід, питання, в яких спеціалізується), види послуг, що
              надаються, вартість таких послуг.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.2 </span>
            <span style="background-color: transparent"
              >Послуги надаються Клієнту фахівцями представленими на веб-сайті
              (Принципалів з якими Агент уклав договір про надання послуг,
              шляхом надання послуг виключно за посередництвом Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.3 </span>
            <span style="background-color: transparent"
              >Після акцептування даного Договору Клієнт на сайті обирає
              фахівця, вид та питання послуг, зазначає зручний час та дату для
              проведення сеансу. За результатами оброблення даних,
              обраних/наданих Клієнтом формується рахунок для сплати.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.4 </span>
            <span style="background-color: transparent"
              >Акцептом даного Договору Клієнт погоджується з умовами надання
              послуг та умовами даного договору.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.5 </span>
            <span style="background-color: transparent"
              >Клієнт здійснює оплату послуг на умовах, визначених у
              Договорі.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.6 </span>
            <span style="background-color: transparent"
              >Після здійснення оплати на електронну адресу Клієнта надходить
              квитанція про оплату. Сеанс проходить в особистому кабінеті у
              заплановану дату та час проведення сеансу.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.7 </span>
            <span style="background-color: transparent"
              >Без здійснення оплати послуги не надаються.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.8 </span>
            <span style="background-color: transparent"
              >Агент розміщує на сайті інформацію про психолога та час і дату у
              який можливо отримати послугу (провести/отримати
              сеанс).&nbsp;&nbsp;</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">4. </strong>
            <strong style="background-color: transparent"
              >ВАРТІСТЬ ПОСЛУГ І ПОРЯДОК ПРОВЕДЕННЯ РОЗРАХУНКІВ</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.1 </span>
            <span style="background-color: transparent"
              >Вартість послуг складається з вартості всіх наданих Клієнту
              послуг.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.2 </span>
            <span style="background-color: transparent"
              >Вартість послуг формується на підставі обраних Клієнтом видів
              послуг.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.3 </span>
            <span style="background-color: transparent"
              >Вартість однієї послуги та / або одного сеансу зазначається на
              веб-сайті Агента.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.4 </span>
            <span style="background-color: transparent"
              >Вартість послуг формується з вартості послуг Принципала, комісії
              Агента.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.5 </span>
            <span style="background-color: transparent"
              >Клієнт здійснює 100% оплату за замовленні послуги он-лайн через
              платіжну систему WayforPay або іншу систему, запропоновану на
              веб-сайті Агентом, у сумі, визначеній за результатами оброблення
              замовлення Клієнта.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.6 </span>
            <span style="background-color: transparent"
              >Клієнт має право здійснити авансовий платіж за сеанси у
              кількості, обраній Клієнтом на веб-сайті Агента.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.7 </span>
            <span style="background-color: transparent"
              >Авансові платежі, здійснені Клієнтом, до проведення сеансу
              зберігаються на рахунку Агента. Після проведення кожного сеансу
              Агент здійснює розрахунок з Принципалом за фактично проведений
              сеанс на умовах, визначених Агентським договором.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.8 </span>
            <span style="background-color: transparent"
              >Кошти за сеанс списуються з карти клієнта за 12 годин до початку
              консультації.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.9 </span>
            <span style="background-color: transparent"
              >Після списання з карти, кошти не підлягають поверненню.</span
            >
          </p>
          <p class="ql-align-center"><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">5. </strong>
            <strong style="background-color: transparent"
              >ОБОВ'ЯЗКИ ТА ПРАВА СТОРІН</strong
            >
          </p>
          <p>
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent"><em>5.1 </em></strong>
            <strong style="background-color: transparent"
              ><em>Агент зобов'язаний</em></strong
            ><span style="background-color: transparent">:&nbsp;</span>
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1.1 </span>
            <span style="background-color: transparent"
              >Організувати проведення надання послуг в строк, визначений
              Клієнтом та Принципалом;&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1.2 </span>
            <span style="background-color: transparent"
              >Розмістити інформацію про Принципала, послуги, вартість послуг на
              веб-сайті;&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1.3 </span>
            <span style="background-color: transparent"
              >Надати та забезпечити технічну можливість для надання послуг
              он-лайн.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1.4 </span>
            <span style="background-color: transparent"
              >Інформувати Клієнта про зміни у процесі надання
              послуг.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1.5 </span>
            <span style="background-color: transparent"
              >Здійснити перерахунок коштів Принципалу за надані послуги&nbsp;
              Клієнту.&nbsp;</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent"><em>5.2 </em></strong>
            <strong style="background-color: transparent"
              ><em>Агент має право:</em></strong
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.1 </span>
            <span style="background-color: transparent"
              >Погодити з Клієнтом іншу дату та час надання послуг, якщо Клієнт
              відмовився від надання послуг більше ніж за 24 години до дати
              початку надання послуг;&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.2 </span>
            <span style="background-color: transparent"
              >Без узгодження встановлювати або скасовувати різноманітні знижки,
              маркетингові акції, пільги, засновувати дисконтні програми,
              програми лояльності тощо.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.3 </span>
            <span style="background-color: transparent"
              >Замінити фахівця (принципала) до початку проведення сеансу,
              повідомивши завчасно про таку заміну Клієнта;&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.4 </span>
            <span style="background-color: transparent"
              >Відмовити Клієнту у наданні Послуг за Договором у випадку
              виявлення порушення Клієнтом умов цього Договору.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.5 </span>
            <span style="background-color: transparent"
              >Внести зміни, доповнення до даного договору без додаткового
              та/або окремого погодження з Клієнтом.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.2.6 </span>
            <span style="background-color: transparent"
              >У випадку виявлення акцептування даного договору особою, що не
              має повної цивільної дієздатності відмовити Клієнту у проведенні
              сеансу до отримання відповідного письмового погодження від
              законного представника такої особи.&nbsp;</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent"><em>5.3 </em></strong>
            <strong style="background-color: transparent"
              ><em>Принципал має право:</em></strong
            ><span style="background-color: transparent">&nbsp;</span>
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.3.1 </span>
            <span style="background-color: transparent"
              >На власний розсуд обрати програму (методологію) надання
              послуг;&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.3.2 </span>
            <span style="background-color: transparent"
              >У випадку виявлення ознак психічних розладів у Клієнта відмовити
              у наданні психологічної допомоги без повернення оплати за
              сеанс.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.3.3 </span>
            <span style="background-color: transparent"
              >Використовувати дані про обсяг та види наданих послуг Клієнту з
              метою статистичних даних та досліджень психологом проблеми
              виникнення питань, що є предметом сеансів з Клієнтом.&nbsp;</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent"><em>5.4 </em></strong>
            <strong style="background-color: transparent"
              ><em>Принципал зобов'язаний:</em></strong
            ><span style="background-color: transparent">&nbsp;</span>
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.1 </span>
            <span style="background-color: transparent"
              >Надавати послуги Клієнту, з яким Агент уклав договір, в тому
              числі, але не виключно – оферти, якісно та в строки, визначені
              даним Договором.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.2 </span>
            <span style="background-color: transparent"
              >Надавати всю можливу психологічну допомогу Клієнту у вигляді
              психологічного консультування, психологічної корекції та
              психологічного інформування.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.3 </span>
            <span style="background-color: transparent"
              >Надавати послуги, призначені у графіку на платформі
              Агента.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.4 </span>
            <span style="background-color: transparent"
              >Під час сеансу дотримуватись етичних норм, що закріплені Етичним
              Кодексом Української спілки психотерапевтів, Етичним Кодексом
              Європейської Асоціації Психотерапії.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.5 </span>
            <span style="background-color: transparent"
              >Не застосовувати до Клієнта гіпнотичних та інших «агресивних»
              методів впливу на Клієнта.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.4.6 </span>
            <span style="background-color: transparent"
              >Не використовувати у власних та/або будь-яких інших цілях
              інформацію про Клієнта та інших осіб, щодо яких Психологу стало
              відомо під час проведення сеансу.&nbsp;</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent"><em>5.5 </em></strong>
            <strong style="background-color: transparent"
              ><em>Клієнт зобов'язаний</em></strong
            ><span style="background-color: transparent">:&nbsp;</span>
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.1 </span>
            <span style="background-color: transparent"
              >Своєчасно та у повному обсязі здійснити оплату послуг
              Агента.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.2 </span>
            <span style="background-color: transparent"
              >З'являтись для надання послуг в узгоджений з Агентом і
              зарезервований час шляхом переходу за посиланням надісланим
              Агентом Клієнту.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.3 </span>
            <span style="background-color: transparent"
              >Слідувати рекомендаціям Агента щодо технічного налаштування
              зв'язку між системою Агента та Клієнта.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.4 </span>
            <span style="background-color: transparent"
              >Забезпечити стабільний Інтернет-зв'язок.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.5 </span>
            <span style="background-color: transparent"
              >Повідомити Агента не пізніше ніж за 12 годин до часу початку
              сеансу про скасування та/або перенесення дати та/або часу сеансу.
              В іншому випадку сесія вважається як надана.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.6 </span>
            <span style="background-color: transparent"
              >Демонструвати взаємну повагу до Психолога, Агента, співробітників
              та партнерів Агента.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.7 </span>
            <span style="background-color: transparent"
              >Повідомити Агентові достовірну інформацію щодо персональних
              даних, та документи, необхідні для ідентифікації Клієнта, в тому
              числі для заповнення Анкети.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.8 </span>
            <span style="background-color: transparent"
              >Не допускати користування послугами сторонніх осіб, якщо інше
              прямо не передбачено положеннями даного Договору.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.5.9 </span>
            <span style="background-color: transparent"
              >Під час сеансу не перебувати у стані алкогольного, наркотичного
              та/або токсичного сп'яніння, під діями медикаментів, за кермом
              транспортного засобу.&nbsp;</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent"><em>5.6 </em></strong>
            <strong style="background-color: transparent"
              ><em>Клієнт має право:</em></strong
            ><span style="background-color: transparent">&nbsp;</span>
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.6.1 </span>
            <span style="background-color: transparent"
              >Під час споживання послуг отримувати допомогу (консультування)
              Фахівців Агента, які володіють фаховими знаннями та навичками,
              щодо надання послуг.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.6.2 </span>
            <span style="background-color: transparent"
              >Повідомити Агента про свої побажання, пропозиції, зауваження щодо
              його діяльності та / або фахівця (Принципала) та послуг, що
              надаються за Договором.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.6.3 </span>
            <span style="background-color: transparent"
              >Змінити фахівця (Принципала) та час надання послуг не пізніше ніж
              за 12 годин до початку надання послуг.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.6.4 </span>
            <span style="background-color: transparent"
              >Письмово звернутися до Агента з пропозиціями щодо покращення
              процесу надання Послуг та акційних пропозицій.&nbsp;</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.7 </span>
            <span style="background-color: transparent"
              >Акцептуючи цей договір Клієнт підтверджує свою поінформованість,
              що психолог та Агент не надають послуги психіатра, не призначають
              медикаментозне лікування. У випадку якщо Клієнт записується на
              сеанс з метою отримання послуг, визначених даним пунктом такий
              сеанс вважається проведеним, кошти за сеанс, що не відбувся, якщо
              Клієнт відмовляється пройти сеанс за послугами, що надаються
              психологом, не повертаються.&nbsp;</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">6. </strong>
            <strong style="background-color: transparent"
              >ВІДПОВІДАЛЬНІСТЬ ТА ВИРІШЕННЯ СПОРІВ СТОРІН</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">6.1 </span>
            <span style="background-color: transparent"
              >За невиконання або неналежне виконання своїх зобов’язань за цим
              Договором, сторони несуть відповідальність відповідно до чинного
              законодавства України.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">6.2 </span>
            <span style="background-color: transparent"
              >Усі спори, що виникають з цього Договору або пов'язані із ним,
              вирішуються шляхом переговорів між Сторонами.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">6.3 </span>
            <span style="background-color: transparent"
              >Якщо відповідний спір неможливо вирішити шляхом переговорів, він
              вирішується в судовому порядку за встановленою підвідомчістю та
              підсудністю такого спору відповідно до чинного в Україні
              законодавства.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">6.4 </span>
            <span style="background-color: transparent"
              >Агент не несе будь-яку відповідальність за ненадання або
              неналежне надання Послуг Клієнту за умови настання будь-яких
              обставин, які виникли не з його вини (а саме – настання обставин,
              які виникли з вини чи необережності Клієнта та / або настання
              обставин, які виникли з вини чи необережності будь-якої третьої
              особи (будь-яких третіх осіб) та /або настання форс-мажорних
              обставин).&nbsp;</span
            >
          </p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">7. </strong>
            <strong style="background-color: transparent"
              >ФОРС-МАЖОР&nbsp;</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">7.1 </span>
            <span style="background-color: transparent"
              >Сторони звільняються від відповідальності за невиконання або
              неналежне виконання зобов'язань, що передбачені даним Договором,
              якщо воно виникло внаслідок форс-мажорних обставин.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">7.2 </span>
            <span style="background-color: transparent"
              >Під форс-мажорними обставинами у даному Договорі розуміються
              будь-які обставини, що виникли поза волею або всупереч волі чи
              бажанню Сторін і яких не можна передбачити чи уникнути, включаючи:
              військові дії, громадські заворушення, епідемії, блокаду,
              землетруси, повені, пожежі, а також рішення чи приписи органів
              державної влади та управління держави, резидентом якої є Замовник,
              або держави, резидентом якої є Агент, внаслідок яких на Сторони
              (або одну зі Сторін) покладатимуться додаткові обов'язки чи
              встановлюватимуться додаткові обмеження і які роблять неможливим
              подальше повне або часткове виконання Договору, а також інші дії
              чи події, що існують поза волею Сторін.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">7.3 </span>
            <span style="background-color: transparent"
              >Якщо форс-мажорні обставини триватимуть більше 3-х (трьох)
              місяців поспіль, то кожна зі Сторін матиме право відмовитись від
              подальшого виконання зобов'язань за цим Договором і, в такому
              разі, жодна із Сторін не матиме права на відшкодування іншою
              Стороною можливих збитків.&nbsp;</span
            >
          </p>
          <p class="ql-align-center"><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">8. </strong>
            <strong style="background-color: transparent"
              >ІНШІ УМОВИ&nbsp;</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.1 </span>
            <span style="background-color: transparent"
              >Кожна Сторона гарантує іншій Стороні, що володіє необхідною
              дієздатністю, а рівно всіма правами і повноваженнями, необхідними
              і достатніми для укладання і виконання цього Договору відповідно
              до його умов.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.2 </span>
            <span style="background-color: transparent"
              >Одностороння зміна умов укладеного Договору Замовником чи відмова
              виконувати умови укладеного Договору Замовником є неприпустимою,
              за винятків випадків, передбачених цим Договором. Жодна зі Сторін
              цього Договору не має права передавати свої права та обов’язки
              третім особам без згоди на це другої Сторони.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.3 </span>
            <span style="background-color: transparent"
              >Агент підтверджує, що є платником спрощеної системи оподаткування
              2-га група, передбаченою Податковим кодексом України.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.4 </span>
            <span style="background-color: transparent"
              >Інформація, що надається Замовником є конфіденційною. Інформація
              про Замовника використовується виключно в цілях надання Послуг за
              цим договором.</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.5 </span>
            <span style="background-color: transparent"
              >Власним акцептуванням Договору Замовник добровільно надає згоду
              на збір та обробку власних персональних даних з наступною метою:
              дані, що стають відомі, використовуватимуться в комерційних цілях,
              в тому числі отримання інформації про замовлення та обробки
              інформації про нього, надсилання телекомунікаційними засобами
              зв’язку (електронною поштою, мобільним зв’язком) рекламних та
              спеціальних пропозицій, інформації про акції або будь-якої іншої
              інформації про діяльність Веб-сайту Агенту. У випадку небажання
              отримувати інформацію про діяльність Веб-сайту Агенту, Клієнт має
              право звернутися до Агент, написавши заяву про відмову від
              отримання рекламних матеріалів та надіславши її на поштову або
              електронну адресу Агента.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.6 </span>
            <span style="background-color: transparent"
              >Сторони зобов’язуються зберігати конфіденційну інформацію,
              отриману в результаті виконання цього Договору, за винятком
              випадків, коли це письмово санкціоновано іншою Стороною або
              вимагається державними органами відповідно до чинного
              законодавства. За розголошення конфіденційної інформації винна
              Сторона несе відповідальність згідно з чинним
              законодавством.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.7 </span>
            <span style="background-color: transparent"
              >Договір є публічним і безстроковим та діє до його припинення
              будь-якою зі Сторін у порядку, встановленому цим Договором або
              чинним законодавством, але у будь-якому випадку до моменту
              остаточного його виконання Сторонами. Сторони погодили, що строк
              дії цього Договору не може становити менше як 3 (три) календарні
              місяців. Цей Договір вважається погодженим Клієнтом та укладеним
              за місцезнаходженням Агент з дати акцептування.&nbsp;</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.8 </span>
            <span style="background-color: transparent"
              >Агент самостійно у відповідності та на виконання вимог чинного
              законодавства України визначає умови даного Договору та додатків
              до нього, які є його невід’ємними частинами. Агент має право
              самостійно змінити та/або доповнити умови цього публічного
              Договору та додатків до нього, включаючи правила надання та
              отримання Послуг за даним Договором. При цьому Агент гарантує та
              підтверджує, що розміщена на Веб-сайті поточна редакція тексту
              цього Договору та додатків до нього, включаючи правила надання та
              отримання Послуг за даним Договором, є дійсною.</span
            >
          </p>
          <p class="ql-indent-1">
            <span style="background-color: transparent">8.9 </span>
            <span style="background-color: transparent"
              >Недійсність окремих положень Договору не впливає на дійсність
              будь-яких інших положень Договору.</span
            >
          </p>
          <p class="ql-indent-1">
            <span style="background-color: transparent">8.10 </span>
            <span style="background-color: transparent"
              >Заголовки розділів наводяться лише для зручності і не впливають
              на їх тлумачення.</span
            >
          </p>
          <p class="ql-align-center"><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">9. </strong>
            <strong style="background-color: transparent">РЕКВІЗИТИ ФОП</strong>
          </p>
          <p><br /></p>
          <p>
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >ФОП Левченко Людмила Іванівна</span
            >
          </p>
          <p>
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >м. Одеса, вул. Жемчужна 5А</span
            >
          </p>
          <p>
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >ЄДРПОУ/ІНН 2748210085</span
            >
          </p>
          <p>
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
              >IBAN UA443220010000026000320054098 в АТ «Універсал Банк»</span
            >
          </p>
          <p><br /></p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Приймаю </v-btn>
      </v-card-actions>
    </v-card>
    <vue-editor class="d-none" />
  </v-dialog>
</template>

<script>
export default {
  name: "terms",
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    locale() {
      return this.$store.getters["app/getSelectedLocale"] === "ru";
    },
  },
};
</script>

<style scoped>
.blue-font {
  color: #00a4b7 !important;
}
</style>
