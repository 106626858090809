import RedactorServices from "../../services/api/redactor.services";

let state = {
  allBlogsList: {
    meta: {
      last_page: 1,
    },
  },
  publishedBlogsList: {
    meta: {
      last_page: 1,
    },
  },
  draftBlogsList: {},
  waitingBlogsList: {},
  categories: {},
  post: {},
  selectedCategories: [],
};

const mutations = {
  SET_ALL_BLOGS_LIST(state, payload) {
    state.allBlogsList = payload;
  },
  SET_PUBLISHED_BLOGS_LIST(state, payload) {
    state.publishedBlogsList = payload;
  },
  SET_DRAFT_BLOGS_LIST(state, payload) {
    state.draftBlogsList = payload;
  },
  SET_WAITING_BLOGS_LIST(state, payload) {
    state.waitingBlogsList = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_POST(state, payload) {
    state.post = payload;
  },
  SET_SELECTED_CATEGORIES(state, payload) {
    state.selectedCategories = payload;
  },
};

const actions = {
  // eslint-disable-next-line no-empty-pattern
  postBlogImg({}, payload) {
    return new Promise((resolve, reject) => {
      RedactorServices.post("postBlogImg", {}, payload, {
        // headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  searchBlogByTitle({ commit }, params) {
    return new Promise((resolve, reject) => {
      RedactorServices.get("searchBlogByTitle", params)
        .then((response) => {
          commit("SET_PUBLISHED_BLOGS_LIST", response.data);
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getAllBlogsList({ commit }, page) {
    return new Promise((resolve, reject) => {
      RedactorServices.get("getAllBlogsList", page)
        .then((response) => {
          commit("SET_ALL_BLOGS_LIST", response.data);
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },
  getAllBlogsForClient({ commit }, page) {
    return new Promise((resolve, reject) => {
      RedactorServices.get("getAllBlogsForClient", page)
        .then((response) => {
          commit("SET_PUBLISHED_BLOGS_LIST", response.data);
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },

  getPublishedBlogsList({ commit }, page) {
    return new Promise((resolve, reject) => {
      RedactorServices.get("getPublishedBlogsList", page)
        .then((response) => {
          commit("SET_PUBLISHED_BLOGS_LIST", response.data);
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },
  getDraftBlogsList({ commit }) {
    return new Promise((resolve, reject) => {
      RedactorServices.get("getDraftBlogsList", {})
        .then((response) => {
          commit("SET_DRAFT_BLOGS_LIST", response.data);
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },
  getWaitingBlogsList({ commit }) {
    return new Promise((resolve, reject) => {
      RedactorServices.get("getWaitingBlogsList", {})
        .then((response) => {
          commit("SET_WAITING_BLOGS_LIST", response.data);
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },

  createBlog({ commit }, data) {
    return new Promise((resolve, reject) => {
      RedactorServices.post("createBlog", {}, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          commit("SET_ALL_BLOGS_LIST", response.data);
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  editBlog({ commit }, payload) {
    return new Promise((resolve, reject) => {
      RedactorServices.post("editBlog", payload.id, payload.data, {})
        .then((response) => {
          commit("SET_ALL_BLOGS_LIST", response.data);
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getCategoriesListForUser({ commit }) {
    return new Promise((resolve, reject) => {
      RedactorServices.get("getCategoriesForUsers", {})
        .then((response) => {
          commit("SET_CATEGORIES", response.data);
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },
  getCategoriesList({ commit }) {
    return new Promise((resolve, reject) => {
      RedactorServices.get("getCategories", {})
        .then((response) => {
          commit("SET_CATEGORIES", response.data);
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },
  createCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      RedactorServices.post("createCategory", {}, data, {})
        .then((response) => {
          commit("SET_CATEGORIES", response.data);
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },
  deleteCategory({ commit }, id) {
    return new Promise((resolve, reject) => {
      RedactorServices.delete("deleteCategory", id, {})
        .then((response) => {
          commit("SET_CATEGORIES", response.data);
          return resolve("");
        })
        .catch((error) => reject(error));
    });
  },
  getPost({ commit }, data) {
    return new Promise((resolve, reject) => {
      RedactorServices.get("getPost", data)
        .then((response) => {
          commit("SET_POST", response.data.data);
          return resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};

const getters = {
  getAllBlogsList: (state) => state.allBlogsList,
  getPublishedBlogsList: (state) => state.publishedBlogsList,
  getDraftBlogsList: (state) => state.draftBlogsList,
  getWaitingBlogsList: (state) => state.waitingBlogsList,
  getCategoriesList: (state) => state.categories,
  getPost: (state) => state.post,
  getSelectedCategoriesList: (state) => state.selectedCategories,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
