<template>
  <verify-component></verify-component>
</template>

<script>
import VerifyComponent from "@/components/registration/verifyComponent";
export default {
  name: "verify",
  components: { VerifyComponent },
};
</script>

<style scoped></style>
