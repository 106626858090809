<template>
  <v-container>
    <v-form ref="form" id="thirdStepForm" @submit.prevent="submit"> </v-form>
    <v-row>
      <v-col cols="12" md="6">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepThree.workExperience"
            )
          }}
        </p>
      </v-col>
      <v-col md="6">
        <v-text-field
          id="experience_ages"
          form="thirdStepForm"
          v-show="!instant"
          v-model="psychologistsList.experience_ages"
          :label="
            $vuetify.lang.t(
              '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepThree.workExperience'
            )
          "
          filled
        ></v-text-field>
        <v-text-field
          v-show="instant"
          v-model="backupList.experience_ages"
          dense
          readonly
          :label="
            $vuetify.lang.t(
              '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepThree.workExperience'
            )
          "
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-3">
      <v-col cols="12" md="6">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepThree.additionalProgram"
            )
          }}
        </p>
      </v-col>
      <v-col md="6">
        <v-text-field
          id="courses"
          form="thirdStepForm"
          v-show="!instant"
          v-model="psychologistsList.courses"
          :label="
            $vuetify.lang.t(
              '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepThree.additionalProgram'
            )
          "
          filled
        ></v-text-field>
        <v-text-field
          v-show="instant"
          v-model="backupList.courses"
          dense
          readonly
          :label="
            $vuetify.lang.t(
              '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepThree.additionalProgram'
            )
          "
        ></v-text-field>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-3">
      <v-col cols="12" md="6">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepThree.selfTherapy"
            )
          }}
        </p>
      </v-col>
      <v-col md="6">
        <v-row class="pt-4 pl-3">
          <span
            v-if="!psychologistsList.self_psychotherapy"
            class="justify-center"
            >{{
              $vuetify.lang.t(
                "$vuetify.main.adminDashboard.psychologists.switcherLabel.no"
              )
            }}</span
          >
          <v-switch
            v-model="psychologistsList.self_psychotherapy"
            class="mt-0 pt-0 ml-2"
            disabled
          ></v-switch>
          <span v-if="psychologistsList.self_psychotherapy">{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.switcherLabel.yes"
            )
          }}</span>
        </v-row>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-3">
      <v-col cols="12" md="6">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepThree.regularSupervision"
            )
          }}
        </p>
      </v-col>
      <v-col md="6">
        <v-row class="pt-4 pl-3">
          <span
            v-if="!psychologistsList.regular_supervision"
            class="justify-center"
          >
            {{
              $vuetify.lang.t(
                "$vuetify.main.adminDashboard.psychologists.switcherLabel.no"
              )
            }}</span
          >
          <v-switch
            v-model="psychologistsList.regular_supervision"
            class="mt-0 pt-0 ml-2"
            disabled
          ></v-switch>
          <span v-if="psychologistsList.regular_supervision">{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.switcherLabel.yes"
            )
          }}</span>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import prepareData from "@/mixins/prepareData";
import updateData from "@/mixins/updateData";

export default {
  name: "stepThree",
  mixins: [prepareData, updateData],
  props: {
    psychologistsList: Object,
    instantValue: Boolean,
    saveIsNeed: Boolean,
  },
  data() {
    return {
      payload: {},
      backupList: null,
    };
  },
  created() {
    this.backupList = JSON.parse(JSON.stringify(this.psychologistsList)); // делаем независимую копию обьекта
  },
  watch: {
    saveIsNeed() {
      if (this.saveIsNeed) this.submit();
    },
  },
  computed: {
    instant() {
      return this.instantValue;
    },
  },
  methods: {
    submit() {
      let data = Array.from(this.$refs.form.$el.elements);
      data.forEach((el) => {
        this.prepareData(el);
      });
      this.updateData();
    },
  },
};
</script>

<style scoped></style>
