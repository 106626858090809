<template>
  <div>
    <blog-component
      v-if="
        this.$store.getters['user/getUser'].role === 'admin' ||
        this.$store.getters['user/getUser'].role === 'redactor'
      "
    ></blog-component>
    <public-blog
      v-if="
        this.$store.getters['user/getUser'].role === 'psychologist' ||
        this.$store.getters['user/getUser'].role === 'patient'
      "
    ></public-blog>
  </div>
</template>

<script>
import BlogComponent from "@/components/dashboard/adminDashboard/blogComponent";
import publicBlog from "../../components/dashboard/publicBlog";
export default {
  name: "blog",
  components: { BlogComponent, publicBlog },
};
</script>

<style scoped></style>
