import en from "./en";
import uk from "./uk";
import ru from "./ru";

import store from "@/store";
const fallBackLang = "ru";
const messages = {
  en,
  uk,
  ru,
};
const getBrowserLocale = () => {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) return undefined;

  return navigatorLocale.trim().split("-").shift();
};
const getStoreLocale = store.getters["app/getSelectedLocale"];

const defaultLanguage = getStoreLocale
  ? getStoreLocale
  : ["uk", "ru"].includes(getBrowserLocale())
  ? getBrowserLocale()
  : fallBackLang;

store.dispatch("app/setDefaultLocale", defaultLanguage);

export { messages, defaultLanguage };
