<template>
  <section v-if="post.content" class="post container">
    <v-card>
      <v-card-title>
        <h2 class="section-title post__title">
          {{ currentLocal(post.title) }}
        </h2>
      </v-card-title>
      <v-card-text>
        <div class="ql-editor container" v-html="currentLocal(post.content)">
          <vue-editor class="d-none"></vue-editor>
        </div>
        <span class="post__published d-block text-right font-italic pr-5">{{
          post.published_at
        }}</span>
        <div class="post__buttons-group d-flex justify-space-between mt-16">
          <router-link class="post__route" :to="`/blog/posts/${previousSlug}`">
            <v-btn
              color="primary"
              elevation="8"
              rounded
              small
              class="post__button pr-5"
              v-if="checkForPrevious"
              @click="toPreviousPost"
            >
              <v-icon dark> mdi-chevron-left </v-icon>
              {{ this.$vuetify.lang.t("$vuetify.view.blog.previousPost") }}
            </v-btn>
          </router-link>
          <router-link
            class="post__route post__route--close"
            :to="{
              name: 'blog',
              params: { page: page },
            }"
          >
            <v-btn
              color="primary"
              elevation="8"
              rounded
              small
              class="post__button pl-5"
            >
              {{ this.$vuetify.lang.t("$vuetify.view.blog.close") }}
            </v-btn>
          </router-link>
          <router-link class="post__route" :to="`/blog/posts/${nextSlug}`">
            <v-btn
              color="primary"
              elevation="8"
              rounded
              small
              class="post__button pl-5"
              v-if="checkForEnd"
              @click="toNextPost"
            >
              {{ this.$vuetify.lang.t("$vuetify.view.blog.nextPost") }}
              <v-icon dark> mdi-chevron-right </v-icon>
            </v-btn>
          </router-link>
        </div></v-card-text
      >
    </v-card>
  </section>
  <div class="text-right mt-6 mr-6" v-else>
    <v-progress-circular :width="3" color="red" indeterminate>
    </v-progress-circular>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "blog-post",
  data() {
    return {
      index: null,
      previousSlug: null,
      nextSlug: null,
      page: null,
      categories: "",
      title: "",
    };
  },
  beforeRouteEnter(routeTo, RouteFrom, next) {
    store
      .dispatch("blog/getPost", { slug: routeTo.params.slug })
      .then((response) => {
        next((vm) => {
          vm.page = routeTo.params.page;
          vm.title = routeTo.params.title;
          vm.categories = routeTo.params.categories;
          vm.nextSlug = response.data.data.next_post_slug;
          vm.previousSlug = response.data.data.previous_post_slug;
        });
      });
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "blog") {
      to.params.page = this.page;
      to.params.title = this.title;
      to.params.categories = this.categories;
    }
    next();
  },
  metaInfo() {
    return {
      title: this.currentLocal(this.post.seo_title),
      meta: [
        {
          name: "description",
          content: this.currentLocal(this.post.seo_description),
        },
      ],
    };
  },
  computed: {
    post() {
      return this.$store.getters["blog/getPost"];
    },
    checkForPrevious() {
      return !!this.previousSlug;
    },
    checkForEnd() {
      return !!this.nextSlug;
    },
    localisation() {
      return this.$store.getters["app/getSelectedLocale"];
    },
  },
  methods: {
    toPreviousPost() {
      this.$store
        .dispatch("blog/getPost", { slug: this.previousSlug })
        .then((response) => {
          this.nextSlug = response.data.data.next_post_slug;
          this.previousSlug = response.data.data.previous_post_slug;
        });
    },
    toNextPost() {
      this.$store
        .dispatch("blog/getPost", { slug: this.nextSlug })
        .then((response) => {
          this.nextSlug = response.data.data.next_post_slug;
          this.previousSlug = response.data.data.previous_post_slug;
        });
    },
    currentLocal(text) {
      if (this.localisation === "ru" && text.ru) return text.ru;
      else if (this.localisation === "uk" && text.uk) return text.uk;
      else return text.ru ? text.ru : text.uk;
    },
  },
};
</script>

<style lang="scss" scoped>
.post {
  padding: 70px 20px 10px;
  &__title {
    color: #575756;
  }
  &__route {
    text-decoration: none;
    color: #575756;
    &--close {
      position: relative;
      z-index: 1;
    }
    &--close button {
      padding: 5px 20px !important;
    }
  }
  &__button > v-btn__content {
    padding: 4px;
  }
  &__published {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 610px) {
  .post__buttons-group {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}
</style>
