<template>
  <RedactorsComponent></RedactorsComponent>
</template>

<script>
const RedactorsComponent = () =>
  import("../../../components/dashboard/adminDashboard/redactorsComponent.vue");
export default {
  name: "redactors",
  components: { RedactorsComponent },
};
</script>

<style scoped></style>
