<template>
  <v-form v-model="valid" ref="psychStepTwoForm">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="4">
          <p class="font-weight-medium text-center">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepTwo.mainTreatmentMethod"
              )
            }}
          </p>
          <v-radio-group v-model="userSelect">
            <v-radio
              v-for="(item, i) in radioBtnItems"
              :key="i + item.title"
              v-bind:label="item.title"
              v-bind:value="item.id"
            >
            </v-radio>
          </v-radio-group>
          <v-divider class="mt-2 mb-5"></v-divider>
          <p class="font-weight-medium text-center">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepTwo.additionalEducation"
              )
            }}
          </p>
          <v-checkbox
            v-for="(item, i) in additionalEducation"
            v-model="selected"
            v-bind:label="item.title"
            :key="item.title + i"
            v-bind:value="item.id"
            hide-details="true"
            class="mt-0 pt-0 mb-0 pb-0"
          >
          </v-checkbox>
          <v-checkbox
            v-model="anotherSelectTextArea"
            :label="
              this.$vuetify.lang.t(
                '$vuetify.main.psychologistReg.stepTwo.another'
              )
            "
            value=""
          >
          </v-checkbox>
          <p class="text-center" v-if="anotherSelectTextArea">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepTwo.additionalEducationDesk"
              )
            }}
          </p>
          <v-textarea
            v-if="anotherSelectTextArea"
            v-model="additional_knowledge_other"
            class="mx-2"
            rows="1"
            prepend-icon="mdi-comment"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="1">
          <v-divider v-if="!window.isVisibleDivider" vertical></v-divider>
          <v-divider v-if="window.isVisibleDivider"></v-divider>
        </v-col>
        <v-col cols="12" md="7" class="mt-2">
          <v-row justify="center" class="mb-3">
            <p class="font-weight-medium text-center">
              {{
                this.$vuetify.lang.t(
                  "$vuetify.main.psychologistReg.stepTwo.problems"
                )
              }}
            </p>
          </v-row>
          <div v-for="(events, index) in psychologistEvents" v-bind:key="index">
            <p class="ma-0 pt-0 font-weight-medium">
              {{
                $vuetify.lang.t(
                  `$vuetify.main.psychologistDashboard.specialization.${index}`
                )
              }}
            </p>
            <div class="events__wrapper">
              <v-checkbox
                v-ripple
                @change="handleChangeEvents(index, i)"
                v-for="(event, i) in events"
                ripple
                v-bind:label="event"
                :key="i"
                v-bind:value="i"
                :hide-details="true"
                class="item"
              >
              </v-checkbox>
            </div>
            <!--            <v-divider-->
            <!--              v-if="index === 'life_event_problems'"-->
            <!--              class="mt-2 mb-5"-->
            <!--            ></v-divider>-->
            <!--            <p-->
            <!--              class="font-weight-medium pb-2"-->
            <!--              v-if="index === 'life_event_problems'"-->
            <!--            >-->
            <!--              {{-->
            <!--                $vuetify.lang.t("$vuetify.main.patientReg.stepTwo.whatDiscuss")-->
            <!--              }}-->
            <!--            </p>-->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  name: "stepTwo",
  data() {
    return {
      valid: false,
      window: {
        isVisibleDivider: false,
        width: 0,
      },
      selected: [],

      selectedLifeEvents: [],
      mentalHealthConditionProblems: [],
      relationshipProblems: [],
      careerProblems: [],

      userSelect: "",
      anotherSelectTextArea: false,
      additional_knowledge_other: "",
      radioGroup: 1,
      column: null,
      enabled: false,
      psychologistEvents: null,
    };
  },
  watch: {
    anotherSelectTextArea() {
      if (!this.anotherSelectTextArea) {
        this.additional_knowledge_other = "";
      }
    },
  },
  created() {
    this.$store.dispatch("user/getPsychologistFilter").then((response) => {
      this.psychologistEvents = response.data.data;
    });
    window.addEventListener("resize", this.handleClientWidth);
    this.handleClientWidth();
    this.$eventHub.$on("psych-step-two-form_validate-form", this.validate);
  },
  beforeDestroy() {
    this.$eventHub.$off("psych-step-two-form_validate-form");
  },
  destroyed() {
    window.removeEventListener("resize", this.handleClientWidth);
  },
  methods: {
    handleChangeEvents(index, value) {
      switch (index) {
        case "life_event_problems":
          this.handleItemInArray(value, this.selectedLifeEvents);
          break;
        case "career_problems":
          this.handleItemInArray(value, this.careerProblems);
          break;
        case "mental_health_condition_problems":
          this.handleItemInArray(value, this.mentalHealthConditionProblems);
          break;
        case "relationship_problems":
          this.handleItemInArray(value, this.relationshipProblems);
          break;
      }
    },
    handleItemInArray(value, array) {
      let itemIndex = array.indexOf(value);
      itemIndex === -1 ? array.push(value) : array.splice(itemIndex, 1);
    },
    handleClientWidth() {
      this.window.width = window.innerWidth;
      this.window.isVisibleDivider = this.window.width < 960;
    },
    validate() {
      this.valid =
        this.selectedLifeEvents.length +
        this.mentalHealthConditionProblems.length +
        this.relationshipProblems.length +
        this.careerProblems.length;
      if (this.valid || this.userSelect) {
        this.$store.dispatch("user/setQuestionnaireProfile", {
          main_treatment_method: this.userSelect,
          additional_knowledge: this.selected,
          additional_knowledge_other: this.additional_knowledge_other,
        });
        this.$store.dispatch("user/setQuestionnaireGeneral", {
          life_event_problems: this.selectedLifeEvents,
          mental_health_condition_problems: this.mentalHealthConditionProblems,
          relationship_problems: this.relationshipProblems,
          career_problems: this.careerProblems,
        });
        this.$eventHub.$emit("switch-psych-registration-tab", 3);
      } else {
        Vue.swal({
          target: document.getElementById("main"),
          text: this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepTwo.warning"
          ),
          toast: true,
          position: "top-right",
          icon: "error",
          timer: 4000,
          showConfirmButton: false,
        });
      }
    },
  },

  computed: {
    radioBtnItems() {
      return [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.art_therapy"
          ),
          id: "art_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.positive_psychotherapy"
          ),
          id: "positive_psychotherapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.client_centered_psychotherapy"
          ),
          id: "client_centered_psychotherapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.cognitive_behavioral_therapy"
          ),
          id: "cognitive_behavioral_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.body_therapy"
          ),
          id: "body_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.psychoanalysis"
          ),
          id: "psychoanalysis",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.jungian_analysis"
          ),
          id: "jungian_analysis",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.transactional_analysis"
          ),
          id: "transactional_analysis",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.family_therapy"
          ),
          id: "family_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.gestalt_therapy"
          ),
          id: "gestalt_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.psychodrama"
          ),
          id: "psychodrama",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.analytical_psychology"
          ),
          id: "analytical_psychology",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.symboldrama"
          ),
          id: "symboldrama",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.existential_analysis"
          ),
          id: "existential_analysis",
        },
      ];
    },
    additionalEducation() {
      return [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.addictions"
          ),
          id: "addictions",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.eating_disorders"
          ),
          id: "eating_disorders",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.sexology"
          ),
          id: "sexology",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.extreme_situations"
          ),
          id: "extreme_situations",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.events__wrapper {
  column-count: 2;
  margin-bottom: 20px;
}

.item {
  padding: 8px;
  margin: -4px -4px;
  page-break-inside: avoid;
}
</style>
