<template>
  <v-col md="8" offset-md="2">
    <v-stepper v-model="el">
      <v-stepper-header>
        <v-stepper-step :complete="el > 1" step="1">
          {{ $vuetify.lang.t("$vuetify.main.patientReg.generalQuestions") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="el > 2" step="2">
          {{ $vuetify.lang.t("$vuetify.main.patientReg.personalQuestions") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="el > 3" step="3">{{
          $vuetify.lang.t("$vuetify.main.patientReg.therapists")
        }}</v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step step="4">
          {{
            $vuetify.lang.t("$vuetify.main.patientReg.terms")
          }}</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12 pb-3" min-height="200px">
            <stepOne></stepOne>
          </v-card>
          <div class="d-flex justify-end">
            <v-btn color="primary" @click="handleValidateForm(1)">
              {{ $vuetify.lang.t("$vuetify.main.patientReg.further") }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-12 pb-3" min-height="200px">
            <stepTwo></stepTwo>
          </v-card>
          <div class="d-flex justify-space-between">
            <v-btn @click="el = 1">
              {{ $vuetify.lang.t("$vuetify.main.patientReg.back") }}
            </v-btn>
            <v-btn color="primary" @click="handleValidateForm(2)">
              {{ $vuetify.lang.t("$vuetify.main.patientReg.further") }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" min-height="200px">
            <stepThree @userSelect="savePropsFromChild"></stepThree>
          </v-card>
          <div class="d-flex justify-space-between flex-xs-column">
            <v-btn @click="el = 2">
              {{ $vuetify.lang.t("$vuetify.main.patientReg.back") }}
            </v-btn>
            <v-btn
              v-if="patientTimeSelect"
              class="mt-xs-8"
              color="primary"
              @click="el = 4"
            >
              {{
                patientTimeSelect
                  ? patientTimeSelect
                  : $vuetify.lang.t("$vuetify.main.patientReg.further")
              }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card class="mb-12" min-height="200px">
            <stepFour></stepFour>
          </v-card>
          <div class="d-flex justify-space-between">
            <v-btn @click="el = 3">
              {{ $vuetify.lang.t("$vuetify.main.patientReg.back") }}
            </v-btn>
            <v-btn color="primary" @click="patientRegistration">
              {{ $vuetify.lang.t("$vuetify.main.patientReg.apply") }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-col>
</template>

<script>
const stepOne = () =>
  import("@/components/registration/registrationSteps/patient/stepOne");
const stepTwo = () =>
  import("@/components/registration/registrationSteps/patient/stepTwo");
const stepThree = () =>
  import("@/components/registration/registrationSteps/patient/stepThree");
const stepFour = () =>
  import("@/components/registration/registrationSteps/patient/stepFour");
import Vue from "vue";

export default {
  components: {
    stepThree,
    stepOne,
    stepTwo,
    stepFour,
  },
  name: "userRegForm",
  data() {
    return {
      el: 1,
      patientTimeSelect: null,
    };
  },
  created() {
    this.$eventHub.$on("switch-patient-registration-tab", this.switchTab);
  },
  beforeDestroy() {
    this.$eventHub.$off("switch-patient-registration-tab");
  },
  methods: {
    switchTab(value) {
      this.el = value;
    },
    handleValidateForm(step) {
      switch (step) {
        case 1:
          return this.$eventHub.$emit("patient-step-one-form_validate-form");
        case 2:
          return this.$eventHub.$emit("patient-step-two-form_validate-form");
        case 3:
          return this.$eventHub.$emit("patient-step-three-form_validate-form");
      }
    },
    savePropsFromChild(btnSelect) {
      this.patientTimeSelect =
        this.$vuetify.lang.t("$vuetify.main.patientReg.select") +
        " " +
        btnSelect.date.translated +
        " " +
        btnSelect.slot.time;
    },

    patientRegistration() {
      let payload = {
        locale: this.$store.getters["app/getSelectedLocale"],
        phone: this.$store.getters["user/getUserPhone"],
        phone_country_code: this.$store.getters["user/getUserPhoneCountryCode"],
        email: this.$store.getters["user/getUserEmail"],
        general_questions:
          this.$store.getters["user/getPatientGeneralQuestions"],
        questionnaire: {
          name: this.$store.getters["user/getPatientQuestionnaire"]
            .questionnaire.name,
          age: this.$store.getters["user/getPatientQuestionnaire"].questionnaire
            .age,
          has_therapy_experience:
            this.$store.getters["user/getPatientQuestionnaire"].questionnaire
              .has_therapy_experience,
          timezone: this.$store.getters["user/getUser"].timezone,
          profile_id:
            this.$store.getters["user/getPatientQuestionnaire"].questionnaire
              .profile_id,
          calendar_id:
            this.$store.getters["user/getPatientQuestionnaire"].questionnaire
              .calendar_id,
        },
      };
      this.$store
        .dispatch("user/patientRegistration", payload)
        .then((response) => {
          Vue.swal({
            target: document.getElementById("main"),
            text: response.data.message,
            icon: "success",
            timer: 4000,
            toast: true,
            position: "top-right",
            showConfirmButton: false,
          });
          if (response.status === 200) {
            this.$router.push("/dashboard/main");
          }
        });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .flex-xs-column {
    flex-direction: column;
  }

  .mt-xs-8 {
    margin-top: 12%;
  }
}
</style>
