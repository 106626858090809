<template>
  <v-container>
    <v-row>
      <v-col class="d-flex align-center" cols="12" md="2">
        <p class="mb-0">
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepTwo.mainTreatmentMethod"
            )
          }}:
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <div>
          <v-select
            v-show="!instant"
            :items="mainMethodList"
            v-model="psychologistsList.main_treatment_method_value"
            item-value="id"
            item-text="title"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.psychologistReg.stepTwo.mainTreatmentMethod`
              )
            "
          ></v-select>
          <p v-show="instant">{{ backupList.main_treatment_method }}</p>
        </div>
      </v-col>
    </v-row>
    <v-divider
      v-if="psychologistsList.additional_knowledge.length"
      class="mt-2 mb-5"
    ></v-divider>
    <v-row v-if="psychologistsList.additional_knowledge.length">
      <v-col cols="12" md="2">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepTwo.additionalEducation"
            )
          }}:
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <div
          v-show="instant"
          v-for="(
            additionalEducation, i
          ) in psychologistsList.additional_knowledge"
          :key="i"
        >
          <p>{{ psychologistsList.additionalEducation }}</p>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-5"></v-divider>
    <v-row>
      <v-col cols="12" md="2">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepTwo.problems"
            )
          }}:
        </p>
      </v-col>
      <v-col cols="12" md="3">
        <div
          v-for="(lifeEventsTopic, i) in psychologistsList.answers
            .life_event_problems"
          :key="i"
        >
          <p>{{ lifeEventsTopic }}</p>
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <!--        <v-select-->
        <!--            v-show="!instant"-->
        <!--            :items="careerList"-->
        <!--            v-model="selectedCareer"-->
        <!--            item-value="id"-->
        <!--            item-text="title"-->
        <!--            multiple-->
        <!--            :label="-->
        <!--              $vuetify.lang.t(-->
        <!--                `$vuetify.main.psychologistReg.stepTwo.mainTreatmentMethod`-->
        <!--              )-->
        <!--            "-->
        <!--        ></v-select>-->
        <div
          v-for="(
            discussTopic, i
          ) in psychologistsList.answers.career_problems.concat(
            psychologistsList.answers.relationship_problems
          )"
          :key="i"
        >
          <p>{{ discussTopic }}</p>
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <div
          v-for="(mentalHealthTopic, i) in psychologistsList.answers
            .mental_health_condition_problems"
          :key="i"
        >
          <p>{{ mentalHealthTopic }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import updateData from "@/mixins/updateData";
export default {
  name: "stepTwo",
  props: {
    psychologistsList: Object,
    instantValue: Boolean,
    saveIsNeed: Boolean,
  },
  mixins: [updateData],
  data() {
    return {
      payload: {
        profile: {},
      },
      // selectedMethod: "",
      backupList: {},
      selectedCareer: [],
      selectedlifeEvents: [],
      selectedHealth: [],
      selectedRelationship: [],
      answers: {},
      answersValues: {},
    };
  },
  watch: {
    saveIsNeed() {
      if (this.saveIsNeed) this.submit();
    },
  },
  created() {
    this.backupList = JSON.parse(JSON.stringify(this.psychologistsList)); // делаем независимую копию обьекта
  },
  computed: {
    instant() {
      return this.instantValue;
    },
    careerList() {
      if (!this.answers.career_problems) return [];
      let arr = [];
      for (let i = 0; i < this.answers.career_problems.length; i++) {
        let obj = {};
        obj.title = this.answers.career_problems[i];
        obj.id = this.answersValues.career_problems[i];
        arr.push(obj);
      }
      return arr;
    },
    lifeEventsList() {
      if (!this.answers.life_event_problems) return [];
      let arr = [];
      for (let i = 0; i < this.answers.life_event_problems.length; i++) {
        let obj = {};
        obj.title = this.answers.life_event_problems[i];
        obj.id = this.answersValues.life_event_problems[i];
        arr.push(obj);
      }
      return arr;
    },
    mentalHealthList() {
      if (!this.answers.mental_health_condition_problems) return [];
      let arr = [];
      for (
        let i = 0;
        i < this.answers.mental_health_condition_problems.length;
        i++
      ) {
        let obj = {};
        obj.title = this.answers.mental_health_condition_problems[i];
        obj.id = this.answersValues.mental_health_condition_problems[i];
        arr.push(obj);
      }
      return arr;
    },
    relationshipList() {
      if (!this.answers.relationship_problems) return [];
      let arr = [];
      for (let i = 0; i < this.answers.relationship_problems.length; i++) {
        let obj = {};
        obj.title = this.answers.relationship_problems[i];
        obj.id = this.answersValues.relationship_problems[i];
        arr.push(obj);
      }
      return arr;
    },
    mainMethodList() {
      return [
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.art_therapy"
          ),
          id: "art_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.positive_psychotherapy"
          ),
          id: "positive_psychotherapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.client_centered_psychotherapy"
          ),
          id: "client_centered_psychotherapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.cognitive_behavioral_therapy"
          ),
          id: "cognitive_behavioral_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.body_therapy"
          ),
          id: "body_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.psychoanalysis"
          ),
          id: "psychoanalysis",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.jungian_analysis"
          ),
          id: "jungian_analysis",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.transactional_analysis"
          ),
          id: "transactional_analysis",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.family_therapy"
          ),
          id: "family_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.gestalt_therapy"
          ),
          id: "gestalt_therapy",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.psychodrama"
          ),
          id: "psychodrama",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.analytical_psychology"
          ),
          id: "analytical_psychology",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.symboldrama"
          ),
          id: "symboldrama",
        },
        {
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepTwo.existential_analysis"
          ),
          id: "existential_analysis",
        },
      ];
    },
  },
  methods: {
    submit() {
      this.payload.profile.main_treatment_method =
        this.psychologistsList.main_treatment_method_value;
      this.updateData();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-field {
  font-size: 0.875rem;
}
</style>
