import ApiClient from "@/libs/http-client";

const AuthService = {
  endpoints(route) {
    const url = {
      sendCode: "api/auth/login/code/attempt",
      verifyCode: "api/auth/login/code/verify",
      viberSignIn: "api/auth/login/code/send-viber",
      viberSignUp: "api/auth/register/code/send-viber",
    };

    return url[route];
  },

  get(url, param) {
    return ApiClient.get(this.endpoints(url), param);
  },
  post(url, data, config = {}) {
    return ApiClient.post(this.endpoints(url), data, config);
  },
};

export default AuthService;
