<template>
  <div>
    <categoriesComponent></categoriesComponent>
  </div>
</template>

<script>
import categoriesComponent from "../../../components/dashboard/adminDashboard/categoriesComponent";
export default {
  name: "categories",
  components: { categoriesComponent },
};
</script>

<style scoped></style>
