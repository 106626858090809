<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "authLayout",
  metaInfo() {
    return {
      title: "Вхід до кабінету Платформи",
      meta: [
        {
          vmid: "og:description",
          property: "og:description",
          name: "description",
          content: "Вхід та реєстрація на сервісі за номером телефона.",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
