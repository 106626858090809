<template>
  <v-col cols="12" md="4" offset-md="4" align="center" class="mt-12" id="loginForm">
    <h1>{{ title }}</h1>
    <p class="font-weight-regular">
      {{ $vuetify.lang.t("$vuetify.view.signIn.title") }}
    </p>
    <vue-phone-number-input
      @verify="onSubmit"
      @keydown.native.enter="sendPhoneNumber"
      v-model="userPhoneNumber"
      :loader="hasLoaderActive"
      :error="hasErrorActive"
      :default-country-code="defaultCountry"
      :preferred-countries="countriesList"
      show-code-on-list
      required
      @update="updatedData"
      :translations="translations"
      clearable
    />

    <p class="mt-8 text-sm-body-2 font-weight-regular">
      {{ $vuetify.lang.t("$vuetify.view.signIn.termsPart1") }}
      <terms>{{ $vuetify.lang.t("$vuetify.view.signIn.termsPart2") }}</terms>
      {{ $vuetify.lang.t("$vuetify.view.signIn.termsPart3")
      }}<personal-data>{{
        $vuetify.lang.t("$vuetify.view.signIn.termsPart4")
      }}</personal-data>
      {{ $vuetify.lang.t("$vuetify.view.signIn.termsPart5") }}
    </p>
    <!-- add in this button data-action with correct actioon, here logic for login&register user -->
    <v-btn
      ref="submitButton"
      :disabled="!userData.isValid"
      class="ma-2"
      :loading="loading"
      color="primary"
      outlined
      @click="sendPhoneNumber"
    >
      {{ $vuetify.lang.t("$vuetify.view.signIn.code") }}
      <template v-slot:loader>
        <span>{{ $vuetify.lang.t("$vuetify.view.signIn.loading") }}</span>
      </template>
    </v-btn>
  </v-col>
</template>

<script>
const Terms = () => import("@/components/UI/personalTerms/terms");
const PersonalData = () => import("@/components/UI/personalTerms/personalData");

export default {
  name: "signInComponent",
  components: { PersonalData, Terms },
  
  mounted() {
    // Load the reCAPTCHA script when the component is mounted
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js?render="+process.env.VUE_APP_RECAPTCHA_KEY;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  },


  data() {
    return {
      userPhoneNumber: null,
      updateValue: "",
      defaultCountry: "",
      countriesList: ["UA", "RU", "BY", "MD"],
      hasLoaderActive: false,
      hasErrorActive: false,
      loader: null,
      loading: false,
      verifyUrl: "",
      userData: {},
      option: "",
      users: {
        admin: "successfully_sent_to_a_phone",
        simple: "successfully_sent_to_phone",
      },
    };
  },
  watch: {
    userData() {
      let countryCode = this.userData.countryCode;
      let nationalNumber = this.userData.nationalNumber;
      switch (countryCode) {
        case "UA":
          if (nationalNumber) {
            if (Array.from(nationalNumber).length > 9) {
              this.hasErrorActive = true;
              this.userData.isValid = false;
            } else {
              this.hasErrorActive = false;
            }
          }
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },
  created() {
    this.defaultCountry = this.getPhoneCountryCode || "UA";
    this.verifyUrl = this.$route.meta.url;
    this.option = this.$route.meta.option;
    this.userPhoneNumber = this.$store.getters["user/getUserPhone"];
  },
  computed: {
    getPhoneCountryCode() {
      return this.$store.getters["user/getUserPhoneCountryCode"];
    },
    title() {
      return this.$vuetify.lang.t(this.$route.meta.title);
    },
    translations() {
      return {
        countrySelectorLabel: this.$vuetify.lang.t(
          "$vuetify.view.signIn.countrySelectorLabel"
        ),
        countrySelectorError: this.$vuetify.lang.t(
          "$vuetify.view.signIn.countrySelectorError"
        ),
        phoneNumberLabel: this.$vuetify.lang.t(
          "$vuetify.view.signIn.phoneNumberLabel"
        ),
        example: this.$vuetify.lang.t("$vuetify.view.signIn.example"),
      };
    },
  },
  methods: {
    onSubmit() {
      this.$refs.submitButton.$el.click();
    },
    updatedData(data) {
      this.userData = data;
    },
    setDispatchType(option) {
      switch (option) {
        case "auth":
          return "user/sendCode";
        case "psychReg":
          return "user/sendUserRegCode";
        case "patientReg":
          return "user/sendUserRegCode";
      }
    },
    async sendPhoneNumber() {
      if (this.userData.isValid) {
        this.loader = "loading";

        const recaptchaToken = await new Promise(resolve => {
          const recaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY;
          window.grecaptcha.execute(recaptchaKey, { action: 'submit' }).then(token => resolve(token));
        });

        let payload = {
          phone: this.userData.formattedNumber,
          recaptchaToken: recaptchaToken,
        };
        await this.$store
          .dispatch(this.setDispatchType(this.option), JSON.stringify(payload))
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.$store.commit(
              "user/SET_ADMIN_TRIGGER",
              response.data.code === "successfully_sent_to_a_phone"
            );
            this.$router.push({
              path: this.verifyUrl,
            });
          })
          .catch((error) => {
            if (error instanceof TypeError) {
              this.$store.commit("user/SET_USER_PHONE", {
                phone: this.userData.formattedNumber,
                code: this.userData.countryCode,
              });
              this.$router.replace("/auth");
            }
            if (error.code === "user_not_exists")
              this.$router.replace("/registration");
          })
          .finally(() => {
            this.loader = false;
          });
        this.$store.commit("user/SET_USER_PHONE", {
          phone: this.userData.formattedNumber,
          code: this.userData.countryCode,
        });
      }
    },
  },
};
</script>

<style scoped></style>
