<template>
  <v-container>
    <v-form ref="form" id="fourthStepForm" @submit.prevent="submit"> </v-form>
    <v-row>
      <v-col cols="12" md="12" class="pb-0">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.aboutInfo"
            )
          }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-textarea
          form="fourthStepForm"
          ref="textarea"
          id="about_info.uk"
          v-show="!instant"
          :key="autoGrowHack"
          auto-grow
          rows="2"
          v-model="psychologistsList.translated.about_info.uk"
          :label="
            $vuetify.lang.t(
              `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
            )
          "
          filled
        ></v-textarea>
        <v-lazy>
          <v-textarea
            v-show="instant"
            v-model="backupList.translated.about_info.uk"
            solo
            auto-grow
            rows="2"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
              )
            "
            readonly
          ></v-textarea>
        </v-lazy>
      </v-col>
      <v-col cols="12" md="6">
        <v-textarea
          form="fourthStepForm"
          ref="textarea"
          id="about_info.ru"
          v-show="!instant"
          :key="autoGrowHack"
          auto-grow
          rows="2"
          v-model="psychologistsList.translated.about_info.ru"
          :label="
            $vuetify.lang.t(
              `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
            )
          "
          filled
        ></v-textarea>
        <v-lazy>
          <v-textarea
            v-show="instant"
            v-model="backupList.translated.about_info.ru"
            solo
            auto-grow
            rows="2"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
              )
            "
            readonly
          ></v-textarea>
        </v-lazy>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" md="5">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.notWorkingWith"
            )
          }}
        </p>
      </v-col>
      <v-col cols="12" md="6" offset-md="1">
        <v-textarea
          form="fourthStepForm"
          ref="textarea"
          id="about_do_not_work_with"
          v-show="!instant"
          :key="autoGrowHack"
          auto-grow
          rows="2"
          v-model="psychologistsList.about_do_not_work_with"
          :label="
            $vuetify.lang.t(
              `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
            )
          "
          filled
        ></v-textarea>
        <v-textarea
          v-show="instant"
          v-model="backupList.about_do_not_work_with"
          solo
          auto-grow
          rows="2"
          :label="
            $vuetify.lang.t(
              `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
            )
          "
          readonly
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" md="5">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.FOP"
            )
          }}
        </p>
      </v-col>
      <v-col cols="12" md="6" offset-md="1">
        <p v-if="!psychologistsList.entrepreneur">Нет</p>
        <div v-else>
          <v-textarea
            form="fourthStepForm"
            ref="textarea"
            id="entrepreneur"
            v-show="!instant"
            :key="autoGrowHack"
            auto-grow
            rows="2"
            v-model="psychologistsList.entrepreneur"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
              )
            "
            filled
          ></v-textarea>
          <v-textarea
            v-show="instant"
            v-model="backupList.entrepreneur"
            solo
            auto-grow
            rows="2"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
              )
            "
            readonly
          ></v-textarea>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" md="5">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.infoChannel"
            )
          }}
        </p>
      </v-col>
      <v-col cols="12" md="6" offset-md="1">
        <v-textarea
          form="fourthStepForm"
          ref="textarea"
          id="about_how_found_platform"
          v-show="!instant"
          :key="autoGrowHack"
          auto-grow
          rows="2"
          v-model="psychologistsList.about_how_found_platform"
          :label="
            $vuetify.lang.t(
              `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
            )
          "
          filled
        ></v-textarea>
        <v-textarea
          v-show="instant"
          v-model="backupList.about_how_found_platform"
          solo
          auto-grow
          rows="2"
          :label="
            $vuetify.lang.t(
              `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
            )
          "
          readonly
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" md="5">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.clientsQuantity"
            )
          }}
        </p>
      </v-col>
      <v-col cols="12" md="3" offset-md="1">
        <v-text-field
          form="fourthStepForm"
          id="about_min_patients"
          v-show="!instant"
          v-model="psychologistsList.about_min_patients"
          :label="
            $vuetify.lang.t(
              '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepFour.from'
            )
          "
          filled
        ></v-text-field>
        <v-text-field
          v-show="instant"
          v-model="backupList.about_min_patients"
          :label="
            $vuetify.lang.t(
              '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepFour.from'
            )
          "
          readonly
          class="mt-0 pt-0"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          form="fourthStepForm"
          id="about_max_patients"
          v-show="!instant"
          v-model="psychologistsList.about_max_patients"
          :label="
            $vuetify.lang.t(
              '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepFour.to'
            )
          "
          filled
        ></v-text-field>
        <v-text-field
          v-show="instant"
          v-model="backupList.about_max_patients"
          :label="
            $vuetify.lang.t(
              '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepFour.to'
            )
          "
          readonly
          class="mt-0 pt-0"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12" md="5">
        <p>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.profileLinks"
            )
          }}
        </p>
      </v-col>
      <v-col cols="12" md="6" offset-md="1">
        <v-textarea
          form="fourthStepForm"
          ref="textarea"
          id="about_social_media_links"
          v-show="!instant"
          :key="autoGrowHack"
          auto-grow
          rows="3"
          v-model="psychologistsList.about_social_media_links"
          :label="
            $vuetify.lang.t(
              `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
            )
          "
          filled
        ></v-textarea>
        <v-textarea
          v-show="instant"
          v-model="backupList.about_social_media_links"
          solo
          rows="3"
          auto-grow
          :label="
            $vuetify.lang.t(
              `$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer`
            )
          "
          readonly
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row class="pb-6 mt-0">
      <v-col cols="12" md="5">
        <v-btn
          color="primary"
          :href="psychologistsList.photo"
          download
          target="_blank"
          >{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.buttons.downloadPhoto"
            )
          }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import prepareData from "@/mixins/prepareData";
import updateData from "@/mixins/updateData";

export default {
  name: "stepFour",
  mixins: [prepareData, updateData],
  props: {
    psychologistsList: Object,
    instantValue: Boolean,
    saveIsNeed: Boolean,
  },
  data() {
    return {
      payload: {},
      backupList: null,
      autoGrowHack: false,
      textareaWidth: undefined,
      editorToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  created() {
    this.backupList = JSON.parse(JSON.stringify(this.psychologistsList)); // делаем независимую копию обьекта
  },
  watch: {
    saveIsNeed() {
      if (this.saveIsNeed) this.submit();
    },
    instantValue() {
      this.autoGrowHack = !this.autoGrowHack;
    },
  },
  computed: {
    instant() {
      return this.instantValue;
    },
  },
  methods: {
    submit() {
      let data = Array.from(this.$refs.form.$el.elements);
      data.forEach((el) => {
        this.prepareData(el);
      });
      this.updateData();
    },
  },
};
</script>

<style lang="scss" scoped></style>
