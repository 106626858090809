<template>
  <v-app app id="app" :key="lang">
    <header-component></header-component>
    <payment-alert></payment-alert>
    <v-main id="main">
      <router-view></router-view>
      <Confirm ref="confirm"></Confirm>
      <!-- Messenger Chat plugin Code -->
      <div id="fb-root"></div>

      <!-- Your Chat plugin code -->
    </v-main>
    <footer-component></footer-component>
  </v-app>
</template>

<script>
import FooterComponent from "@/components/main/footerComponent";
import Confirm from "@/components/UI/Confirm";
import PaymentAlert from "@/components/UI/alerts/paymentAlert";

const HeaderComponent = () => import("@/components/main/headerComponent");
export default {
  name: "App",
  components: { PaymentAlert, FooterComponent, HeaderComponent, Confirm },
  data() {
    return {
      lang: null,
    };
  },
  mounted() {},
  computed: {
    selectedLang() {
      return this.$store.getters["app/getSelectedLocale"];
    },
  },
  watch: {
    selectedLang: {
      immediate: true,
      handler(selectedLang) {
        this.$nextTick(() => {
          this.lang = selectedLang;
        });
        document.documentElement.setAttribute("lang", selectedLang);
      },
    },
  },
};
</script>
<style lang="scss">
@import "assets/fonts/variables.scss";
@mixin sectionTitle($size, $letterSpacing) {
  font-size: $size;
  font-weight: 500;
  text-align: center;
  letter-spacing: $letterSpacing;
  line-height: normal;
  color: $primary;
}
@mixin sectionSubtitle($size, $letterSpacing) {
  font-size: $size;
  position: relative;
  display: inline-block;
  font-weight: normal;
  text-align: center;
  letter-spacing: $letterSpacing;
  line-height: normal;
  color: $primaryGray;
  z-index: 1;
}
.section-title {
  @include sectionTitle(2rem, 1px);
}
.section-title-sm {
  @include sectionTitle(1.8rem, normal);
}
.section-subtitle {
  @include sectionSubtitle(1.6rem, 2px);
}
.section-subtitle-sm {
  @include sectionSubtitle(1.35rem, normal);
  display: block;
  margin: 0 auto;
}

.text--gotham-book {
  font-weight: 400;
}
.font-gotham {
  font-weight: 400 !important;
  font-family: $body-font-family !important;
}

.text-gray {
  color: $textGray;
}

.v-application {
  [class*="text-"] {
    font-family: $body-font-family !important;
  }
  font-family: $body-font-family !important;
}
.v-rating .v-icon {
  font-family: "Font Awesome 5 Free", serif !important;
}

.v-application .title {
  font-family: Gotham, sans-serif !important;
}
#main {
  position: relative;
}

body.swal2-shown {
  padding: 0 !important;
}

.swal2-container {
  margin-top: 65px;
}

.v-counter.error--text.theme--light {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>
