<template>
  <v-form v-model="valid" ref="psychStepThreeForm">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepThree.workExperience"
              )
            }}
          </p>
        </v-col>
        <v-col md="2" offset-md="2">
          <v-text-field
            v-model="consultingExperience"
            required
            :rules="consultingExperienceRules"
            dense
            type="number"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.psychologistReg.stepThree.workExperience`
              )
            "
          ></v-text-field>
        </v-col>
        <p class="pt-6">{{ consultingExperienceYearsText }}</p>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-3">
        <v-col cols="12" md="6">
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepThree.additionalProgram"
              )
            }}
          </p>
        </v-col>
        <v-col md="4" offset-md="2">
          <v-text-field
            v-model="courses"
            required
            :rules="coursesRules"
            :counter="10"
            dense
            :label="
              $vuetify.lang.t(
                `$vuetify.main.psychologistReg.stepThree.additionalProgramLabel`
              )
            "
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-3">
        <v-col cols="12" md="6">
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepThree.selfTherapy"
              )
            }}
          </p>
        </v-col>
        <v-col md="4" offset-md="2">
          <v-row class="pt-4 pl-3">
            <span class="justify-center">
              {{
                $vuetify.lang.t("$vuetify.main.psychologistReg.stepThree.no")
              }}</span
            >
            <v-switch
              v-model="personalPsychotherapy"
              class="mt-0 pt-0 ml-2"
            ></v-switch>
            <span>
              {{
                $vuetify.lang.t("$vuetify.main.psychologistReg.stepThree.yes")
              }}</span
            >
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-3">
        <v-col cols="12" md="6">
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepThree.regularSupervision"
              )
            }}
          </p>
        </v-col>
        <v-col md="4" offset-md="2">
          <v-row class="pt-4 pl-3">
            <span class="justify-center">
              {{
                $vuetify.lang.t("$vuetify.main.psychologistReg.stepThree.no")
              }}</span
            >
            <v-switch
              v-model="regularSupervision"
              class="mt-0 pt-0 ml-2"
            ></v-switch>
            <span>
              {{
                $vuetify.lang.t("$vuetify.main.psychologistReg.stepThree.yes")
              }}</span
            >
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: "stepThree",
  data() {
    return {
      valid: false,
      consultingExperience: "",
      consultingExperienceYearsText: "",
      courses: "",
      personalPsychotherapy: false,
      regularSupervision: false,
      consultingExperienceRules: [
        (v) =>
          !!+v ||
          this.$vuetify.lang.t("$vuetify.main.psychologistReg.stepThree.rules"),
        (v) =>
          (v && v >= 0) ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepThree.consultingExperienceRules1"
          ),
        (v) =>
          (v && v <= 100) ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepThree.consultingExperienceRules2"
          ),
      ],
      coursesRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t("$vuetify.main.psychologistReg.stepThree.rules"),
        (v) =>
          v.length >= 10 ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepThree.coursesRules"
          ),
      ],
    };
  },
  created() {
    this.$eventHub.$on("psych-step-three-form_validate-form", this.validate);
  },
  beforeDestroy() {
    this.$eventHub.$off("psych-step-three-form_validate-form");
  },
  watch: {
    consultingExperience: function () {
      if (
        +this.consultingExperience === 11 ||
        +this.consultingExperience === 12 ||
        +this.consultingExperience === 13 ||
        +this.consultingExperience === 14
      ) {
        this.consultingExperienceYearsText = this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.years"
        );
      } else if (this.consultingExperience % 10 === 1) {
        this.consultingExperienceYearsText = this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.year"
        );
      } else if (
        this.consultingExperience % 10 === 2 ||
        this.consultingExperience % 10 === 3 ||
        this.consultingExperience % 10 === 4
      ) {
        this.consultingExperienceYearsText = this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.years3"
        );
      } else {
        this.consultingExperienceYearsText = this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepThree.years"
        );
      }
    },
  },
  methods: {
    validate() {
      this.$refs.psychStepThreeForm.validate();
      if (this.valid) {
        this.$store.dispatch("user/setQuestionnaireProfile", {
          experience_ages: this.consultingExperience,
          courses: this.courses,
          self_psychotherapy: this.personalPsychotherapy,
          regular_supervision: this.regularSupervision,
        });
        this.$eventHub.$emit("switch-psych-registration-tab", 4);
      }
    },
  },
};
</script>

<style></style>
