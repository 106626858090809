import ApiClient from "@/libs/http-client";

const RedactorServices = {
  endpoints(route, params) {
    const url = {
      searchBlogByTitle: `api/blog/posts?search=${params.title}&${params.categories}&page=${params.page}`,
      getCategoriesForUsers: "api/blog/categories",

      getRedactorsList: "api/admin/redactors",
      deleteRedactor: `api/admin/redactors/${params}`,
      createRedactor: `api/admin/redactors`,

      getAllBlogsList: `/api/admin/blog/posts?page=${params.page}`,
      getPublishedBlogsList: `api/admin/blog/posts/published?page=${params.page}`,
      getDraftBlogsList: "api/admin/blog/posts/draft",
      getWaitingBlogsList: "api/admin/blog/posts/waiting",
      getPost: `api/blog/posts/${params.slug}`,

      getAllBlogsForClient: `api/blog/posts?page=${params.page}&`,

      editBlog: `api/admin/blog/posts/${params}`,
      createBlog: "api/admin/blog/posts",

      getCategories: "api/admin/blog/categories",
      createCategory: "api/admin/blog/categories",
      deleteCategory: `/api/admin/blog/categories/${params}`,

      postBlogImg: `api/admin/blog/posts/images`,
    };
    return url[route];
  },

  get(url, param) {
    return ApiClient.get(this.endpoints(url, param));
  },
  post(url, params = {}, data, config = {}) {
    return ApiClient.post(this.endpoints(url, params), data, config);
  },
  delete(url, params = {}, data, config = {}) {
    return ApiClient.delete(this.endpoints(url, params), data, config);
  },
};

export default RedactorServices;
