<template>
  <v-container class="ml-0 mr-0 w-100 custom__container">
    <v-dialog
      v-model="modal"
      fullscreen
      transition="dialog-bottom-transition"
      :scrollable="true"
    >
      <v-card>
        <div v-if="modal">
          <v-card-title class="card-title-container">
            <v-btn icon dark @click="closeQuestionnaire">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <span class="card-title">
              {{
                $vuetify.lang.t(
                  "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.title"
                )
              }}
              {{ currentPsychologist.name }}
              {{ currentPsychologist.surname }}</span
            >
            <v-btn
              v-show="instantValue"
              @click="redact"
              class="ml-auto"
              text
              color="white"
              >{{
                $vuetify.lang.t(
                  "$vuetify.main.adminDashboard.psychologists.buttons.btnEdit"
                )
              }}</v-btn
            >
            <div v-show="!instantValue" class="ml-auto">
              <v-btn @click="cancel" text color="white">{{
                $vuetify.lang.t(
                  "$vuetify.main.adminDashboard.psychologists.buttons.btnCancel"
                )
              }}</v-btn>
              <v-btn @click="apply" text color="white">{{
                $vuetify.lang.t(
                  "$vuetify.main.adminDashboard.psychologists.buttons.btnSave"
                )
              }}</v-btn>
            </div>
          </v-card-title>
          <v-card-text style="height: 500px">
            <v-stepper v-model="el">
              <v-stepper-header>
                <v-stepper-step :complete="el > 1" step="1">
                  {{
                    $vuetify.lang.t(
                      "$vuetify.main.adminDashboard.psychologists.stepsTitle.generalInformation"
                    )
                  }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="el > 2" step="2">
                  {{
                    $vuetify.lang.t(
                      "$vuetify.main.adminDashboard.psychologists.stepsTitle.mainTreatmentMethod"
                    )
                  }}
                </v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step :complete="el > 3" step="3">{{
                  $vuetify.lang.t(
                    "$vuetify.main.adminDashboard.psychologists.stepsTitle.workExperience"
                  )
                }}</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="4">
                  {{
                    $vuetify.lang.t(
                      "$vuetify.main.adminDashboard.psychologists.stepsTitle.aboutInfo"
                    )
                  }}
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <stepOne
                    :psychologistsList="currentPsychologist"
                    :instantValue="instantValue"
                    :saveIsNeed="saveIsNeed.firstStep"
                    @successfullySaved="successfullySaved"
                  ></stepOne>
                  <div class="d-flex justify-end">
                    <v-btn
                      :disabled="!instantValue"
                      color="primary"
                      @click="
                        el = 2;
                        saveIsNeed.firstStep = false;
                        instantValue = true;
                      "
                    >
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.adminDashboard.psychologists.buttons.forward"
                        )
                      }}
                    </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card class="mb-12 pb-3" min-height="200px">
                    <step-two
                      :psychologistsList="currentPsychologist"
                      :instantValue="instantValue"
                      :saveIsNeed="saveIsNeed.secondStep"
                      @successfullySaved="successfullySaved"
                    ></step-two>
                  </v-card>
                  <div class="d-flex justify-space-between">
                    <v-btn
                      :disabled="!instantValue"
                      @click="
                        el = 1;
                        saveIsNeed.secondStep = false;
                        instantValue = true;
                      "
                    >
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.adminDashboard.psychologists.buttons.back"
                        )
                      }}
                    </v-btn>
                    <v-btn
                      :disabled="!instantValue"
                      color="primary"
                      @click="
                        el = 3;
                        instantValue = true;
                        saveIsNeed.secondStep = false;
                      "
                    >
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.adminDashboard.psychologists.buttons.forward"
                        )
                      }}
                    </v-btn>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-card class="mb-12" min-height="200px">
                    <step-three
                      :psychologistsList="currentPsychologist"
                      :instantValue="instantValue"
                      :saveIsNeed="saveIsNeed.thirdStep"
                      @successfullySaved="successfullySaved"
                    ></step-three>
                  </v-card>
                  <div class="d-flex justify-space-between">
                    <v-btn
                      :disabled="!instantValue"
                      @click="
                        el = 2;
                        instantValue = true;
                        saveIsNeed.thirdStep = false;
                      "
                    >
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.adminDashboard.psychologists.buttons.back"
                        )
                      }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="!instantValue"
                      @click="
                        el = 4;
                        instantValue = true;
                        saveIsNeed.thirdStep = false;
                      "
                    >
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.adminDashboard.psychologists.buttons.forward"
                        )
                      }}
                    </v-btn>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-card class="mb-12" min-height="200px">
                    <step-four
                      :psychologistsList="currentPsychologist"
                      :instantValue="instantValue"
                      :saveIsNeed="saveIsNeed.fourthStep"
                      @successfullySaved="successfullySaved"
                    ></step-four>
                  </v-card>
                  <div class="d-flex justify-space-between">
                    <v-btn
                      :disabled="!instantValue"
                      @click="
                        el = 3;
                        instantValue = true;
                        saveIsNeed.fourthStep = false;
                      "
                    >
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.adminDashboard.psychologists.buttons.back"
                        )
                      }}
                    </v-btn>
                    <v-btn color="primary" @click="closeQuestionnaire">
                      {{
                        $vuetify.lang.t(
                          "$vuetify.main.adminDashboard.psychologists.buttons.closeForm"
                        )
                      }}
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
    <confirm-modal
      :id="confirm.id"
      :action="currentAction"
      @closeDialog="closeDialog"
      :dialog="confirm.dialog"
      >{{ confirm.slot }}</confirm-modal
    >
    <v-row
      v-for="(psychologist, i) in psychologists"
      :key="psychologist.id"
      style="border-bottom: lightgray 1px solid"
      :class="{ photo_wrapper: psychologist.buffer_photo }"
      class="mt-5 pl-3 pr-3 row-wrapper"
    >
      <v-icon
        @click="
          action(
            'deletePsychologist',
            psychologist.id,
            psychologist.name,
            psychologist.surname
          )
        "
        class="delete-button"
        >mdi-delete</v-icon
      >
      <v-col class="text-center" cols="12" md="2">
        <span class="grey--text text--lighten-2 text-caption mr-2">
          {{
            $vuetify.lang.t("$vuetify.main.adminDashboard.psychologists.rating")
          }}
          ({{ psychologist.rating }})
        </span>
        <v-rating
          v-model="psychologist.rating"
          color="yellow accent-4"
          half-increments
          hover
          dense
          readonly
          size="12"
        >
        </v-rating>
        <span
          v-if="psychologist.buffer_photo"
          :id="psychologist.id"
          class="red--text d-block mt-2 text--accent-2 text-caption mr-2"
        >
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.changePhotoRequire"
            )
          }}
        </span>
      </v-col>
      <v-col cols="12" md="2" class="text-center">
        <p class="mb-0">
          <v-avatar
            :id="'buffer' + psychologist.id"
            class="new-photo"
            v-if="psychologist.buffer_photo"
          >
            <img :src="psychologist.buffer_photo" :alt="psychologist.name" />
          </v-avatar>
          <v-avatar :id="'photo' + psychologist.id" class="current-photo mb-1">
            <img :src="psychologist.photo" :alt="psychologist.name" />
          </v-avatar>
        </p>
        <v-btn
          small
          :href="
            psychologist.buffer_photo
              ? psychologist.buffer_photo
              : psychologist.photo
          "
          download
          target="_blank"
          >{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.buttons.downloadPhoto"
            )
          }}</v-btn
        >
      </v-col>
      <v-col cols="12" md="3" class="text-center">
        <p class="mb-0 mt-4">
          {{ psychologist.name }} {{ psychologist.surname }}
        </p>
        <v-btn class="mt-3" @click="openQuestionnaire(i)" small>{{
          $vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.buttons.viewProfile"
          )
        }}</v-btn>
      </v-col>
      <v-col cols="12" md="2" class="text-center pt-0">
        <v-row class="photoWrapper">
          <v-file-input
            v-model="psychologist.newPhoto"
            accept="image/png, image/jpeg, image/bmp, image/webp, image/gif, image/jpg"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.adminDashboard.psychologists.buttons.uploadPhoto`
              )
            "
            class="pt-9"
          ></v-file-input>
          <v-btn
            class="mt-10 ml-2"
            small
            fab
            color="primary"
            @click="
              updatePhoto(
                psychologist.id,
                psychologist.newPhoto,
                psychologist.buffer_photo
              )
            "
          >
            <v-icon dark> mdi-cloud-upload </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" md="2" offset-md="1" class="text-center pt-0">
        <div class="mb-0 custom__chips">
          <p class="mb-0">
            {{
              $vuetify.lang.t(
                "$vuetify.main.adminDashboard.psychologists.month"
              )
            }}
          </p>
          <div>
            <span>
              {{
                $vuetify.lang.t(
                  "$vuetify.main.adminDashboard.psychologists.current"
                )
              }}
            </span>
            <span>
              {{
                $vuetify.lang.t(
                  "$vuetify.main.adminDashboard.psychologists.last"
                )
              }}
            </span>
          </div>
        </div>
        <p>
          <v-chip label>
            {{ psychologist.successful_sessions_this_month }}
          </v-chip>
          <v-chip label class="ml-5">
            {{ psychologist.successful_sessions_previous_month }}
          </v-chip>
        </p>
        <v-btn
          v-if="!inactive"
          @click="
            action(
              'deactivate',
              psychologist.id,
              psychologist.name,
              psychologist.surname
            )
          "
          color="primary"
          small
          >{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.buttons.deactivate"
            )
          }}</v-btn
        >
        <v-btn
          v-else
          @click="
            action(
              'activate',
              psychologist.id,
              psychologist.name,
              psychologist.surname
            )
          "
          color="primary"
          small
          >{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.buttons.activate"
            )
          }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ConfirmModal from "@/components/dashboard/adminDashboard/ConfirmModal";
import stepOne from "./QuestionaryFormSteps/stepOne";
import stepTwo from "./QuestionaryFormSteps/stepTwo";
import stepThree from "./QuestionaryFormSteps/stepThree";
import stepFour from "./QuestionaryFormSteps/stepFour";
import Vue from "vue";
export default {
  name: "psychologistsComponent",
  components: { stepOne, stepTwo, stepThree, stepFour, ConfirmModal },
  props: { psychologists: Array, inactive: Boolean },
  metaInfo() {
    return {
      title:
        "Платформа / " +
        this.$vuetify.lang.t("$vuetify.main.header.menu.psychologists"),
    };
  },
  data() {
    return {
      modal: false,
      current: null,
      newPhoto: "",
      el: 1,
      confirm: {
        dialog: false,
        slot: "",
        id: "",
        type: null,
      },
      isNeedToReload: false,
      saveIsNeed: {
        firstStep: false,
        secondStep: false,
        thirdStep: false,
        fourthStep: false,
      },
      instantValue: true,
    };
  },
  computed: {
    currentPsychologist() {
      return this.psychologists[this.current];
    },
    currentAction() {
      if (this.confirm.type) {
        return this[this.confirm.type];
      } else return () => {};
    },
  },
  methods: {
    closeDialog() {
      this.confirm = {
        dialog: false,
        slot: "",
        id: "",
        type: null,
      };
    },
    action(type, id, name, surname) {
      if (type === "deletePsychologist") {
        this.confirm.slot =
          this.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.buttons.confirmDelete"
          ) +
          " " +
          name +
          " " +
          surname +
          "?";
      } else if (type === "activate") {
        this.confirm.slot =
          this.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.buttons.confirmActivate"
          ) +
          " " +
          name +
          " " +
          surname +
          "?";
      } else if (type === "deactivate") {
        this.confirm.slot =
          this.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.buttons.confirmDeactivate"
          ) +
          " " +
          name +
          " " +
          surname +
          "?";
      }
      this.confirm.id = id;
      this.confirm.dialog = true;
      this.confirm.type = type;
    },
    redact() {
      this.instantValue = false;
    },
    cancel() {
      this.instantValue = true;
    },
    apply() {
      if (this.el === 1) this.saveIsNeed.firstStep = !this.saveIsNeed.firstStep;
      else if (this.el === 2) this.saveIsNeed.secondStep = true;
      else if (this.el === 3) this.saveIsNeed.thirdStep = true;
      else if (this.el === 4) this.saveIsNeed.fourthStep = true;
    },
    successfullySaved(arg) {
      if (arg) {
        this.instantValue = true;
        this.isNeedToReload = true;
      }
      for (const i in this.saveIsNeed) {
        this.saveIsNeed[i] = false;
      }
    },
    openQuestionnaire(i) {
      this.current = i;
      this.modal = true;
    },
    closeQuestionnaire() {
      this.modal = false;
      this.current = null;
      this.instantValue = true;
      this.el = 1;
      if (this.isNeedToReload) {
        this.$emit("reload");
        this.$store.dispatch("user/getInactivePsychologists").then(() => {
          this.isNeedToReload = false;
        });
      }
    },
    updatePhoto(id, photo, bufferPhoto) {
      const payload = new FormData();
      payload.append("photo", photo);
      payload.append("userId", id);
      const requireText = document.getElementById(id);
      this.$store
        .dispatch("user/updatePsychologistPhoto", payload)
        .then(() => {
          const reader = new FileReader();
          reader.onloadend = function () {
            const requirePhoto = document.getElementById("photo" + id);
            requirePhoto.firstChild.src = reader.result;
          };
          reader.readAsDataURL(photo);
          if (bufferPhoto) {
            const requireBuffer = document.getElementById("buffer" + id);
            requireBuffer.style.opacity = 0;
            requireText.innerText = this.$vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.changePhotoDone"
            );
            requireText.classList.remove("red--text");
            requireText.classList.remove("deep-orange--text");
            requireText.classList.add("indigo--text");
            let row = requireText.closest(".photo_wrapper");
            row.classList.remove("photo_wrapper");
          }
        })
        .catch(() => {
          if (!photo) {
            requireText.innerText = this.$vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.changePhotoWarning"
            );
            requireText.classList.remove("red--text");
            requireText.classList.add("deep-orange--text");
          }
        });
    },
    deletePsychologist(id) {
      this.$emit("reload");
      this.$store.dispatch("user/deletePsychologist", id).then((response) => {
        if (response.status === 200) {
          Vue.swal({
            target: document.getElementById("main"),
            text: this.$vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.swalText.deleted"
            ),
            icon: "success",
            timer: 4000,
            toast: true,
            position: "top-right",
            showConfirmButton: false,
          });
        }
      });
    },
    activate(id) {
      this.$emit("reload");
      this.$store.dispatch("user/activatePsychologist", id).then((response) => {
        if (response.status === 200) {
          Vue.swal({
            target: document.getElementById("main"),
            text: this.$vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.swalText.activated"
            ),
            icon: "success",
            timer: 4000,
            toast: true,
            position: "top-right",
            showConfirmButton: false,
          });
        }
      });
    },
    deactivate(id) {
      this.$emit("reload");
      this.$store
        .dispatch("user/deactivatePsychologist", id)
        .then((response) => {
          if (response.status === 200) {
            Vue.swal({
              target: document.getElementById("main"),
              text: this.$vuetify.lang.t(
                "$vuetify.main.adminDashboard.psychologists.swalText.deactivated"
              ),
              icon: "success",
              timer: 4000,
              toast: true,
              position: "top-right",
              showConfirmButton: false,
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.photoWrapper {
  flex-wrap: nowrap !important;
}

//@media screen and (max-width: 1165px) {
//  .photoWrapper {
//    min-width: 200px;
//    justify-content: center;
//    align-items: center;
//  }
//}
.row-wrapper {
  position: relative;
}
.delete-button {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -9px;
  right: 0;
  opacity: 0.1;
  &:hover {
    color: red;
    opacity: 1;
  }
}
.custom__container {
  max-width: 100% !important;
}
.photo_wrapper {
  &:hover .new-photo {
    z-index: 1;
    animation-duration: 0.6s;
    animation-name: slidein;
    animation-iteration-count: 1;
  }
  &:hover .current-photo {
    animation-duration: 0.6s;
    animation-name: slideout;
    transform: translateX(-50%);
  }
}
@keyframes slideout {
  from {
    z-index: 2;
  }
  to {
    z-index: 1;
  }
}
@keyframes slidein {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(50%);
    z-index: 2;
  }
  100% {
    transform: translateX(0%);
    z-index: 2;
  }
}
.current-photo {
  transition: 0.3s;
}
.new-photo {
  position: absolute;
  transition: 0.3s;
}
.card-title-container {
  background-color: #1976d2 !important;
}
.card-title {
  color: white;
}
.custom__chips {
  font-size: 0.7rem;
}
</style>
