<template>
  <v-alert v-if="handlePaymentAlert" prominent dismissible type="warning">
    <v-row align="center">
      <v-col class="grow">
        {{
          this.$vuetify.lang.t(
            "$vuetify.main.paymentAlert",
            this.$store.getters["user/getUser"].time_slots[0].date.translated
          )
        }}
      </v-col>
      <v-col class="shrink">
        <v-btn to="/dashboard/payment">{{
          this.$vuetify.lang.t("$vuetify.main.patientDashboard.main.paymentBtn")
        }}</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "paymentAlert",
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    handlePaymentAlert() {
      if (this.$store.getters["user/getUser"].payment_method) {
        return !!(
          !this.$store.getters["user/getUser"].payment_method.id &&
          this.$store.getters["user/getUser"].time_slots[0]
        );
      }
    },
  },
};
</script>

<style scoped></style>
