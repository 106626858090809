<template>
  <v-form ref="psychStepOneForm" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" class="mb-0 pb-0">
          <p class="mb-0">
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.generalInformation"
              )
            }}
          </p>
          <p class="mb-0 caption">
            {{ $vuetify.lang.t("$vuetify.main.psychologistReg.stepOne.desk") }}
          </p>
        </v-col>
      </v-row>
      <v-row class="pt-0 mt-0">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="lastname.uk"
            :rules="lastnameRulesUA"
            label="Прізвище"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="firstname.uk"
            :rules="nameRulesUA"
            label="Ім'я"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="patronymic.uk"
            label="По батькові"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pt-0 mt-0">
        <v-col cols="12" md="4" class="pt-0 mt-0">
          <v-text-field
            v-model="lastname.ru"
            :rules="lastnameRules"
            label="Фамилия"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4" class="pt-0 mt-0">
          <v-text-field
            v-model="firstname.ru"
            :rules="nameRules"
            label="Имя"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4" class="pt-0 mt-0">
          <v-text-field
            v-model="patronymic.ru"
            label="Отчество"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="
                  $vuetify.lang.t(
                    `$vuetify.main.psychologistReg.stepOne.dateOfBirth`
                  )
                "
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4" class="pt-2">
          <v-text-field
            v-model="citizenship"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.psychologistReg.stepOne.citizenship`
              )
            "
            required
            :rules="citizenshipRules"
            class="pt-5 mt-0"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <p class="mb-0">
            {{
              $vuetify.lang.t("$vuetify.main.psychologistReg.stepOne.education")
            }}
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="university"
            :rules="universityRules"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.psychologistReg.stepOne.university`
              )
            "
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="faculty"
            :rules="facultyRules"
            :label="
              $vuetify.lang.t(`$vuetify.main.psychologistReg.stepOne.faculty`)
            "
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="speciality"
            :rules="specialityRules"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.psychologistReg.stepOne.speciality`
              )
            "
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            v-model="scienceDegree"
            :items="scienceDegreeList"
            item-value="id"
            item-text="title"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.psychologistReg.stepOne.scienseDegree`
              )
            "
            dense
            required
            :rules="specialityRules"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3" offset-md="2" class="mt-0 pt-0">
          <v-text-field
            v-model="yearOfEnding"
            :rules="yearOfEndingRules"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.psychologistReg.stepOne.yearOfEnding`
              )
            "
            :counter="4"
            required
            class="mt-0 pt-1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="mb-0 pb-2">
          <p class="mb-0 pb-0">
            {{
              $vuetify.lang.t("$vuetify.main.psychologistReg.stepOne.photoDesc")
            }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="pt-0">
          <v-row>
            <v-file-input
              class="diploma-and-certificates"
              v-model="photo"
              accept="image/png, image/jpeg, image/bmp, image/webp, image/gif, image/jpg, application/vnd.oasis.opendocument.text ,application/rtf, text/rtf, application/msword, application/pdf"
              @click:clear="clearFiles"
              @change="addFiles"
              show-size
              counter
              append
              multiple
              :label="
                $vuetify.lang.t(
                  `$vuetify.main.psychologistReg.stepOne.diploma_and_certificates`
                )
              "
            ></v-file-input>
          </v-row>
          <v-row>
            <p class="pl-8 pt-0 mt-0 font-weight-thin">
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistReg.stepOne.diploma_and_certificates_desk"
                )
              }}
            </p>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
export default {
  name: "stepOne",
  data() {
    return {
      photo: [],
      photos: [],
      currFiles: [],
      valid: false,
      firstname: { ru: "", uk: "" },
      lastname: { ru: "", uk: "" },
      patronymic: { ru: "", uk: "" },
      university: "",
      faculty: "",
      speciality: "",
      scienceDegree: [],
      yearOfEnding: "",
      citizenship: "",
      email: "",
      scienceDegreeList: [
        {
          id: "bachelors",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.bachelors"
          ),
        },
        {
          id: "master",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.master"
          ),
        },
        {
          id: "doctoral",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.doctoral"
          ),
        },
        {
          id: "candidate_of_sciences",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.candidate_of_sciences"
          ),
        },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
    };
  },
  created() {
    this.$eventHub.$on("psych-step-one-form_validate-form", this.validate);
  },
  beforeDestroy() {
    this.$eventHub.$off("psych-step-one-form_validate-form");
  },
  computed: {
    nameRules() {
      return [(v) => !!v || `Поле "Имя" обязательно для заполнения`];
    },
    lastnameRules() {
      return [(v) => !!v || `Поле "Фамилия" обязательно для заполнения`];
    },
    nameRulesUA() {
      return [(v) => !!v || `Поле "Ім'я" обов'язково під час заповнення`];
    },
    lastnameRulesUA() {
      return [(v) => !!v || `Поле "Прізвище" обов'язково під час заповнення`];
    },
    emailRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.emailRules1"
          ),
        (v) =>
          /.+@.+/.test(v) ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.emailRules2"
          ),
      ];
    },
    universityRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.universityRules"
          ),
      ];
    },
    facultyRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.facultyRules"
          ),
      ];
    },
    specialityRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.specialityRules"
          ),
      ];
    },
    yearOfEndingRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.yearOfEndingRules1"
          ),
        (v) =>
          v.length === 4 ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.yearOfEndingRules2"
          ),
      ];
    },
    citizenshipRules() {
      return [
        (v) =>
          !!v ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.citizenshipRules"
          ),
      ];
    },
  },
  methods: {
    validate() {
      this.$refs.psychStepOneForm.validate();

      if (this.valid) {
        this.$store.dispatch("user/setQuestionnaireProfile", {
          name: { ru: this.firstname.ru, uk: this.firstname.uk },
          surname: { ru: this.lastname.ru, uk: this.lastname.uk },
          middle_name: { ru: this.patronymic.ru, uk: this.patronymic.uk },
          birthday: this.date,
          citizenship: this.citizenship,
          education_university: this.university,
          education_faculty: this.faculty,
          education_speciality: this.speciality,
          education_degree: this.scienceDegree,
          education_year_of_ending: this.yearOfEnding,
          localVal: this.localVal,
        });
        this.$store.dispatch("user/setUserCertificates", this.photo);
        this.$eventHub.$emit("switch-psych-registration-tab", 2);
      }
    },
    addFiles() {
      if (event.type === "blur" || event.type === "click") return;
      this.photos.push(...this.photo);
      this.photo = this.photos;
    },
    clearFiles() {
      this.photos = [];
      this.photo = [];
    },
  },
};
</script>

<style lang="scss"></style>
