export default {
  methods: {
    prepareData: function (data) {
      if (!data.id) return;
      if (data.id === "email") {
        this.payload[data.id] = data.value;
      } else {
        if (!this.payload.profile) this.payload.profile = {};
        // get data from first step
        if (this.scienceValue || this.userData) {
          this.payload.profile.education_degree = this.scienceValue;
          this.payload.phone = this.userData.formattedNumber;
          this.payload.phone_country_code = this.userData.countryCode;
        }
        // get data from fourth step
        if (this.editorToolbar) {
          this.payload.profile.about_info = {
            uk: this.psychologistsList.translated.about_info.uk,
            ru: this.psychologistsList.translated.about_info.ru,
          };
        }
        //
        if (data.id.includes(".")) {
          let parent = data.id.split(".")[0];
          let child = data.id.split(".")[1];
          if (this.payload.profile[parent]) {
            this.payload.profile[parent][child] = data.value;
          } else {
            this.payload.profile[parent] = {
              [child]: data.value,
            };
          }
        } else {
          this.payload.profile[data.id] = data.value;
        }
      }
    },
  },
};
