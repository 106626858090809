<template>
  <v-container class="w-100" fluid>
    <p class="text-center title mt-6 mb-0">
      {{
        $vuetify.lang.t("$vuetify.main.adminDashboard.psychologists.mainTitle")
      }}
    </p>
    <v-card class="mt-3">
      <v-tabs v-model="tab" background-color="primary" dark>
        <v-tab>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.tabs.inactivePsychologists"
            )
          }}</v-tab
        >
        <v-tab>
          {{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.tabs.activePsychologists"
            )
          }}</v-tab
        >
        <div class="align-self-center ml-auto pr-4">
          <a @click="downloadDocument" class="white--text">{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.buttons.downloadAll"
            )
          }}</a>
        </div>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-if="!inactiveLoadingDone">
          <v-row class="pt-16 pb-16">
            <v-col cols="12" md="12 text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item v-else>
          <psychologistCards
            @reload="reload"
            :psychologists="inactivePsychologists"
            inactive
          />
        </v-tab-item>
        <v-tab-item v-if="!activeLoadingDone">
          <v-row class="pt-16 pb-16">
            <v-col cols="12" md="12 text-center">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item v-else>
          <psychologistCards
            @reload="reload"
            :psychologists="activePsychologists"
          />
        </v-tab-item>
      </v-tabs-items>
      <pagination
        :tab="tab"
        @reload="reload"
        :activeLastPage="activeLastPage"
        :inactiveLastPage="inactiveLastPage">
      </pagination>
    </v-card>
  </v-container>
</template>

<script>
import psychologistCards from "@/components/dashboard/adminDashboard/psychologistCards";
import Pagination from '@/components/dashboard/adminDashboard/Pagination'

export default {
  name: "psychologistsComponent",
  components: {
    psychologistCards,
    Pagination,
  },
  metaInfo() {
    return {
      title:
        "Платформа / " +
        this.$vuetify.lang.t("$vuetify.main.header.menu.psychologists"),
    };
  },
  data() {
    return {
      tab: null,
      activeLastPage: null, 
      inactiveLastPage: null,
      activeLoadingDone: false,
      inactiveLoadingDone: false,
    };
  },
  beforeMount () {
    this.$store.dispatch("user/requestPsychologistCards");
    this.$store.dispatch('user/getActivePsychologists', { page: 1 })
      .then((response) => {
        this.activeLastPage = response.data.meta.last_page;
      });
    this.$store.dispatch('user/getInactivePsychologists', { page: 1 })
      .then((response) => {
        this.inactiveLastPage = response.data.meta.last_page;
      });
  },
  watch: {
    activePsychologists() {
      this.activeLoadingDone = true;
    },
    inactivePsychologists() {
      this.inactiveLoadingDone = true;
    },
  },
  computed: {
    activePsychologists() { 
      return this.$store.getters["user/getActivePsychologists"];
    },
    inactivePsychologists() {
      return this.$store.getters["user/getInactivePsychologists"];
    },
  },
  methods: {
    reload(paginationType) {
      if (paginationType === 'active') {
        this.activeLoadingDone = false;
      } else if (paginationType === 'inactive') {
        this.inactiveLoadingDone = false;
      }
    },
    downloadDocument() {
      this.$store.dispatch("user/downloadPsychologistInfo");
    },
  },
};
</script>
<style lang="scss" scoped></style>