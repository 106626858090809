import ApiClient from "@/libs/http-client";

const PsychologistServices = {
  // eslint-disable-next-line no-unused-vars
  endpoints(route, params) {
    const url = {
      allowTimeSlot: "api/psychologists/calendars/sync",
      cancelTimeSlot: `api/psychologists/calendars/${params.id}/cancel`,
    };

    return url[route];
  },

  get(url, data) {
    return ApiClient.get(this.endpoints(url, data));
  },
  post(url, params = {}, data, config = {}) {
    return ApiClient.post(this.endpoints(url, params), data, config);
  },
  patch(url, params = {}, data, config = {}) {
    return ApiClient.patch(this.endpoints(url, params), data, config);
  },
  delete(url, params = {}, data, config = {}) {
    return ApiClient.delete(this.endpoints(url, params), data, config);
  },
};

export default PsychologistServices;
