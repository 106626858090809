<template>
  <v-container fluid>
    <p class="text-center title mt-6">
      {{ this.$vuetify.lang.t("$vuetify.main.redactorDashboard.blog.title") }}
    </p>

    <v-row class="mt-5">
      <v-col cols="12" md="4" class="pt-0">
        <div class="d-flex justify-space-between">
          <p class="text-sm-body-1 font-weight-bold mb-0 pb-0">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.redactorDashboard.blog.allBlogs"
              )
            }}
          </p>
          <v-btn small class="mb-2" @click="createNewBlog">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.redactorDashboard.blog.create"
              )
            }}
            <v-icon color="green"> mdi-plus </v-icon>
          </v-btn>
        </div>
        <v-tabs
          v-model="statusTabs"
          centered
          slider-color="white"
          background-color="primary"
        >
          <v-tab class="font-weight-bold font-gotham">{{
            this.$vuetify.lang.t("$vuetify.main.redactorDashboard.blog.all")
          }}</v-tab>
          <v-tab class="font-weight-bold font-gotham">{{
            this.$vuetify.lang.t(
              "$vuetify.main.redactorDashboard.blog.published"
            )
          }}</v-tab>
          <v-tab class="font-weight-bold font-gotham">{{
            this.$vuetify.lang.t("$vuetify.main.redactorDashboard.blog.waiting")
          }}</v-tab>
          <v-tab class="font-weight-bold font-gotham">{{
            this.$vuetify.lang.t("$vuetify.main.redactorDashboard.blog.draft")
          }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="statusTabs">
          <v-tab-item>
            <v-pagination
              v-model="allBlogsPage"
              :length="paginationLengthAllBlogs"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              :total-visible="6"
              class="mt-3"
            >
            </v-pagination>
            <v-card
              v-for="(blog, i) in allBlogsList"
              :key="i"
              @click="selectBlog(blog)"
              class="mt-2"
            >
              <v-card-title class="text-sm-body-1 font-weight-bold">
                №{{ blog.number + " " }}{{ blog.card_title }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="1">
                    <v-icon>{{ setIcon(blog.status) }}</v-icon>
                  </v-col>
                  <v-col cols="12" md="4" v-if="blog.published_at">
                    {{ blog.published_at }}
                  </v-col>
                  <v-col cols="12" md="4" v-if="blog.publish_at">
                    {{ blog.publish_at }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card></v-tab-item
          >
          <v-tab-item>
            <v-pagination
              v-model="publishedBlogsPage"
              :length="paginationLengthPublishedBlogs"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              :total-visible="6"
              class="mt-3"
            >
            </v-pagination>
            <v-card
              v-for="(blog, i) in publishedBlogsList"
              :key="i"
              @click="selectBlog(blog)"
              class="mt-2"
            >
              <v-card-title class="text-sm-body-1 font-weight-bold">
                {{ blog.card_title }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6"> {{ blog.published_at }}</v-col>
                  <v-col cols="12" md="4"> </v-col>
                </v-row>
              </v-card-text> </v-card
          ></v-tab-item>
          <v-tab-item>
            <v-card
              v-for="(blog, i) in waitingBlogsList"
              :key="i"
              @click="selectBlog(blog)"
              class="mt-2"
            >
              <v-card-title class="text-sm-body-1 font-weight-bold">
                {{ blog.card_title }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6"> {{ blog.publish_at }}</v-col>
                  <v-col cols="12" md="4"> </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              v-for="(blog, i) in draftBlogsList"
              :key="i"
              @click="selectBlog(blog)"
              class="mt-2"
            >
              <v-card-title class="text-sm-body-1 font-weight-bold">
                {{ blog.card_title }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4"> {{ blog.published_at }}</v-col>
                  <v-col cols="12" md="4"> </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12" md="8" class="pt-0">
        <p class="text-sm-body-1 font-weight-bold pb-0 mb-3">
          {{
            this.$vuetify.lang.t(
              "$vuetify.main.redactorDashboard.blog.langDesk"
            )
          }}
        </p>
        <v-tabs
          v-model="tabs"
          centered
          slider-color="white"
          background-color="primary"
        >
          <v-tab> Український</v-tab>
          <v-tab> Русский </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <v-card>
              <v-form>
                <v-text-field
                  label="SEO заголовок"
                  v-model="SEOTitle.uk"
                ></v-text-field>
                <v-text-field
                  label="SEO опис"
                  v-model="SEODesc.uk"
                ></v-text-field>
                <v-text-field
                  label="Заголовок"
                  v-model="title.uk"
                ></v-text-field>
                <v-text-field
                  label="Короткий опис"
                  v-model="short_desk.uk"
                ></v-text-field>
                <v-select
                  v-model="categoriesValue"
                  :items="categoriesItems"
                  item-text="name.uk"
                  item-value="id"
                  chips
                  label="Категорії"
                  attach
                  multiple
                ></v-select>
              </v-form>

              <vue-editor
                id="editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="content.uk"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-form>
                <v-text-field
                  label="SEO заголовок"
                  v-model="SEOTitle.ru"
                ></v-text-field>
                <v-text-field
                  label="SEO описание"
                  v-model="SEODesc.ru"
                ></v-text-field>
                <v-text-field
                  label="Заголовок"
                  v-model="title.ru"
                ></v-text-field>
                <v-text-field
                  label="Краткое описание"
                  v-model="short_desk.ru"
                ></v-text-field>
                <v-select
                  v-model="categoriesValue"
                  :items="categoriesItems"
                  item-text="name.ru"
                  item-value="id"
                  chips
                  label="Категории"
                  attach
                  multiple
                ></v-select>
              </v-form>
              <vue-editor
                id="editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="content.ru"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <div
          v-show="!previewImg"
          class="blog"
          @click="$refs.previewImg.click()"
        >
          <div class="blog__inner">
            <span>
              {{
                this.$vuetify.lang.t(
                  "$vuetify.main.redactorDashboard.blog.previewPhoto"
                )
              }}
            </span>
            <input
              @change="updatePreviewImg"
              hidden
              ref="previewImg"
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
        </div>
        <div v-show="previewImg" class="blog__img" id="blog">
          <v-icon @click="deletePreviewImg" class="blog__img_icon"
            >mdi-close</v-icon
          >
        </div>
        <div class="d-flex justify-space-between mt-1">
          <div class="mt-5">
            <v-checkbox
              v-model="publishNow"
              color="primary"
              :label="
                $vuetify.lang.t(
                  `$vuetify.main.redactorDashboard.blog.publishNow`
                )
              "
              class="pt-0 mt-0"
            ></v-checkbox>
          </div>
          <div>
            <v-dialog
              ref="date"
              v-model="dateModal"
              :return-value.sync="publishDate"
              persistent
              width="290px"
              :disabled="publishNow"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="publishDate"
                  :label="
                    $vuetify.lang.t(
                      `$vuetify.main.redactorDashboard.blog.publishDate`
                    )
                  "
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :disabled="publishNow"
                ></v-text-field>
              </template>
              <v-date-picker v-model="publishDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateModal = false">
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.main.redactorDashboard.blog.cancel"
                    )
                  }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.date.save(publishDate)"
                >
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.main.redactorDashboard.blog.accept"
                    )
                  }}
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
          <div>
            <v-dialog
              ref="dialog"
              v-model="timeModal"
              :return-value.sync="publishTime"
              persistent
              width="290px"
              :disabled="publishNow"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="publishTime"
                  :label="
                    $vuetify.lang.t(
                      `$vuetify.main.redactorDashboard.blog.publishTime`
                    )
                  "
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :disabled="publishNow"
                ></v-text-field>
              </template>
              <v-time-picker
                color="primary"
                v-if="timeModal"
                v-model="publishTime"
                :allowed-minutes="allowedStep"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="timeModal = false">
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.main.redactorDashboard.blog.cancel"
                    )
                  }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(publishTime)"
                >
                  {{
                    this.$vuetify.lang.t(
                      "$vuetify.main.redactorDashboard.blog.accept"
                    )
                  }}
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </div>
          <v-btn small v-if="editBlogToggle" @click="editBlog()" class="mt-5">
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.redactorDashboard.blog.editBlog"
              )
            }}
          </v-btn>
          <v-btn
            small
            v-if="!editBlogToggle"
            @click="createBlog()"
            class="mt-5"
          >
            {{
              this.$vuetify.lang.t(
                "$vuetify.main.redactorDashboard.blog.createBlog"
              )
            }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

export default {
  name: "blogComponent",
  metaInfo() {
    return {
      title:
        "Платформа / " + this.$vuetify.lang.t("$vuetify.main.header.menu.blog"),
    };
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {},
        },
      },
      tabs: null,
      statusTabs: null,
      content: { uk: null, ru: null },
      allBlogsPage: 1,
      publishedBlogsPage: 1,
      SEOTitle: { uk: null, ru: null },
      SEODesc: { uk: null, ru: null },
      title: { uk: null, ru: null },
      short_desk: { uk: null, ru: null },
      categoriesValue: [],
      blogId: null,
      editBlogToggle: false,
      publishNow: true,
      publishTime: null,
      publishDate: null,
      timeModal: null,
      dateModal: null,
      previewImg: null,
    };
  },
  computed: {
    locale() {
      return this.$store.getters["app/getSelectedLocale"];
    },
    allBlogsList() {
      return this.$store.getters["blog/getAllBlogsList"].data;
    },
    publishedBlogsList() {
      return this.$store.getters["blog/getPublishedBlogsList"].data;
    },
    draftBlogsList() {
      return this.$store.getters["blog/getDraftBlogsList"].data;
    },
    waitingBlogsList() {
      return this.$store.getters["blog/getWaitingBlogsList"].data;
    },
    paginationLengthAllBlogs() {
      return this.$store.getters["blog/getAllBlogsList"].meta.last_page;
    },
    paginationLengthPublishedBlogs() {
      return this.$store.getters["blog/getPublishedBlogsList"].meta.last_page;
    },
    categoriesItems() {
      return this.$store.getters["blog/getCategoriesList"].data;
    },
  },
  beforeMount() {
    this.$store.dispatch("blog/getAllBlogsList", {
      page: this.allBlogsPage,
    });
    this.$store.dispatch("blog/getPublishedBlogsList", {
      page: this.publishedBlogsPage,
    });
    this.$store.dispatch("blog/getDraftBlogsList");
    this.$store.dispatch("blog/getWaitingBlogsList");
    this.$store.dispatch("blog/getCategoriesList");
  },
  watch: {
    previewImg() {
      this.setPreviewImg();
    },
    statusTabs: function () {
      switch (this.statusTabs) {
        case 0:
          this.$store.dispatch("blog/getAllBlogsList", {
            page: this.allBlogsPage,
          });
          break;
        case 1:
          this.$store.dispatch("blog/getPublishedBlogsList", {
            page: this.publishedBlogsPage,
          });
          break;
        case 2:
          this.$store.dispatch("blog/getWaitingBlogsList");
          break;
        case 3:
          this.$store.dispatch("blog/getDraftBlogsList");
          break;
      }
    },
    publishNow: function () {
      if (this.publishNow) {
        this.publishDate = null;
        this.publishTime = null;
      }
    },
    allBlogsPage: function () {
      this.$store.dispatch("blog/getAllBlogsList", { page: this.allBlogsPage });
    },
    publishedBlogsPage: function () {
      this.$store.dispatch("blog/getPublishedBlogsList", {
        page: this.publishedBlogsPage,
      });
    },
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let payload = new FormData();
      payload.append("image", file);
      this.$store.dispatch("blog/postBlogImg", payload).then((result) => {
        const url = result.data.data.url;
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      });
    },
    setIcon: (status) => {
      switch (status) {
        case "WAITING":
          return "mdi-calendar";
        case "PUBLISHED":
          return "mdi-check-bold";
        case "DRAFT":
          return "mdi-progress-wrench";
      }
    },
    allowedStep: (m) => m % 10 === 0,
    selectBlog(content) {
      this.content = content.content;
      this.SEOTitle = content.seo_title;
      this.SEODesc = content.seo_description;
      this.short_desk = content.short_description;
      this.title = content.title;
      this.previewImg = content.preview_image;

      this.publishNow = true;
      this.editBlogToggle = true;
      this.blogId = content.id;
      let categories = [];
      content.categories.data.forEach((category) => {
        categories.push(category.id);
      });
      this.categoriesValue = categories;
    },
    createNewBlog() {
      this.content = { uk: null, ru: null };
      this.SEOTitle = { uk: null, ru: null };
      this.title = { uk: null, ru: null };
      this.SEODesc = { uk: null, ru: null };
      this.short_desk = { uk: null, ru: null };
      this.categoriesValue = [];
      this.publishNow = true;
      this.editBlogToggle = false;
      this.previewImg = null;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }

      return formData;
    },
    createBlog() {
      let payload = new FormData();
      payload.append("preview_image", this.previewImg);

      let data = {
        content: this.content,
        title: this.title,
        seo_title: this.SEOTitle,
        seo_description: this.SEODesc,
        categories: this.categoriesValue,
        published: this.publishNow ? 1 : 0, //dich from @dymion
        short_description: this.short_desk,
        publish_at: this.publishDate + " " + this.publishTime + ":00",
      };

      if (!this.publishDate || !this.publishTime) {
        data.publish_at = null;
      }

      let newPayload = this.buildFormData(payload, data);

      this.$store.dispatch("blog/createBlog", newPayload).then((response) => {
        Vue.swal({
          target: document.getElementById("main"),
          text: response.data.message,
          icon: "success",
          timer: 4000,
          toast: true,
          position: "top-right",
          showConfirmButton: false,
        });
        this.statusTabs = 0;
        this.allBlogsPage = 1;
        this.createNewBlog();
      });
    },
    editBlog() {
      let payload = new FormData();

      if (typeof this.previewImg === "string") {
        // link to photo
      } else {
        payload.append("preview_image", this.previewImg);
      }

      let data = {
        content: this.content,
        title: this.title,
        seo_title: this.SEOTitle,
        seo_description: this.SEODesc,
        categories: this.categoriesValue,
        published: this.publishNow ? 1 : 0, //dich from @dymion
        short_description: this.short_desk,
        publish_at: this.publishDate + " " + this.publishTime + ":00",
      };

      if (!this.publishDate || !this.publishTime) {
        data.publish_at = null;
      }

      let newPayload = this.buildFormData(payload, data);

      this.$store
        .dispatch("blog/editBlog", { data: newPayload, id: this.blogId })
        .then((response) => {
          Vue.swal({
            target: document.getElementById("main"),
            text: response.data.message,
            icon: "success",
            timer: 4000,
            toast: true,
            position: "top-right",
            showConfirmButton: false,
          });
          this.statusTabs = 0;
          this.allBlogsPage = 1;
          this.createNewBlog();
        });
    },
    setPreviewImg() {
      let element = document.getElementById("blog");
      element.style.backgroundImage = `url(${this.previewImg}`;
    },
    updatePreviewImg(e) {
      let previewImg = this.previewImg;
      let element = document.getElementById("blog");
      this.previewImg = e.target.files[0];
      previewImg = URL.createObjectURL(e.target.files[0]);
      element.style.backgroundImage = `url(${previewImg}`;
    },
    deletePreviewImg(e) {
      e.preventDefault();
      let element = document.getElementById("blog");
      this.previewImg = null;
      element.style.backgroundImage = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.blog {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  height: 250px;
  width: 450px;
  opacity: 1;
  transition: background-color 0.3s ease;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
  & span {
    font-size: 22px;
  }
  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border: 3px dashed $primary;
    background-color: transparent;
  }
}

.blog__img {
  border: 1px solid $primary;
  position: relative;
  margin-top: 20px;
  height: 250px;
  width: 450px;
  opacity: 1;
  transition: background-color 0.3s ease;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  &_icon {
    color: #e0405c;
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    padding: 5px;
    &:hover {
      transform: rotate(-20deg);
      cursor: pointer;
    }
  }
}
</style>
