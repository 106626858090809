import Vue from "vue";
export default {
  methods: {
    updateData: function () {
      this.$store
        .dispatch("user/psychologistEditProfile", {
          id: this.psychologistsList.id,
          payload: this.payload,
        })
        .then((response) => {
          if (!response) this.$emit("successfullySaved", false);
          else if (response.status === 200) {
            if (this.backupList) this.backupList = response.data.data;
            Vue.swal({
              target: document.getElementById("main"),
              text: this.$vuetify.lang.t(
                "$vuetify.main.adminDashboard.psychologists.swalText.updated"
              ),
              icon: "success",
              timer: 4000,
              toast: true,
              position: "top-right",
              showConfirmButton: false,
            });
            this.$emit("successfullySaved", true);
          }
        });
    },
  },
};
