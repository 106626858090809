import ApiClient from "@/libs/http-client";

const RegistrationService = {
  endpoints(route, params) {
    const url = {
      sendCode: "api/auth/register/code/send",
      verifyCode: "api/auth/register/code/verify",
      sendEmailCode: "api/auth/register/email/send",
      verifyEmailCode: "api/auth/register/email/verify",
      psychologistRegistration: "api/auth/register/psychologist",
      requestListPsychologists: `api/auth/register/psychologists/proposed${params}`,
      patientRegistration: "api/auth/register/patient",
      photo: "api/photo",
    };

    return url[route];
  },

  get(url, param) {
    return ApiClient.get(this.endpoints(url), param);
  },
  post(url, data, config = {}, param) {
    return ApiClient.post(this.endpoints(url, param), data, config, param);
  },
};

export default RegistrationService;
