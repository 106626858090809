<template>
  <v-card class="mb-12 pb-3" min-height="200px">
    <v-form ref="form" id="firstStepForm" @submit.prevent="submit"> </v-form>
    <v-container>
      <v-row>
        <v-col cols="12" md="3" class="mb-0 pb-0">
          <p class="mb-0">
            {{
              $vuetify.lang.t(
                "$vuetify.main.adminDashboard.psychologists.stepsTitle.generalInformation"
              )
            }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pb-0">
          <!--            :typeOfTextfield="'lastnameRules'"-->
          <v-text-field
            id="surname.ru"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.translated.surname.ru"
            label="Фамилия"
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.translated.surname.ru"
            label="Фамилия"
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4" class="pb-0">
          <!--            :typeOfTextfield="'nameRules'"-->

          <v-text-field
            id="name.ru"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.translated.name.ru"
            label="Имя"
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.translated.name.ru"
            label="Имя"
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4" class="pb-0">
          <v-text-field
            id="middle_name.ru"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.translated.middle_name.ru"
            label="Отчество"
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.translated.middle_name.ru"
            label="Отчество"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" class="pt-0">
          <!--            :typeOfTextfield="'lastnameRulesUA'"-->

          <v-text-field
            id="surname.uk"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.translated.surname.uk"
            label="Прізвище"
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.translated.surname.uk"
            label="Прізвище"
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4" class="pt-0">
          <!--            :typeOfTextfield="'nameRulesUA'"-->

          <v-text-field
            id="name.uk"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.translated.name.uk"
            label="Ім'я"
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.translated.name.uk"
            label="Ім'я"
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4" class="pt-0">
          <v-text-field
            id="middle_name.uk"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.translated.middle_name.uk"
            label="По батькові"
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.translated.middle_name.uk"
            label="По батькові"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <!--            :typeOfTextfield="'dateOfBirth'"-->

          <v-text-field
            form="firstStepForm"
            id="birthday"
            v-show="!instant"
            v-model="psychologistsList.birthday"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.dateOfBirth'
              )
            "
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.birthday"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.dateOfBirth'
              )
            "
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <!--            :typeOfTextfield="'citizenshipRules'"-->

          <v-text-field
            id="citizenship"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.citizenship"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.citizenship'
              )
            "
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.citizenship"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.citizenship'
              )
            "
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <!--            :typeOfTextfield="'emailRules'"-->

          <v-text-field
            id="email"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.email"
            label="Email:"
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.email"
            label="Email:"
            readonly
          ></v-text-field>
        </v-col>
        <v-col col="12" md="4">
          <vue-phone-number-input
            v-model="psychologistsList.phone"
            :default-country-code="defaultCountry"
            :preferred-countries="countriesList"
            :loader="hasLoaderActive"
            :error="hasErrorActive"
            :translations="translations"
            @update="updatedPhone"
            show-code-on-list
            required
            :disabled="instant"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <p class="mb-0">
            {{
              $vuetify.lang.t(
                "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepOne.education"
              )
            }}
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" md="4">
          <!--            :typeOfTextfield="'universityRules'"-->

          <v-text-field
            id="education_university"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.education_university"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.university'
              )
            "
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.education_university"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.university'
              )
            "
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <!--            :typeOfTextfield="'facultyRules'"-->

          <v-text-field
            id="education_faculty"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.education_faculty"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.faculty'
              )
            "
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.education_faculty"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.faculty'
              )
            "
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <!--            :typeOfTextfield="'specialityRules'"-->

          <v-text-field
            id="education_speciality"
            form="firstStepForm"
            v-show="!instant"
            v-model="psychologistsList.education_speciality"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.speciality'
              )
            "
            filled
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.education_speciality"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.speciality'
              )
            "
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <!--            :typeOfTextfield="'specialityRules'"-->
          <v-select
            v-show="!instant"
            :items="scienceDegreeList"
            v-model="scienceValue"
            item-value="id"
            item-text="title"
            :label="
              $vuetify.lang.t(
                `$vuetify.main.psychologistReg.stepOne.scienseDegree`
              )
            "
          ></v-select>
          <v-text-field
            v-show="instant"
            v-model="backupList.education_degree"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.scienseDegree'
              )
            "
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" offset-md="2" class="mt-0 pt-0">
          <!--            :typeOfTextfield="'yearOfEndingRules'"-->

          <v-text-field
            form="firstStepForm"
            id="education_year_of_ending"
            v-show="!instant"
            v-model="psychologistsList.education_year_of_ending"
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.yearOfEnding'
              )
            "
            filled
            class="mt-5 pt-1"
          ></v-text-field>
          <v-text-field
            v-show="instant"
            v-model="backupList.education_year_of_ending"
            readonly
            :label="
              $vuetify.lang.t(
                '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepOne.yearOfEnding'
              )
            "
            class="mt-5 pt-1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="mb-0 pb-0">
          <p class="mb-0 pb-0">
            {{
              $vuetify.lang.t(
                "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepOne.certificatesTitle"
              )
            }}
          </p>
        </v-col>
      </v-row>
      <v-row
        v-for="certificate in psychologistsList.certificates"
        :key="certificate.title"
      >
        <span class="mr-2 pt-2 pl-4"> {{ certificate.title }} : </span>
        <v-btn
          class="mt-2"
          color="primary"
          small
          :href="certificate.url"
          download
          target="_blank"
          >{{
            $vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.buttons.download"
            )
          }}</v-btn
        >
      </v-row>
    </v-container>
    <!--    <button form="firstStepForm" type="submit">dsaddsa</button>-->
  </v-card>
</template>

<script>
import prepareData from "@/mixins/prepareData.js";
import updateData from "@/mixins/updateData";
import Vue from "vue";
export default {
  name: "stepOne",
  mixins: [prepareData, updateData],

  data() {
    return {
      payload: {},
      backupList: {},
      userData: null,
      scienceValue: "",
      defaultCountry: "",
      countriesList: ["UA", "RU", "BY", "MD"],
      hasLoaderActive: false,
      hasErrorActive: false,
      loader: null,
    };
  },
  props: {
    psychologistsList: Object,
    instantValue: Boolean,
    saveIsNeed: Boolean,
  },
  created() {
    this.backupList = JSON.parse(JSON.stringify(this.psychologistsList)); // делаем независимую копию обьекта
    this.scienceValue = this.psychologistsList.education_degree_value;
    this.defaultCountry = this.getPhoneCountryCode || "UA";
  },
  watch: {
    saveIsNeed() {
      if (this.saveIsNeed) this.submit();
    },
    userData() {
      let countryCode = this.userData.countryCode;
      let nationalNumber = this.userData.nationalNumber;
      switch (countryCode) {
        case "UA":
          if (nationalNumber) {
            if (Array.from(nationalNumber).length > 9) {
              this.hasErrorActive = true;
              this.userData.isValid = false;
            } else {
              this.hasErrorActive = false;
            }
          }
      }
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
  },
  computed: {
    instant() {
      return this.instantValue;
    },
    getPhoneCountryCode() {
      return this.$store.getters["user/getUserPhoneCountryCode"];
    },
    scienceDegreeList() {
      return [
        {
          id: "bachelors",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.bachelors"
          ),
        },
        {
          id: "master",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.master"
          ),
        },
        {
          id: "doctoral",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.doctoral"
          ),
        },
        {
          id: "candidate_of_sciences",
          title: this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepOne.candidate_of_sciences"
          ),
        },
      ];
    },
    translations() {
      return {
        countrySelectorLabel: this.$vuetify.lang.t(
          "$vuetify.view.signIn.countrySelectorLabel"
        ),
        countrySelectorError: this.$vuetify.lang.t(
          "$vuetify.view.signIn.countrySelectorError"
        ),
        phoneNumberLabel: this.$vuetify.lang.t(
          "$vuetify.view.signIn.phoneNumberLabel"
        ),
        example: this.$vuetify.lang.t("$vuetify.view.signIn.example"),
      };
    },
  },
  methods: {
    updatedPhone(data) {
      this.userData = data;
    },
    submit() {
      if (!this.userData.isValid) {
        Vue.swal({
          target: document.getElementById("main"),
          text: this.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.swalText.invalidPhone"
          ),
          icon: "error",
          timer: 4000,
          toast: true,
          position: "top-right",
          showConfirmButton: false,
        });
      } else {
        let data = Array.from(this.$refs.form.$el.elements);
        data.forEach((el) => {
          this.prepareData(el);
        });
        this.updateData();
      }
    },
  },
};
</script>

<style scoped></style>
