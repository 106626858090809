<template>
  <div class="d-flex flex-row ml-5">
    <v-col cols="12" md="6" offset-md="3" class="align-self-center text-center">
      <transition appear name="slide-col">
        <h1
          v-show="show"
          class="text-color-blue-light text-black font-size-150"
        >
          404
        </h1>
      </transition>
      <transition-group appear name="slide-row">
        <h1 key="1" class="text-color-blue-light text-h1">
          {{ $vuetify.lang.t("$vuetify.pageNotFound.Oops") }}
        </h1>
        <p key="2" class="text-color-blue-light mt-4 text-body-1">
          {{ $vuetify.lang.t("$vuetify.pageNotFound.description") }}
        </p>
      </transition-group>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "pageNotFound",
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.show = true;
  },
};
</script>

<style lang="css" scoped>
.text-color-blue-light {
  color: #4f9ae4;
}
.font-size-150 {
  font-size: 150px !important;
}

@keyframes slide-col {
  0% {
    transform: translateY(-100%);
  }
  50% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0);
  }
}

.slide-col-enter-active {
  animation: slide-col 0.9s;
}
.slide-col-leave-active {
  animation: slide-col 0.9s reverse;
}

.slide-row-enter-active {
  animation: slide-row 0.9s;
}
.slide-row-leave-active {
  animation: slide-row 0.9s reverse;
}

@keyframes slide-row {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
