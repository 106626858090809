import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VuePhoneNumberInput from "vue-phone-number-input";
import Vue2Editor from "vue2-editor";
import VueSweetalert2 from "vue-sweetalert2";
import "./styles-connect.scss";
import "sweetalert2/dist/sweetalert2.min.css";

import Store from "./store";
import Echo from "laravel-echo";
import SocketClient from "socket.io-client";

import vueDebounce from "vue-debounce";

Vue.use(vueDebounce);

router.afterEach(() => {
  if (store.getters["user/getAuthToken"] && !window.io && !window.Echo) {
    window.io = SocketClient;
    window.Echo = new Echo({
      transports: ["websocket", "polling", "flashsocket"],
      broadcaster: "socket.io",
      host: process.env.VUE_APP_API + ":6001",
      authEndpoint: "/broadcasting/auth",
      auth: {
        headers: {
          Authorization: "Bearer " + Store.getters["user/getAuthToken"],
        },
      },
      // path: "/ws/socket.io",
    });
  }
});

Vue.use(VueSweetalert2);
Vue.component("vue-phone-number-input", VuePhoneNumberInput);
Vue.use(vuetify);
Vue.use(Vue2Editor);

Vue.config.productionTip = process.env.NODE_ENV !== "production";
Vue.prototype.$eventHub = new Vue();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
