<template>
  <v-form v-model="valid" ref="psychStepFourForm">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepFour.aboutInfo"
              )
            }}
            <br />
            <span class="font-gotham text-gray">
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistReg.stepFour.warning"
                )
              }}</span
            >
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            auto-grow
            v-model="personalInfo.uk"
            solo
            name="input-7-4"
            rows="2"
            label="uk"
            :counter="2000"
            :rules="textAreaValidationRules"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6">
          <v-textarea
            auto-grow
            v-model="personalInfo.ru"
            label="ru"
            solo
            name="input-7-4"
            rows="2"
            :counter="2000"
            :rules="textAreaValidationRules"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="5">
          <p>
            {{ $vuetify.lang.t("$vuetify.main.psychologistReg.stepFour.FOP") }}
          </p>
          <v-switch v-model="entrepreneur.status"></v-switch>
        </v-col>
        <v-col cols="12" md="6" offset-md="1">
          <v-textarea
            v-if="entrepreneur.status"
            v-model="entrepreneur.requisites"
            solo
            name="input-7-4"
            :label="
              $vuetify.lang.t(`$vuetify.main.psychologistReg.stepFour.FOPLabel`)
            "
            rows="2"
            :counter="10"
            :rules="entrepreneurRules"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" md="5">
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepFour.notWorkingWith"
              )
            }}
          </p>
        </v-col>
        <v-col cols="12" md="6" offset-md="1">
          <v-textarea
            v-model="notWorkingWith"
            solo
            name="input-7-4"
            rows="2"
            :counter="10"
            :rules="entrepreneurRules"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" md="5">
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepFour.infoChannel"
              )
            }}
          </p>
        </v-col>
        <v-col cols="12" md="6" offset-md="1">
          <v-textarea
            v-model="informationChanel"
            solo
            name="input-7-4"
            rows="2"
            :counter="10"
            :rules="entrepreneurRules"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" md="5">
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepFour.clientsQuantity"
              )
            }}
          </p>
        </v-col>
        <v-col cols="12" md="3" offset-md="1">
          <v-text-field
            v-model="numberOfClientsReadyToServe.from"
            :rules="numberOfClientsReadyToServeRules"
            :label="
              $vuetify.lang.t(`$vuetify.main.psychologistReg.stepFour.from`)
            "
            required
            class="mt-0 pt-0"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="numberOfClientsReadyToServe.to"
            :rules="numberOfClientsReadyToServeRules"
            :label="
              $vuetify.lang.t(`$vuetify.main.psychologistReg.stepFour.to`)
            "
            required
            class="mt-0 pt-0"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" md="5">
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepFour.mediaLinksTitle"
              )
            }}
          </p>
        </v-col>
        <v-col cols="12" md="6" offset-md="1">
          <v-textarea
            required
            v-model="socialMediaLinks"
            solo
            name="input-7-4"
            rows="3"
            :rules="socialMediaLinksRules"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row class="pb-6 mt-0">
        <v-col cols="12" md="5">
          <p class="mb-0">
            {{
              $vuetify.lang.t(
                "$vuetify.main.psychologistReg.stepFour.photoTitle"
              )
            }}
          </p>
          <v-file-input
            :rules="photosRules"
            v-model="photos"
            accept="image/png, image/jpeg, image/bmp, image/webp, image/gif, image/jpg"
            show-size
            counter
            multiple
            :label="
              $vuetify.lang.t(
                `$vuetify.main.psychologistReg.stepFour.photoLabel`
              )
            "
            class="pt-7"
          ></v-file-input>
        </v-col>
        <v-col cols="12" md="6" offset-md="1">
          <span>{{
            $vuetify.lang.t(
              "$vuetify.main.psychologistReg.stepFour.requirements"
            )
          }}</span>
          <ul>
            <li>
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistReg.stepFour.requirements1"
                )
              }}
            </li>
            <li>
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistReg.stepFour.requirements2"
                )
              }}
            </li>
            <li>
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistReg.stepFour.requirements3"
                )
              }}
            </li>
            <li>
              {{
                $vuetify.lang.t(
                  "$vuetify.main.psychologistReg.stepFour.requirements4"
                )
              }}
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: "stepFour",
  data() {
    return {
      valid: false,
      personalInfo: { uk: "", ru: "" },
      notWorkingWith: "",
      informationChanel: "",
      photos: [],
      photosRules: [(value) => value.length > 0 || value.size < 2000000],
      entrepreneur: {
        status: false,
        requisites: "",
      },
      numberOfClientsReadyToServe: {
        from: "",
        to: "",
      },
      socialMediaLinks: "",
      socialMediaLinksRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t("$vuetify.main.psychologistReg.stepFour.rules"),
        (v) =>
          v.length >= 10 ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepFour.socialMediaLinksMin50"
          ),
        (v) =>
          v.length <= 2000 ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepFour.socialMediaLinks2k"
          ),
      ],
      entrepreneurRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t("$vuetify.main.psychologistReg.stepFour.rules"),
        (v) =>
          v.length >= 10 ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepFour.entrepreneurRules"
          ),
      ],
      textAreaValidationRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t("$vuetify.main.psychologistReg.stepFour.rules"),
        (v) =>
          v.length >= 50 ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepFour.textAreaValidationRulesMin50"
          ),
        (v) =>
          v.length <= 2000 ||
          this.$vuetify.lang.t(
            "$vuetify.main.psychologistReg.stepFour.textAreaValidationRules"
          ),
      ],
      numberOfClientsReadyToServeRules: [
        (v) =>
          !!v ||
          this.$vuetify.lang.t("$vuetify.main.psychologistReg.stepFour.rules"),
      ],
    };
  },
  watch: {
    valid() {
      this.$emit("handle-valid", this.valid);
    },
  },
  created() {
    this.$eventHub.$on("psych-step-four-form_validate-form", this.validate);
  },
  mounted() {
    this.$emit("handle-valid", this.valid);
  },
  beforeDestroy() {
    this.$eventHub.$off("psych-step-four-form_validate-form");
  },
  methods: {
    validate() {
      this.$refs.psychStepFourForm.validate();

      this.$store.dispatch("user/setQuestionnaireProfile", {
        about_info: { ru: this.personalInfo.ru, uk: this.personalInfo.uk },
        about_do_not_work_with: this.notWorkingWith,
        about_min_patients: this.numberOfClientsReadyToServe.from,
        about_max_patients: this.numberOfClientsReadyToServe.to,
        about_social_media_links: this.socialMediaLinks,
        about_how_found_platform: this.informationChanel,
        entrepreneur: this.entrepreneur.status
          ? this.entrepreneur.requisites
          : "",
      });
      this.$store.dispatch("user/setUserPhoto", this.photos[0]);
    },
  },
};
</script>
