<template>
  <emailRegistrationComponent></emailRegistrationComponent>
</template>

<script>
const emailRegistrationComponent = () =>
  import("../../components/registration/emailRegistrationComponent");

export default {
  name: "emailRegistration",
  components: { emailRegistrationComponent },
};
</script>

<style scoped></style>
