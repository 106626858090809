<template>
  <sign-in-component></sign-in-component>
</template>

<script>
import SignInComponent from "../../components/registration/signInComponent";
export default {
  name: "signIn",
  components: { SignInComponent },
};
</script>

<style scoped></style>
