import AppServices from "../../services/api/app.services";

let state = {
  selectedLang: "uk",
};

const mutations = {
  CHANGE_LOCALE(state, payload) {
    state.selectedLang = payload;
  },
};

const actions = {
  setDefaultLocale({ commit }, lang) {
    commit("CHANGE_LOCALE", lang);
  },
  changeLocale({ commit, rootGetters }, data) {
    commit("CHANGE_LOCALE", data);
    if (rootGetters["user/isAuth"]) {
      return new Promise((resolve, reject) => {
        let payload = { locale: data };
        AppServices.post("switchLocale", {}, payload, {})
          .then((response) => {
            // location.reload();
            return resolve(response);
          })
          .catch(({ errors }) => reject(errors));
      });
    }
  },
};

const getters = {
  getSelectedLocale: (state) => state.selectedLang,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
