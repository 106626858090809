<template>
  <div class="container">
    <div class="registration">
      <h3>{{ $vuetify.lang.t("$vuetify.view.registration.desk") }}</h3>
      <div class="registration__container">
        <div class="registration__l">
          <p>
            {{
              $vuetify.lang.t(
                "$vuetify.view.registration.welcomeMessagePatient"
              )
            }}
          </p>
          <v-btn
            style="width: fit-content"
            color="primary"
            @click="goToPatientRegForm"
            >{{ $vuetify.lang.t("$vuetify.view.registration.patient") }}</v-btn
          >
        </div>
        <div class="registration__r">
          <p>
            {{
              $vuetify.lang.t("$vuetify.view.registration.welcomeMessagePsyh")
            }}
          </p>
          <v-btn
            style="width: fit-content"
            color="primary"
            @click="goToPsychRegForm"
          >
            {{
              $vuetify.lang.t("$vuetify.view.registration.psychologist")
            }}</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "registrationComponent",
  methods: {
    goToPsychRegForm() {
      this.$router.push("/psychologistRegForm");
    },
    goToPatientRegForm() {
      this.$router.push("/patientRegForm");
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin registrationColumn() {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  & > p {
    width: 300px;
    font-weight: 400;
    font-size: 1rem;
    color: $textGray;
  }
}

.container {
  max-width: 1200px !important;
  padding: 20px 15px;
  margin: 0 auto;
}

.registration {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  &__container {
    display: flex;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
  }
  &__l {
    @include registrationColumn;
  }
  &__r {
    @include registrationColumn;
  }
}
</style>
