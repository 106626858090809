<template>
  <registration-component></registration-component>
</template>

<script>
import registrationComponent from "../components/registration/registrationComponent";
export default {
  name: "registration",
  components: { registrationComponent },
};
</script>

<style scoped></style>
