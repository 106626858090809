var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',{ref:"form",attrs:{"id":"fourthStepForm"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}}),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.aboutInfo" ))+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(!_vm.instant),expression:"!instant"}],key:_vm.autoGrowHack,ref:"textarea",attrs:{"form":"fourthStepForm","id":"about_info.uk","auto-grow":"","rows":"2","label":_vm.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
          ),"filled":""},model:{value:(_vm.psychologistsList.translated.about_info.uk),callback:function ($$v) {_vm.$set(_vm.psychologistsList.translated.about_info, "uk", $$v)},expression:"psychologistsList.translated.about_info.uk"}}),_c('v-lazy',[_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.instant),expression:"instant"}],attrs:{"solo":"","auto-grow":"","rows":"2","label":_vm.$vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
            ),"readonly":""},model:{value:(_vm.backupList.translated.about_info.uk),callback:function ($$v) {_vm.$set(_vm.backupList.translated.about_info, "uk", $$v)},expression:"backupList.translated.about_info.uk"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(!_vm.instant),expression:"!instant"}],key:_vm.autoGrowHack,ref:"textarea",attrs:{"form":"fourthStepForm","id":"about_info.ru","auto-grow":"","rows":"2","label":_vm.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
          ),"filled":""},model:{value:(_vm.psychologistsList.translated.about_info.ru),callback:function ($$v) {_vm.$set(_vm.psychologistsList.translated.about_info, "ru", $$v)},expression:"psychologistsList.translated.about_info.ru"}}),_c('v-lazy',[_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.instant),expression:"instant"}],attrs:{"solo":"","auto-grow":"","rows":"2","label":_vm.$vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
            ),"readonly":""},model:{value:(_vm.backupList.translated.about_info.ru),callback:function ($$v) {_vm.$set(_vm.backupList.translated.about_info, "ru", $$v)},expression:"backupList.translated.about_info.ru"}})],1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.notWorkingWith" ))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"1"}},[_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(!_vm.instant),expression:"!instant"}],key:_vm.autoGrowHack,ref:"textarea",attrs:{"form":"fourthStepForm","id":"about_do_not_work_with","auto-grow":"","rows":"2","label":_vm.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
          ),"filled":""},model:{value:(_vm.psychologistsList.about_do_not_work_with),callback:function ($$v) {_vm.$set(_vm.psychologistsList, "about_do_not_work_with", $$v)},expression:"psychologistsList.about_do_not_work_with"}}),_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.instant),expression:"instant"}],attrs:{"solo":"","auto-grow":"","rows":"2","label":_vm.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
          ),"readonly":""},model:{value:(_vm.backupList.about_do_not_work_with),callback:function ($$v) {_vm.$set(_vm.backupList, "about_do_not_work_with", $$v)},expression:"backupList.about_do_not_work_with"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.FOP" ))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"1"}},[(!_vm.psychologistsList.entrepreneur)?_c('p',[_vm._v("Нет")]):_c('div',[_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(!_vm.instant),expression:"!instant"}],key:_vm.autoGrowHack,ref:"textarea",attrs:{"form":"fourthStepForm","id":"entrepreneur","auto-grow":"","rows":"2","label":_vm.$vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
            ),"filled":""},model:{value:(_vm.psychologistsList.entrepreneur),callback:function ($$v) {_vm.$set(_vm.psychologistsList, "entrepreneur", $$v)},expression:"psychologistsList.entrepreneur"}}),_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.instant),expression:"instant"}],attrs:{"solo":"","auto-grow":"","rows":"2","label":_vm.$vuetify.lang.t(
              "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
            ),"readonly":""},model:{value:(_vm.backupList.entrepreneur),callback:function ($$v) {_vm.$set(_vm.backupList, "entrepreneur", $$v)},expression:"backupList.entrepreneur"}})],1)])],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.infoChannel" ))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"1"}},[_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(!_vm.instant),expression:"!instant"}],key:_vm.autoGrowHack,ref:"textarea",attrs:{"form":"fourthStepForm","id":"about_how_found_platform","auto-grow":"","rows":"2","label":_vm.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
          ),"filled":""},model:{value:(_vm.psychologistsList.about_how_found_platform),callback:function ($$v) {_vm.$set(_vm.psychologistsList, "about_how_found_platform", $$v)},expression:"psychologistsList.about_how_found_platform"}}),_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.instant),expression:"instant"}],attrs:{"solo":"","auto-grow":"","rows":"2","label":_vm.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
          ),"readonly":""},model:{value:(_vm.backupList.about_how_found_platform),callback:function ($$v) {_vm.$set(_vm.backupList, "about_how_found_platform", $$v)},expression:"backupList.about_how_found_platform"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.clientsQuantity" ))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"3","offset-md":"1"}},[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.instant),expression:"!instant"}],attrs:{"form":"fourthStepForm","id":"about_min_patients","label":_vm.$vuetify.lang.t(
            '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepFour.from'
          ),"filled":""},model:{value:(_vm.psychologistsList.about_min_patients),callback:function ($$v) {_vm.$set(_vm.psychologistsList, "about_min_patients", $$v)},expression:"psychologistsList.about_min_patients"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.instant),expression:"instant"}],staticClass:"mt-0 pt-0",attrs:{"label":_vm.$vuetify.lang.t(
            '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepFour.from'
          ),"readonly":""},model:{value:(_vm.backupList.about_min_patients),callback:function ($$v) {_vm.$set(_vm.backupList, "about_min_patients", $$v)},expression:"backupList.about_min_patients"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.instant),expression:"!instant"}],attrs:{"form":"fourthStepForm","id":"about_max_patients","label":_vm.$vuetify.lang.t(
            '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepFour.to'
          ),"filled":""},model:{value:(_vm.psychologistsList.about_max_patients),callback:function ($$v) {_vm.$set(_vm.psychologistsList, "about_max_patients", $$v)},expression:"psychologistsList.about_max_patients"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.instant),expression:"instant"}],staticClass:"mt-0 pt-0",attrs:{"label":_vm.$vuetify.lang.t(
            '$vuetify.main.adminDashboard.psychologists.inputLabel.main.stepFour.to'
          ),"readonly":""},model:{value:(_vm.backupList.about_max_patients),callback:function ($$v) {_vm.$set(_vm.backupList, "about_max_patients", $$v)},expression:"backupList.about_max_patients"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.stepFour.profileLinks" ))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"1"}},[_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(!_vm.instant),expression:"!instant"}],key:_vm.autoGrowHack,ref:"textarea",attrs:{"form":"fourthStepForm","id":"about_social_media_links","auto-grow":"","rows":"3","label":_vm.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
          ),"filled":""},model:{value:(_vm.psychologistsList.about_social_media_links),callback:function ($$v) {_vm.$set(_vm.psychologistsList, "about_social_media_links", $$v)},expression:"psychologistsList.about_social_media_links"}}),_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(_vm.instant),expression:"instant"}],attrs:{"solo":"","rows":"3","auto-grow":"","label":_vm.$vuetify.lang.t(
            "$vuetify.main.adminDashboard.psychologists.inputLabel.questionnaire.answer"
          ),"readonly":""},model:{value:(_vm.backupList.about_social_media_links),callback:function ($$v) {_vm.$set(_vm.backupList, "about_social_media_links", $$v)},expression:"backupList.about_social_media_links"}})],1)],1),_c('v-row',{staticClass:"pb-6 mt-0"},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-btn',{attrs:{"color":"primary","href":_vm.psychologistsList.photo,"download":"","target":"_blank"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.main.adminDashboard.psychologists.buttons.downloadPhoto" )))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }