import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

/**
 * Vuex modules
 */
import UserModule from "./modules/user.module";
import AppModule from "./modules/app.modules";
import BlogModule from "./modules/blog.modules";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: UserModule,
    app: AppModule,
    blog: BlogModule,
  },
  plugins: [
    createPersistedState({
      key: "start_app",
      paths: ["app", "user", "blog"],
      storage: {
        getItem: (key) => localStorage.getItem(key),
        setItem: (key, data) => localStorage.setItem(key, data),
        removeItem: (key) => localStorage.removeItem(key),
      },
    }),
  ],
});
