import uk from "vuetify/lib/locale/uk";

export default {
  ...uk,
  locale: {
    switcherLabel: "Оберіть мову",
  },
  main: {
    paymentAlert: `Ви маєте заплановану сесію на {0}. Для проведення планового платежу за 12 годин до початку сенасу прив'яжіть, будь ласка, картку.`,
    route: {
      enter: "ВХІД",
      registration: "РЕЄСТРАЦІЯ",
    },
    header: {
      menu: {
        main: "Головна",
        settings: "Налаштування",
        specialization: "Спеціалізація",
        schedule: "Розклад",
        blog: "Блог",
        psychologists: "Психологи",
        categories: "Категорії",
        exit: "Вихід",
        payment: "Оплата",
        ourPsychologists: "Наші терапевти",
      },
    },
    redactorDashboard: {
      redactors: {
        title:
          "У цьому розділі ви можете створити/видалити профіль редактора для блогу",
        createRedactor: "Створити профіль редактора",
        delete: "Видалити",
        cancel: "Скасувати",
        accept: "Застосувати",
        name: "Ім'я*",
      },
      blog: {
        title:
          "У цьому розділі ви можете створити нову статтю або редагувати раніше створену",
        allBlogs: "Усі раніше створені статті:",
        create: "Створити",
        all: "Усі",
        published: "Опубліковані",
        waiting: "Заплановані",
        draft: "Чернетки",
        langDesk: "Кожну статтю можна створити 2 мовами",
        cancel: "Скасувати",
        accept: "Застосувати",
        createBlog: "Створити статтю",
        editBlog: "Редагувати статтю",
        publishTime: "Час публікації",
        publishDate: "Дата публікації",
        publishNow: "Опублікувати зараз",
        previewPhoto: "Фото для заголовка",
      },
      categories: {
        title:
          "У цьому розділі ви можете створити/видалити категорію для блогу",
        delete: "Видалити",
        create: "Створити",
        createCategory: "Створити нову категорію",
        createdCategories: "Створені категорії:",
      },
    },
    adminDashboard: {
      psychologists: {
        month: "Місяць",
        mainTitle:
          "В даному розділі ви можете активувати/деактивувати психологів на платформі",
        changePhotoRequire: "Фото профілю вимагає підтвердження",
        changePhotoWarning: "Необхідно завантажити фото",
        changePhotoDone: "Фото профілю підтверджено",
        rating: "Рейтинг",
        current: "Поточний",
        last: "Минулий",
        swalText: {
          deleted: "Користувача успішно видалено",
          activated: "Анкета активована. Виконується оновлення списків...",
          deactivated: "Анкета деактивована. Виконується оновлення списків...",
          updated: "Дані оновлено",
          invalidPhone: "Введено не вірний номер телефону",
        },
        tabs: {
          inactivePsychologists: "Не активні психологи",
          activePsychologists: "Активні психологи",
        },
        buttons: {
          confirmUpdates: "Підтвердіть зміни",
          downloadPhoto: "Завантажити фото",
          uploadPhoto: "Завантажити фото",
          downloadAll: "Завантажити все",
          download: "Завантажити",
          activate: "Активувати",
          deactivate: "Деактивувати",
          apply: "Застосувати",
          viewProfile: "Переглянути анкету",
          back: "Назад",
          forward: "Далі",
          closeForm: "Закрити анкету",
          btnEdit: "Редагувати",
          btnCancel: "Скасувати",
          btnSave: "Зберегти",
          confirmDelete: "Ви дійсно хочете видалити",
          confirmActivate: "Активувати анкету",
          confirmDeactivate: "Деактивувати анкету",
          confirmY: "Підтвердити",
          confirmN: "Відмінити",
        },
        switcherLabel: {
          yes: "Так",
          no: "Ні",
        },
        stepsTitle: {
          generalInformation: "Загальна інформація",
          mainTreatmentMethod: "Загальний метод",
          workExperience: "Досвід роботи",
          aboutInfo: "Про себе",
        },
        inputLabel: {
          main: {
            uploadPhoto: "Завантажити фото",
            stepOne: {
              firstName: "Ім'я",
              secondName: "Прізвище",
              patronymic: "По батькові",
              dateOfBirth: "Дата народження",
              citizenship: "Громадянство",
              phone: "Телефон",
              university: "Назва ВНЗ",
              faculty: "Назва факультету",
              speciality: "Спеціальність",
              scienseDegree: "Академічний або науковий ступінь",
              yearOfEnding: "Рік випуску",
            },
            stepThree: {
              workExperience: "Досвід роботи",
              additionalProgram: "Повна назва і кількість відпрацьованих годин",
            },
            stepFour: {
              from: "Від",
              to: "До",
            },
          },
          questionnaire: {
            answer: "Відповідь...",
            title: "Анкета",
            stepOne: {
              education: "Освіта",
              certificatesTitle:
                "Фото розгорнутих дипломів і сертифікатів, підтверджуючих навчання:",
            },
            stepTwo: {
              mainTreatmentMethod: "Основний метод",
              additionalEducation: "Додаткова освіта",
              problems: "Проблеми з якими працює",
            },
            stepThree: {
              workExperience:
                "Скільки років ви консультуєте? За кошти, не в рамках навчальної програми.",
              additionalProgram:
                "Які додаткові програми пройшли чи проходите на цей момент?",
              selfTherapy: "Чи проходите ви персональну психотерапію?",
              regularSupervision: "Чи проходите ви регулярно супервізії?",
            },
            stepFour: {
              aboutInfo:
                "Розкажіть про себе у вільній формі. Що вважаєте нам потрібно дізнатися про вас, щоб зрозуміти, який ви спеціаліст?",
              warning: "Ця інформація буде відображатися на сайті",
              notWorkingWith: "З чим ви не працюєте?",
              FOP: "Зареєстрований як ФОП",
              infoChannel:
                'Як ви дізналися про набір терапевтів в "Платформа"?',
              clientsQuantity:
                'Яку кількість клієнтів ви готові взяти в "Платформа"?',
              profileLinks:
                "Вкажіть посилання на профілі в соціальних мережах ",
            },
          },
        },
      },
    },
    psychologistDashboard: {
      settings: {
        imageInput: "Завантажити фото",
        notificationLabel: {
          1: "За 1 годину",
          2: "За 2 години",
          5: "За 5 годин",
          12: "За 12 годин",
          24: "За 24 години",
        },
        mainSettings: "Головні налаштування",
        aboutInfo: "Про себе",
        education: "Освіта",
        workExperience: "Досвід роботи",
        certificates: "Сертифікати",

        aboutYou: "Про вас",
        yourAvatar: "Ваш аватар:",
        avatarDesc:
          "Ваш аватар знаходиться на розгляді Адміністрації, це не займе багато часу.",
        avatarAlt: "Аватар психолога",
        name: "Ваше ім'я:",
        nameLabel: "Ім'я",
        lastName: "Ваше прізвище:",
        lastNameLabel: "Прізвище",
        patronymic: "Ваше по батькові:",
        patronymicLabel: "По батькові",

        timezone: "Ваш часовий пояс:",
        howOld: "Скільки вам років ?",
        birthday: "Дата народження",
        FOP: "Ви зареєстровані як ФОП?",
        FOPLabel: "Вкажіть реквізити",

        contacts: "Контакти та налаштування сповіщень",
        phone: "Телефон",
        email: "Эл. пошта",
        emailLabel: "Пошта",

        about_info:
          "Розкажіть про себе у вільній формі. Що вважаєте нам потрібно дізнатися про вас, щоб зрозуміти, який ви спеціаліст? Ця інформація буде відображатися на сайті.",
        aboutInfoLabel: "Про себе:",
        updateInfo1: "Врахуйте, дану інформацію",
        updateInfo2: " дозволено редагувати виключно 1 раз на місяць.",
        lastUpdate: "Останнього разу ця інформація змінювалась",

        consultingExperience:
          "Скільки років ви консультуєте? За кошти, не в рамках навчальної програми.",
        consultingExperienceLabel: "Досвід роботи",
        additionalProgram:
          "Які додаткові програми пройшли чи проходите на даний момент?",
        coursesLabel: "Повна назва та кількість пройдених годин",
        selfTherapy: "Чи проходите ви персональну психотерапію?",
        regularSupervision: "Чи проходите ви регулярно супервізії?",
        yes: "Так",
        no: "Ні",
        save: "Зберегти",
        update: "Оновити",
        yourCertificates: "Ваші сертифікати",
        uploadDoc: "Завантажити документ",
        cancel: "Скасувати",
        accept: "Підтвердити",
        download: "Завантажити",
        delete: "Видалити",

        rules: "Поле обовя'зкове для заповнення.",
        nameRules: "Поле \"Ім'я\" обов'язкове для заповнення",
        lastnameRules: 'Поле "Прізвище" обов\'язкове для заповнення',
        emailRules1: 'Поле "E-mail" обов\'язкове для заповнення',
        emailRules2: "E-mail повинен бути валідним",
        entrepreneurRules1: "Обов'язкове для заповнення",
        entrepreneurRules2: "Це поле повинно містити не менше 10 символів",
        consultingExperienceRules1: "Обов'язкове для заповнення",
        consultingExperienceRules2: "Некоректні дані",
        consultingExperienceRules3: "Будь ласка, введіть достовірні дані",
        coursesRules1: "Поле обов'язкове для заповнення",
        coursesRules2: "Це поле повинно містити не менше 10 символів",
        countrySelectorLabel: "Код країни",
        countrySelectorError: "Помилка країни",
        phoneNumberLabel: "Номер телефону",
        example: "Приклад :",
        year: "рік",
        years: "років",
        years2: "року",
        error: "Ви ще не можете змінювати це поле.",
      },
      mainPage: {
        currentClient: "Поточний клієнт:",
        clientsProblem: "Проблеми клієнта:",
        rating: "Ваш рейтинг:",
        nextSession: "Найближча сесія:",
        skipNextSession: "Скасувати сесію",
        todayClients: "Ваші клієнти на сьогоднішній день",
        webRTC: {
          connection: "Підключаємось...",
          nextSessionWillBee: "Наступна сесія буде",
          textInformationInVideoPlayer:
            "У момент початку Вашого сеансу кнопка для підключення до дзвінка з'явиться нижче.",
          connectToCallBtn: "Приєднається до дзвінка",
          rateTheSession: "Оцініть сесію",
          rateBtn: "Оцінити",
          settings: "Налаштування",
          scanningNextSession: "Скануємо..",
          psychologistSpinnerMessage:
            "Клієнт ще не приєднався, будь ласка, чекайте...",
          clientSpinnerMessage: "Психолог ще не приєднався, будь ласка...",
          remoteStatusVueSwall: "приєднується",
          warningCallWillFinishVueSwall:
            "УВАГА! Дзвінок закінчиться за 5 хвилин.",
        },
      },
      welcomeMessage: {
        hello: "Доброго дня,",
        title: "Раді вітати у вашому робочому кабінеті на Платформі",
        subtitle: "Ваша анкета зараз знаходиться на розглядані.",
        description:
          "Це означає, що ви не можете вести діяльність, поки ваш профіль не буде підтверджений адміністратором!",
        informing:
          "Після розгляду анкети (може розглядатися до 2-х тижнів) ми зв'яжемось з вами та домовимось про співбесіду.",
        informing2: "Слідкуйте за електронною поштою.",
      },
      schedule: {
        swallCancelSessionByPsychologist: "Скасовуємо сеанс..",
        swallCancelSessionByPsychologistTitle:
          "Ви впевнені що хочете скасувати сеанс?",
        swallYes: "Так",
        swallNo: "Нi",
        client: "Клієнт: ",
        instruction: "Інструкція",
        example1: "Приклад 1",
        exampleDesk11: "- Час, коли ви ",
        exampleDesk12: "Не Готові ",
        exampleDesk13: "працювати (клієнту не відображається цей час)",
        example2: "Приклад 2",
        exampleDesk21: "- Час, коли ви ",
        exampleDesk22: "Готові ",
        exampleDesk23:
          "працювати (клієнту  відображається цей час для запису).",
        example3: "Приклад 3",
        exampleDesk31:
          "- Час, заброньований клієнтом для роботи. У вас є можливість скасувати консультацію, так як до неї ще більше ніж 24 години",
        // exampleDesk32: "Ви не відображаєтесь ",
        // exampleDesk33:
        //   "іншим клієнтам. А також ви маєте можливість скасувати сеанс, тому, що до сеансу більш ніж 24 години.",
        example4: "Приклад 4",
        exampleDesk41:
          "- Час, заброньований кліентом для роботи. Але ви не маєте можливості скасувати консультацію, так як до неї залишилось меньше ніж 24 години",
        // exampleDesk42: "Ви також не відображаєтесь ",
        // exampleDesk43:
        //   "іншим клієнтам . А також у Вас немає можливості скасувати сеанс, тому, що до сеансу менше ніж 24 години.",
      },
      specialization: {
        title: "В даному розділі ви можете відредагувати анкетні дані",
        subtitle: "Оберіть, проблеми з якими ви можете допомогти:",
        main_treatment_method: "Оберіть Ваш основний метод:",
        additionalEducation:
          " Оберіть, якщо у вас є додаткова освіта, що стосується наступних тем:",
        other: "Інше",
        anotherSelectTextArea:
          "Напишіть, будь ласка, в якій сфері у вас є додаткова освіта?",

        art_therapy: "Арт-терапія",
        positive_psychotherapy: "Позитивна психотерапія",
        client_centered_psychotherapy: "Клієнто-центрована психотерапія",
        cognitive_behavioral_therapy: "Когнітивно-поведінкова терапія",
        body_therapy: "Тілесна терапія",
        psychoanalysis: "Психоаналіз",
        jungian_analysis: "Юнгіанський аналіз",
        transactional_analysis: "Транзакційний аналіз",
        family_therapy: "Сімейна терапія",
        gestalt_therapy: "Гештальт-терапія",
        psychodrama: "Психодрама",
        analytical_psychology: "Аналітична психологія",
        symboldrama: "Символдрама",
        existential_analysis: "Екзистенціальний аналіз",

        addictions: "Залежності",
        sexology: "Сексологія",
        extreme_situations: "Екстремальні ситуації, ПТСР",

        // psychologist filters
        life_event_problems: "Життєві ситуації",
        mental_health_condition_problems: "Стан",
        relationship_problems: "Відносини",
        career_problems: "Робота, кар'єра, навчання",
        /******************************************/

        relocate_emigration: "Переїзд, еміграція",
        financial_changes: "Фінансові зміни",
        betrayal: "Зрада",
        illness: "Хвороба, своя чи близьких",
        pregnancy: "Вагітність, народження дитини",
        divorce: "Розрив відносин, розлучення",
        violence: "Насильство",
        loss_of_a_loved_one: "Втрата близької людини",
        family_conflicts: "Сімейні конфлікти",

        stress: "Стрес",
        panic_attacks: "Панічні атаки",
        low_self_esteem: "Низька самооцінка",
        sleep_problems: "Проблеми зі сном",
        emotional_addiction: "Емоційна залежність",
        hypochondria: "Іпохондрія, занепокоєння про здоров'я",
        alcohol_drugs_addiction: "Залежність, від алкоголю, наркотиків",

        anxiety: "Тривожність",
        breakdown: "Знесилення",
        mood_swings: "Перепади настрою",
        irritability: "Роздратування",
        feelings_of_loneliness: "Почуття самотності",
        social_network_addiction: "Залежність від соціальних мереж",
        eating_disorders: "Розлад харчової поведінки",

        with_partner: "З партнером",
        with_children: "З дітьми",
        sexual: "Сексуальні",
        with_parents: "З батьками",
        common: "В цілому, з оточуючими",

        stress_at_work: "Стрес, вигоряння",
        procrastination: "Прокрастинація",
        no_goal: "Відсутність цілі",
        lack_of_motivation: "Недостатньо мотивації",
        cannot_find_myself: '"Не знаю, чим хочу займатися"',
        job_loss_change: "Зміна, втрата роботи",

        save_changes: "Зберегти зміни",
      },
    },
    patientDashboard: {
      blog: {
        header:
          "Мудра порада, цікава історія, список корисних лайфхаків чи наукова стаття. Хто знає, яке знання припаде вам до душі сьогодні?",
        subtitle1: "Добре, що ми маємо вибір.",
        subtitle2:
          "Найкращі статті допоможуть знайти відповіді на непрості та вкрай важливі питання, відпочити душею та познайомитися з думкою експертів проєкту.",
        andMore: "...і ще багато цікавого",
      },
      main: {
        swallCancelSession: "Скасуємо попередній сеанс..",
        swallBookedSession: "Бронюємо новий сеанс..",
        userAgreementLink: "угоді користувача.",
        paymentBtn: "Прив'язати карту",
        paymentMethodWarning:
          "Сеанс буде автоматично скасовано. Щоб цього не сталося, прив'яжіть карту для оплати.",
        hello: "Доброго дня ",
        yourTherapist: "Ваш терапевт: ",
        nonTherapist: "На даний момент у Вас немає терапевта.",
        chooseTherapist: "Обрати терапевта",
        switchTherapist: "Змінити терапевта",
        warning:
          "Для відображення повного функціонала - необхідно прив'язати карту.",
        connectCard: "Прив'язати карту",
        nextSession: "Наступна сесія",
        shiftSession: "Перенести сесію",
        shiftSessionOnSelectedTime: "Перенести сесію на",
        cancelSession: "Скасувати сесію",
        bookedSession: "Забронювати сесію",
        bookedSessionOnSelectedTime: "Забронювати сесію на",
        warningSession1:
          "Переносити можна не пізніше ніж за 12 годин до початку сесії. Повні умови читайте в ",
        warningSession2: "угоді користувача ",
        and: "і ",
        warningSession3: "договорі надання послуг ",
        withPsychologist: "з психологом ",
        cancel: "Відміна",
        shiftTo: "Перенести на ",
      },
      settings: {
        title: "Про вас",
        name: "Ваше ім'я:",
        age: "Скільки вам років?",
        contacts: "Контакти і налаштування повідомлень",
        smsNotification: "СМС-повідомлення за 1 годину до початку сеансу",
        hint: "Ваш e-mail використовується для нагадування про майбутні сеанси",
        blogLabel: "Новини сервісу і важливе з блогу",
        notificationLabel: "Сповіщення по почті",
        timeLabel: "Оберіть час",
        beforeSession: "до початку сеансу.",
        save: "Зберегти",
        refresh: "Оновити",
      },
      ourPsychologists: {
        oops: "Упс..ми нічого не змогли знайти по твоєму запиту: з",
        workExperience: "Досвід роботи ",
        showFully: "Показати повністю..",
        chooseDateTime: "Оберіть дату та час:",
        chooseTime: "Оберіть час",
        book: " Забронювати ",
        on: "на ",

        lifeEvent: "Життєві ситуації",
        condition: "Стан",
        relationship: "Відносини",
        career: "Робота, кар'єра, навчання",

        stress: "Стрес",
        panic_attacks: "Панічні атаки",
        low_self_esteem: "Низька самооцінка",
        sleep_problems: "Проблеми зі сном",
        emotional_addiction: "Емоційна залежність",
        hypochondria: "Іпохондрія, занепокоєння про здоров'я",
        alcohol_drugs_addiction: "Залежність, від алкоголю, наркотиків",

        anxiety: "Тривожність",
        breakdown: "Знесилення",
        mood_swings: "Перепади настрою",
        irritability: "Роздратування",
        feelings_of_loneliness: "Почуття самотності",
        social_network_addiction: "Залежність від соціальних мереж",
        eating_disorders: "Розлад харчової поведінки",

        with_partner: "З партнером",
        with_children: "З дітьми",
        sexual: "Сексуальні",
        with_parents: "З батьками",
        common: "В цілому, з оточуючими",

        stress_at_work: "Стрес, вигорання",
        procrastination: "Прокрастинація",
        no_goal: "Відсутність цілі",
        lack_of_motivation: "Недостатньо мотивації",
        cannot_find_myself: '"Не знаю, чим хочу займатися"',
        job_loss_change: "Зміна, втрата роботи",

        relocate_emigration: "Переїзд, еміграція",
        financial_changes: "Фінансові зміни",
        betrayal: "Зрада",
        illness: "Хвороба, своя чи близьких",
        pregnancy: "Вагітність, народження дитини",
        divorce: "Розрив відносин, розлучення",
        violence: "Насильство",
        loss_of_a_loved_one: "Втрата близької людини",
        family_conflicts: "Сімейні конфлікти",
      },
      payment: {
        cardInfo: "Інформація про вашу карту",
        cardDesk:
          "Дана картка використовується для оплати консультацій із Вашим терапевтом.",
        untieCard: "Відв'язати картку",
        untieCardDesk: "Ваша карта не прив'язана",
        bookInfo: "Для бронювання часу консультації необхідно прив'язати карту",
        connectCard: "Прив'язати карту ",
        cancel: "Скасувати",
        cardNumber: "Номер карти",
        cardOwner: "Власник карти",
        expireDate: "Термін дії",
        month: "Місяць",
        year: "Рік",
      },
    },
    patientReg: {
      generalQuestions: "Загальні питання",
      personalQuestions: "Особисті питання",
      therapists: "Вибір терапевта",
      terms: "Умови оплати",
      further: "Далі",
      back: "Назад",
      apply: "Зареєструватись",
      select: "Обрати",
      stepOne: {
        yourName: "Як вас звати?",
        nameDesk: "Ваше ім'я буде доступне вашому психологу",
        nameLabel: "Ім'я",
        yourAge: "Скільки вам років?",
        ageDesk: "Для отримання послуг ви повинні бути старше 16 років",
        ageLabel: "Вік",
        therapyExperience: "Чи був у вас досвід терапії?",
        yes: "Так",
        no: "Ні",
        consultationType: "Оберіть тип консультіції",
        single: "Для себе",
        family: "Сімейна",
        timeZone: "Часовий пояс",
        patientNameRules: "Поле \"Ім'я\" обов'язково при заповненні",
        patientAgeRules1: 'Поле "Вік" обов\'язково при заповненні',
        patientAgeRules2:
          "Для отримання послуг ви повинні бути старше 16 років.",
        patientEmailRules1: 'Поле "E-mail" обов\'язково при заповненні',
        patientEmailRules2: "E-mail має бути валідним",
      },
      stepTwo: {
        selectThemes: "Оберіть теми, з якими ви бажаєте працювати з психологом",
        warning: "Необхідно вибрати хоча б одну тему",
        whatDiscuss: "Що ви хотіли б обговорити насамперед?",
        lifeEvent: "Життєві ситуації",
        condition: "Стан",
        relationship: "Відносини",
        career: "Робота, кар'єра, навчання",

        relocate_emigration: "Переїзд, еміграція",
        financial_changes: "Фінансові зміни",
        betrayal: "Зрада",
        illness: "Хвороба, своя чи близьких",
        pregnancy: "Вагітність, народження дитини",
        divorce: "Розрив відносин, розлучення",
        violence: "Насильство",
        loss_of_a_loved_one: "Втрата близької людини",
        family_conflicts: "Сімейні конфлікти",

        stress: "Стрес",
        panic_attacks: "Панічні атаки",
        low_self_esteem: "Низька самооцінка",
        sleep_problems: "Проблеми зі сном",
        emotional_addiction: "Емоційна залежність",
        hypochondria: "Іпохондрія, занепокоєння про здоров'я",
        alcohol_drugs_addiction: "Залежність, від алкоголю, наркотиків",

        anxiety: "Тривожність",
        breakdown: "Знесилення",
        mood_swings: "Перепади настрою",
        irritability: "Роздратування",
        feelings_of_loneliness: "Почуття самотності",
        social_network_addiction: "Залежність від соціальних мереж",
        eating_disorders: "Розлад харчової поведінки",

        with_partner: "З партнером",
        with_children: "З дітьми",
        sexual: "Сексуальні",
        with_parents: "З батьками",
        common: "В цілому, з оточуючими",
        stress_at_work: "Стрес, вигорання",
        procrastination: "Прокрастинація",
        no_goal: "Відсутність цілі",
        lack_of_motivation: "Недостатньо мотивації",
        cannot_find_myself: '"Не знаю, чим хочу займатися"',
        job_loss_change: "Зміна, втрата роботи",
      },
      stepThree: {
        workExperience: "Досвід роботи",
        worksWith: "Працює з",
        outOf: "із",
        topics: "тем Вашої анкети",
        soon: "Найближчий час:",
        selectSessionTime: "Обрати час сеансу",
        aboutSpecialist: "Про спеціаліста",
        canContactMe: "Ви можете звернутися до мене:",
        education: "Освіта",
        therapyMethod: "Метод терапії",
        workTopics: "Спеціаліст працює з темами з вашої анкети:",
        dateAndTime: "Оберіть дату та час сеансу",
        canShift: "Можливо перенести за 12 годин",
        price: "Фіксована ціна {0} ",
      },
      stepFour: {
        price: "Вартість сеансу становить ",
        termsPart1: "Після того, як ви прив'яжете карту, ми знімемо з неї",
        termsPart2:
          "і одразу ж повернемо назад. Це стандартна процедура перевірки.",
        termsPart31: "Кошти знімаються з карти ",
        termsPart32: "за 12 годин ",
        termsPart33:
          "до сесії – і після того, як це сталося, ви вже не зможете її перенести або скасувати.",
        termsPart41: "Ви в будь-який момент ",
        termsPart42: "можете відв'язати ",
        termsPart43: "свою карту. Це можливо зробити в особистому кабінеті.",
        termsPart5:
          "Всі сеанси проводяться по відеозв'язку з особистого кабінету і вам необхідне стабільне підключення до інтернету.",
        important: "Важливо! ",
        termsPart6: `Натискаючи кнопку «Прив'язати карту» ви підтверджуєте, що ознайомлені та згідні з договором надання послуг та даєте згоду на обробку персональних даних.`,
        psychologist: "Терапевт:",
        sessionDate: "Дата та час сеансу:",
      },
    },
    psychologistReg: {
      emailCodeSpam: "Якщо ви не бачите листа, перевірте теку Спам",
      emailCode:
        "Вам було надіслано код на вказану пошту, введіть його у поле вище, будь ласка.",
      generalInformation: "Загальна інформація",
      mainTreatmentMethod: "Основний метод",
      workExperience: "Досвід роботи",
      aboutInfo: "Розкажіть про себе",
      further: "Далі",
      back: "Назад",
      apply: "Подати заявку",
      stepOne: {
        desk: "Деякі поля необхідно заповнити двома мовами",
        phoneRules: 'Поле "телефон" обов\'язково при заповненні',
        phoneRules2: 'Поле "телефон" повинно містити тільки цифри',
        birthRule:
          'Поле "дата народження" повинно бути в форматі "рік-місяць-день"',
        firstName: "Ім'я",
        secondName: "Прізвище",
        patronymic: "По батькові",
        dateOfBirth: "Дата народження",
        citizenship: "Громадянство",
        phone: "Телефон",
        university: "Назва ВНЗ",
        faculty: "Назва факультету",
        speciality: "Спеціальність",
        scienseDegree: "Академічний чи науковий ступінь",
        yearOfEnding: "Рік закінчення",
        education: "Освіта",
        photoDesc:
          "Будь ласка, прикріпіть фотографії розгорнутих дипломів та сертифікатів, що підтверджують навчання",
        diploma_and_certificates: "Дипломи та сертифікати",
        nameRules: "Поле \"Ім'я\" обов'язково при заповненні",
        lastnameRules: 'Поле "Прізвище" обов\'язково при заповненні',
        emailRules1: "E-mail має бути валідним",
        emailRules2: 'Поле "E-mail" обов\'язково при заповненні',

        educationRules: 'Поле "Освіта" обов\'язково при заповненні',
        universityRules: 'Поле "Назва ВНЗ" обов\'язково при заповненні',
        facultyRules: 'Поле "Назва факультету" обов\'язково при заповненні',
        specialityRules: 'Поле "Спеціальність" обов\'язково при заповненні',
        scienceDegreeRules:
          'Поле "Академічний чи науковий ступінь" обов\'язково при заповненні',

        yearOfEndingRules1: 'Поле "Рік закінчення" обов\'язкове при заповненні',
        yearOfEndingRules2: 'Поле "Рік закінчення" повинно містити 4 символи',
        citizenshipRules: 'Поле "Громадянство" обовязково при заповненні',

        bachelors: "Бакалавр",
        master: "Магістр",
        doctoral: "Доктор наук",
        candidate_of_sciences: "Кандидат наук",
        diploma_and_certificates_desk: "Основні та додаткові",
      },
      stepTwo: {
        another: "Інше",
        mainTreatmentMethod: "Оберіть Ваш основний метод:",
        additionalEducation:
          "Виберіть, якщо у вас є додаткова освіта, яка стосується наступних тем:",
        additionalEducationDesk:
          "Напишіть, будь ласка, в якій галузі маєте додаткову освіту?",
        problems: "Виберіть проблеми з якими ви можете допомогти:",

        art_therapy: "Арт-терапія",
        positive_psychotherapy: "Позитивна психотерапія",
        client_centered_psychotherapy: "Клієнт-центрована психотерапія",
        cognitive_behavioral_therapy: "Когнітивно-поведінкова терапія",
        body_therapy: "Тілесна терапія",
        psychoanalysis: "Психоаналіз",
        jungian_analysis: "Юнгіанський аналіз",
        transactional_analysis: "Транзакційний аналіз",
        family_therapy: "Сімейна терапія",
        gestalt_therapy: "Гештальт-терапія",
        psychodrama: "Психодрама",
        analytical_psychology: "Аналітична психологія",
        symboldrama: "Символдрама",
        existential_analysis: "Екзистенційний аналіз",

        addictions: "Залежності",
        sexology: "Сексологія",
        extreme_situations: "Екстремальні ситуації, ПТСР",

        lifeEvent: "Події життя",
        condition: "Стан",
        relationship: "Відносини",
        career: "Робота, кар'єра, навчання",

        relocate_emigration: "Переїзд, еміграція",
        financial_changes: "Фінансові зміни",
        betrayal: "Зрада",
        illness: "Хвороба, своя чи близьких",
        pregnancy: "Вагітність, народження дитини",
        divorce: "Розрив відносин, розлучення",
        violence: "Насильство",
        loss_of_a_loved_one: "Втрата близької людини",
        family_conflicts: "Сімейні конфлікти",

        stress: "Стрес",
        panic_attacks: "Панічні атаки",
        low_self_esteem: "Низька самооцінка",
        sleep_problems: "Проблеми зі сном",
        emotional_addiction: "Емоційна залежність",
        hypochondria: "Іпохондрія, занепокоєння про здоров'я",
        alcohol_drugs_addiction: "Залежність, від алкоголю, наркотиків",

        anxiety: "Тривожність",
        breakdown: "Знесилення",
        mood_swings: "Перепади настрою",
        irritability: "Роздратування",
        feelings_of_loneliness: "Почуття самотності",
        social_network_addiction: "Залежність від соціальних мереж",
        eating_disorders: "Розлад харчової поведінки",

        with_partner: "З партнером",
        with_children: "З дітьми",
        sexual: "Сексуальні",
        with_parents: "З батьками",
        common: "В цілому, з оточуючими",
        stress_at_work: "Стрес, вигорання",
        procrastination: "Прокрастинація",
        no_goal: "Відсутність цілі",
        lack_of_motivation: "Недостатньо мотивації",
        cannot_find_myself: '"Не знаю, чим хочу займатися"',
        job_loss_change: "Зміна, втрата роботи",
      },
      stepThree: {
        year: "рік",
        years: "років",
        years2: "року",
        years3: "роки",
        workExperienceLabel: "Досвід роботи",
        workExperience:
          "Скільки років ви консультуєте? За кошти, не в рамках навчальної програми.",
        additionalProgram:
          "Які додаткові програми пройшли чи проходите на цей момент?",
        additionalProgramLabel: "Повна назва та кількість пройдених годин",
        selfTherapy: "Чи проходите ви персональну психотерапію?",
        regularSupervision: "Чи проходите ви регулярно супервізії?",
        rules: "Поле обов'язкове для заповнення",
        consultingExperienceRules1: "Некоректні дані",
        consultingExperienceRules2: "Будь ласка, введіть достовірні дані",
        coursesRules: "Це поле повинно містити не менше 10 символів",
        yes: "Так",
        no: "Ні",
      },
      stepFour: {
        swallWriteToDB: "Створюємо обліковий запис",
        aboutInfo:
          "Розкажіть про себе у вільній формі. Що вважаєте нам потрібно дізнатися про вас, щоб зрозуміти, який ви спеціаліст?",
        warning: "Ця інформація буде відображена на сайті",
        FOP: "Ви зареєстровані як ФОП?",
        FOPLabel: "Вкажіть реквізити",
        notWorkingWith: "З чим ви не працюєте?",
        infoChannel: 'Як ви дізналися про набір терапевтів в "Платформа"?',
        clientsQuantity:
          'Яку кількість клієнтів ви готові взяти в "Платформа"?',
        from: "Від",
        to: "До",
        mediaLinksTitle: "Вкажіть посилання на профілі в соціальних мережах ",
        photoTitle: "Додайте ваше фото",
        photoLabel: "Ваше фото",
        requirements: "Вимоги до фото:",
        requirements1: "Кольорове",
        requirements2: "Обличчя по центру і добре освітлене",
        requirements3: "Розмір не менше 1МБ",
        requirements4: "В форматі (назва).jpg",
        socialMediaLinksMin50: "Це поле має містити більше ніж 10 символів",
        socialMediaLinks2k: "Це поле має містити не більше 2000 символів",
        rules: "Поле обов'язково при заповненні",
        textAreaValidationRulesMin50:
          "Це поле має містити більше ніж 50 символів",
        textAreaValidationRules: "Це поле має містити не більше 2000 символів",
        entrepreneurRules: "Це поле має містити не менше 10 символів",
      },
    },
    footer: {
      questions: "Залишились питання? ",
      contacts: "Ми завжди на зв'язку",
      legal: {
        mainText:
          ' <b>Важливий момент.</b> "Платформа" — це не швидка психологічна\n' +
          "            допомога. Якщо у вас серйозні проблеми, що загрожують вашому життю,\n" +
          "            котрі потребують негайного вирішення, вам краще звернутися в\n" +
          "            будь-яку з цих організацій.",
        userAgreementPart1: "Реєстрація на сайті означає згоду з",
        userAgreementLink: "угодою користувача.",
        userAgreementPart2: "Політика у відношенні ",
        userAgreementLink2: "обробки та захисту персональних даних",
        terms:
          "При використанні будь-яких матеріалів цього сайту, посилання на джерело обов'язкове.",
        protected: "Всі права захищені!",
      },
      footerMenu: {
        FAQ: "ПИТАННЯ-ВІДПОВІДІ",
        Contacts: "КОНТАКТИ",
        workOffers: "РОБОТА В ПЛАТФОРМА",
        blog: "БЛОГ",
        ourPsychologists: "НАШІ ПСИХОЛОГИ",
        forBusiness: "ПЛАТФОРМА ДЛЯ БІЗНЕСУ",
      },
    },
  },
  view: {
    blog: {
      search: "Пошук по статтях",
      categories: "Категорії",
      title: "Ми вибачаємося.",
      subtitle: "Цей розділ знаходиться в розробці.",
      localisationWarning: "Стаття написана 1-ою мовою",
      posted: "Опубліковано",
      close: "Закрити",
      readMore: "Читати статтю повністю",
      nextPost: "Наступниа стаття",
      previousPost: "Попереднія стаття",
    },
    signIn: {
      title:
        "Будь ласка, введіть свій номер телефону з кодом країни, ми надішлемо вам одноразовий пароль",
      termsPart1: "Ввівши свій номер, ви приймаєте умови, ",
      termsPart2: "угоди користувача ",
      termsPart3: "даєте згоду на ",
      termsPart4: "обробку персональних даних ",
      termsPart5:
        "і даєте згоду на отримання СМС-паролей, а також інших інформаційних і сервісних повідомлень на вказаний номер телефону.",
      code: "Отримати код",
      loading: "Завантаження...",
      countrySelectorLabel: "Код країни",
      countrySelectorError: "Помилка країни",
      phoneNumberLabel: "Номер телефону",
      example: "Приклад :",
    },
    verify: {
      title: "ВВЕДІТЬ КОД",
      desk: "Код дійсний протягом 10 хвилин. Код надіслано!",
      resendCode: "Надіслати код повторно",
      resendCodeDesk:
        "Термін дії тимчасового коду минув. Надіслати код повторно?",
      accept: "Підтвердити",
      loading: "Завантаження...",
      viberCode1: "Не прийшов код ?",
      viberCode2: "Надіслати код на Viber",
    },
    registration: {
      welcomeMessagePsyh:
        "Якщо ви психолог та бажаєте стати частиною нашої команди",
      welcomeMessagePatient:
        "Якщо ви бажаєте знайти саме свого психолога та вирішити свій запит",
      desk: "Для початку роботи з Платформою необхідно пройти реєстрацію",
      patient: "Реєстрація для клієнта",
      psychologist: "Реєстрація для психолога",
      title:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    },
    emailRegistration: {
      title: "Введіть Ваш E-mail",
      desk: 'Вся взаємодія з сервісом "Платформа" відбувається через листи на пошту. Ми надішлемо Вам листа з підсумком по Вашій анкеті.',
      accept: "Підтвердити",
      loading: "Завантаження...",
      emailRules1: 'Поле "E-mail" обов\'язково при заповненні',
      emailRules2: "E-mail має бути валідним",
    },
    auth: {
      logout: "Ви успішно були розлогінені!",
      psychologist: {
        Schedule: {
          title: "Розклад",
          subtitle:
            "Заповніть свій робочий графік, коли ви готові працювати з клієнтами Платформи.",
          description:
            "Розклад відображається у форматі - поточний день + 6 днів.",
          btnSave: "Зберегти",
          btnEdit: "Редагувати",
        },
      },
    },
  },

  developmentSection: {
    title: "Даний розділ знаходиться в розробці.",
  },
  pageNotFound: {
    Oops: "Уупс..",
    description: "Сторінка яку Ви шукаєте не існує. ",
  },
};
