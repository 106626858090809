<template>
  <v-col md="8" offset-md="2">
    <v-stepper v-model="el">
      <v-stepper-header>
        <v-stepper-step :complete="el > 1" step="1">
          {{
            $vuetify.lang.t("$vuetify.main.psychologistReg.generalInformation")
          }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="el > 2" step="2">
          {{
            $vuetify.lang.t("$vuetify.main.psychologistReg.mainTreatmentMethod")
          }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="el > 3" step="3">{{
          $vuetify.lang.t("$vuetify.main.psychologistReg.workExperience")
        }}</v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step step="4">
          {{ $vuetify.lang.t("$vuetify.main.psychologistReg.aboutInfo") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12 pb-3" min-height="200px">
            <stepOne></stepOne>
          </v-card>
          <div class="d-flex justify-end">
            <v-btn color="primary" @click="handleValidateForm(1)">
              {{ $vuetify.lang.t("$vuetify.main.psychologistReg.further") }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-12 pb-3" min-height="200px">
            <stepTwo></stepTwo>
          </v-card>
          <div class="d-flex justify-space-between">
            <v-btn @click="el = 1">
              {{ $vuetify.lang.t("$vuetify.main.psychologistReg.back") }}
            </v-btn>
            <v-btn color="primary" @click="handleValidateForm(2)">
              {{ $vuetify.lang.t("$vuetify.main.psychologistReg.further") }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" min-height="200px">
            <step-three></step-three>
          </v-card>
          <div class="d-flex justify-space-between">
            <v-btn @click="el = 2">
              {{ $vuetify.lang.t("$vuetify.main.psychologistReg.back") }}
            </v-btn>
            <v-btn color="primary" @click="handleValidateForm(3)">
              {{ $vuetify.lang.t("$vuetify.main.psychologistReg.further") }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card class="mb-12" min-height="200px">
            <stepFour @handle-valid="handleValid"></stepFour>
          </v-card>
          <div class="d-flex justify-space-between">
            <v-btn @click="el = 3">
              {{ $vuetify.lang.t("$vuetify.main.psychologistReg.back") }}
            </v-btn>
            <v-btn :disabled="!valid" color="primary" @click="sendRegFormData">
              {{ $vuetify.lang.t("$vuetify.main.psychologistReg.apply") }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import stepOne from "@/components/registration/registrationSteps/psycholog/stepOne";
import StepTwo from "@/components/registration/registrationSteps/psycholog/stepTwo";
import stepFour from "@/components/registration/registrationSteps/psycholog/stepFour";
import StepThree from "@/components/registration/registrationSteps/psycholog/stepThree";
import Vue from "vue";

export default {
  components: {
    StepThree,
    StepTwo,
    stepOne,
    stepFour,
  },
  name: "psychologRegForm",
  data() {
    return {
      el: 1,
      valid: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  created() {
    this.$eventHub.$on("switch-psych-registration-tab", this.switchTab);
  },
  beforeDestroy() {
    this.$eventHub.$off("switch-psych-registration-tab");
  },
  methods: {
    handleValid(valid) {
      this.valid = valid;
    },
    switchTab(value) {
      this.el = value;
    },
    handleValidateForm(step) {
      switch (step) {
        case 1:
          return this.$eventHub.$emit("psych-step-one-form_validate-form");
        case 2:
          return this.$eventHub.$emit("psych-step-two-form_validate-form");
        case 3:
          return this.$eventHub.$emit("psych-step-three-form_validate-form");
        case 4:
          return this.$eventHub.$emit("psych-step-four-form_validate-form");
      }
    },
    sendRegFormData() {
      this.handleValidateForm(4);
      let formatData = new FormData();
      formatData.append("photo", this.$store.getters["user/getUserPhoto"]);

      this.$store.getters["user/getCertificates"].forEach(
        (certificate, index) =>
          formatData.append(`certificates[${index}]`, certificate)
      );

      formatData.append("phone", this.$store.getters["user/getUserPhone"]);
      formatData.append(
        "phone_country_code",
        this.$store.getters["user/getUserPhoneCountryCode"]
      );
      formatData.append("email", this.$store.getters["user/getUserEmail"]);
      formatData.append("locale", this.$store.getters["app/getSelectedLocale"]);

      formatData.append(
        "general_questions",
        JSON.stringify(
          this.$store.getters["user/getUserQuestionnaireGeneralQuestions"]
        )
      );
      formatData.append(
        "profile",
        JSON.stringify(this.$store.getters["user/getUserQuestionnaireProfile"])
      );
      Vue.swal({
        target: document.getElementById("main"),
        text: this.$vuetify.lang.t(
          "$vuetify.main.psychologistReg.stepFour.swallWriteToDB"
        ),
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        didOpen: () => {
          Vue.swal.showLoading();
        },
        didClose: () => {},
      });

      this.$store
        .dispatch("user/psychologistRegistration", formatData)
        .then((response) => {
          if (response.status === 200) {
            this.$router.push("/dashboard/main");
            Vue.swal({
              target: document.getElementById("main"),
              text: response.data.message,
              icon: "success",
              timer: 4000,
              toast: true,
              position: "top-right",
              showConfirmButton: false,
            });
          }
        });
    },
  },
};
</script>

<style scoped></style>
