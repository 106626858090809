import ApiClient from "@/libs/http-client";

const PaymentServices = {
  endpoints(route) {
    const url = {
      getCredentials: "api/profile/card-verify-data",
      sendCredentials: "api/payment-methods",
    };
    return url[route];
  },

  get(url, param) {
    return ApiClient.get(this.endpoints(url), param);
  },
  post(url, params = {}, data, config = {}) {
    return ApiClient.post(this.endpoints(url, params), data, config);
  },
  patch(url, params = {}, data, config = {}) {
    return ApiClient.patch(this.endpoints(url, params), data, config);
  },
};

export default PaymentServices;
