<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "registrationLayout",
  created() {
    if (location.href.indexOf("?chosen_psychologist=") + 1) {
      let get = location.href;
      let chosenPsychologistId = get.slice(get.indexOf("?"), get.length);
      this.$store.dispatch(
        "user/setChosenPsychologistId",
        chosenPsychologistId
      );
    }
  },
};
</script>

<style scoped></style>
